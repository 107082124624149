import { v4 } from 'uuid';
import { z } from 'zod';
import { computeAnnotationCommentsHash } from '../utils';

const positionSchema = z.object({
  x: z.number(),
  y: z.number(),
});

export const annotationCommentSchema = z.object({
  id: z.number(),
  name: z.string(),
  initials: z.string(),
  comment: z.string(),
  timestamp: z.number(),
});

export const annotationSchema = z
  .object({
    id: z.number(),
    index: z.number(),
    pos: positionSchema,
    comments: z.array(annotationCommentSchema),
    resolved: z.boolean(),
    timestamp: z.number(),
  })
  .transform((annotation) => ({
    ...annotation,
    // to avoid closing the popover for newly created annotations by keeping the key
    key: v4(),
  }));

export const slideWithAnnotationsSchema = z.object({
  id: z.number(),
  index: z.number(),
  annotations: z.array(annotationSchema),
  timestamp: z.number(),
});

const slideVersionSchema = z.object({
  id: z.number(),
  preview: z.string(),
  timestamp: z.number(),
  version: z.number(),
});

export const slideLatestVersionSchema = slideVersionSchema.extend({
  index: z.number(),
  versions: z.array(slideVersionSchema),
  updatedAt: z.number(),
});

export const slideAnnotationsSchema = z
  .object({
    slides: z.array(slideWithAnnotationsSchema),
    maxAnnotationIndex: z.number(),
  })
  .transform(({ slides, maxAnnotationIndex }) => ({
    slideAnnotations: slides.map((slide) => ({
      ...slide,
      annotations: slide.annotations.map((annotation) => ({
        ...annotation,
        hash: computeAnnotationCommentsHash(annotation.comments),
      })),
    })),
    maxAnnotationIndex,
  }));

export type AnnotationBase = z.infer<typeof annotationSchema>;

export type SlideWithAnnotations = z.infer<
  typeof slideAnnotationsSchema
>['slideAnnotations'][number];

export type Annotation = z.infer<
  typeof slideAnnotationsSchema
>['slideAnnotations'][number]['annotations'][number];

export const slidesSchema = z
  .object({
    slides: z.array(slideLatestVersionSchema),
    revisions: z
      .array(
        z.object({
          id: z.number(),
          timestamp: z.number(),
          updatedAt: z.number(),
          slides: z.array(
            z.object({
              id: z.number(),
              preview: z.string(),
              timestamp: z.number(),
              updatedAt: z.number(),
            }),
          ),
        }),
      )
      .optional()
      .transform((revisions) => {
        return (revisions || []).map((revision, index, { length }) => ({
          ...revision,
          name: index === 0 ? 'Latest version' : `Version ${length - index}`,
          slides: revision.slides.map((slide, index) => ({ ...slide, index })),
        }));
      }),
  })
  .transform(({ slides, revisions }) => ({
    slides: slides.map((slide) => ({
      ...slide,
      versions: slide.versions.map((version) => ({
        ...version,
        index: slide.index,
      })),
    })),
    revisions,
  }));

export type Slides = z.infer<typeof slidesSchema>;

export type Slide = Slides['slides'][number];
