import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';

import '~/utils/fontAwesome';
import '~/utils/sentry';
import { services } from '~/root/services';
import { App } from './root/App';

services.init();

// @ts-ignore
ReactDOM.render(<App />, document.getElementById('root'));
