import { useMemo } from 'react';
import { Any, record } from '~/common/utils';
import { FrontendTableConfig, GenericItem, Sort, Value } from '../types';
import { getSort } from '../utils';
import { useParsedQuery } from './useParsedQuery';

const ROWS_PER_PAGE = 15;

type FrontendTableParams<T extends GenericItem> = {
  data?: T[];
  tableConfig: FrontendTableConfig<T>;
};

type CellValueGetters<T> = Record<keyof T, (cellData: T[keyof T]) => Value | null>;

const compare = (a: string, b: string) => {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};

// I know this looks like a garbage, but it works
const sortRows = <T extends GenericItem>(
  displayItems: T[],
  sort: Sort<keyof T>,
  cellValueGetters: CellValueGetters<T>,
): T[] => {
  const { option, order } = sort;

  return displayItems.slice().sort((a, b) => {
    const aValue = (cellValueGetters[option]?.(a[option]) ?? a[option]) as Value | null;
    const bValue = (cellValueGetters[option]?.(b[option]) ?? b[option]) as Value | null;

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc' ? compare(aValue, bValue) : compare(bValue, aValue);
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    }

    if (typeof aValue === 'string' && bValue == null) {
      return order === 'asc' ? -1 : 1;
    }

    if (typeof bValue === 'string' && aValue == null) {
      return order === 'asc' ? 1 : -1;
    }

    if (typeof aValue === 'number' && bValue == null) {
      return order === 'asc' ? -1 : 1;
    }

    if (typeof bValue === 'number' && aValue == null) {
      return order === 'asc' ? 1 : -1;
    }

    if (aValue == null && bValue == null) {
      return 0;
    }

    throw new Error(
      "Couldn't sort these values, consider adding simple value getters for objects " +
        JSON.stringify({ aValue, bValue, option, order }),
    );
  });
};

export const useFrontendTable = <T extends GenericItem>({
  data,
  tableConfig,
}: FrontendTableParams<T>) => {
  const { queryParams, setQueryParams } = useParsedQuery();

  // TODO fix type, or whatever
  const onSort = (option: Any) => {
    setQueryParams((params) => ({
      ...params,
      sort: getSort(params.sort?.option === option ? params.sort : option),
    }));
  };

  const onPageChange = (page: number) => {
    setQueryParams((params) => ({ ...params, page }));
  };

  const searchCache = useMemo(() => {
    if (!data) {
      return null;
    }

    return data.reduce((items: Record<Value, string>, item) => {
      items[item.id] = tableConfig.searchable
        .map((key) => item[key])
        .join(' ')
        .toLowerCase();
      return items;
    }, {});
  }, [data, tableConfig.searchable]);

  const cellValueGetters = useMemo(() => {
    return tableConfig.columns.reduce((getters, column) => {
      if (column.getCellValue) {
        getters[column.key] = column.getCellValue;
      }
      return getters;
    }, {} as CellValueGetters<T>);
  }, [tableConfig.columns]);

  if (!data || !searchCache) {
    return null;
  }

  let displayItems = data.slice();

  // TODO since we filter using filter field, it makes sense to add filterable
  // fields to tableConfig, maybe exactFilters (case for selects), so we have proper types here
  const filterEntries = record.entries(queryParams.filter).filter(([key]) => key !== 'search');
  if (filterEntries.length) {
    displayItems = displayItems.filter((item) => {
      for (const [stringKey, value] of filterEntries) {
        const key = stringKey as keyof T;
        if (
          cellValueGetters[key] ? cellValueGetters[key](item[key]) !== value : item[key] !== value
        ) {
          return false;
        }
      }
      return true;
    });
  }

  const search = queryParams.filter.search as string | undefined;
  if (search) {
    const lowerCasedSearch = String(search).toLowerCase();
    displayItems = displayItems.filter((item) => {
      return searchCache[item.id].includes(lowerCasedSearch);
    });
  }

  if (queryParams.sort && displayItems.length) {
    displayItems = sortRows(displayItems, queryParams.sort, cellValueGetters);
  }

  const page = queryParams.page ?? 1;
  const indexOfLastRow = page * ROWS_PER_PAGE;
  const indexOfFirstRow = indexOfLastRow - ROWS_PER_PAGE;
  const currentRows = displayItems.slice(indexOfFirstRow, indexOfLastRow);

  return {
    tableConfig,
    items: currentRows,
    totalPages: Math.ceil(displayItems.length / ROWS_PER_PAGE),
    page: queryParams.page ?? 1,
    limit: queryParams.limit ?? 5,
    total: currentRows.length,
    results: currentRows.length,
    sort: queryParams.sort ?? null,
    onSort,
    onPageChange,
  };
};
