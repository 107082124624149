import { useForm, validator } from 'formoid';
import { useState } from 'react';
import {
  CheckboxField,
  Chip,
  ChipsInputField,
  ConfirmModalShell,
  InputField,
  ModalContentProps,
  ModalHeader,
  ModalShell,
  SelectField,
  Tooltip,
  stringPasteProcessor,
  useModalBlock,
  useModalContext,
} from '~/common/components';
import { propagateBackendValidationErrors } from '~/common/utils';
import { CustomField, CustomFieldType, customFieldOptions, isCustomFieldType } from './domain';
import { useCreateCustomField, useUpdateCustomField } from './hooks';

const STRING_SEPARATORS = ['Enter', ',', ';'];

const noValues = {
  title: '',
  description: '',
  type: null as CustomFieldType | null,
  options: [] as Chip<string>[],
  is_required: false,
};

export const ConfirmModal = ({ onClose, edit }: ModalContentProps & { edit: boolean }) => {
  const { close: closeModal } = useModalContext();
  return (
    <ConfirmModalShell
      title="Are you sure?"
      description={
        edit
          ? `If you close this window without clicking "Save changes," all modifications you made to this field will be lost.`
          : `If you close this window without clicking "Add field," any information you've entered in this field will be lost.`
      }
      submitText="Close without saving"
      onClose={onClose}
      onConfirm={closeModal}
    />
  );
};

const customFieldToFormValues = (field: CustomField) => {
  const { id, ...rest } = field;
  return {
    ...rest,
    type: field.type as typeof field.type | null,
    options: field.options.map((value) => ({ value, isValid: true })),
  };
};

type CustomFieldModalProps = ModalContentProps & {
  field?: CustomField;
  passedValues?: ReturnType<typeof customFieldToFormValues>;
};

export const CustomFieldModal = ({ onClose, field, passedValues }: CustomFieldModalProps) => {
  const createCustomField = useCreateCustomField();
  const updateCustomField = useUpdateCustomField();

  const { modalOpener, close: closeModal } = useModalContext();

  const [initialValues] = useState(() => (field ? customFieldToFormValues(field) : noValues));

  const { fieldProps, handleSubmit, setErrors, values, errors } = useForm({
    initialValues: passedValues ?? initialValues,
    validationStrategy: 'onBlur',
    validators: () => ({
      title: null,
      description: null,
      type: validator.fromPredicate(isCustomFieldType, 'Type is required'),
      options: null,
      is_required: null,
    }),
  });

  const submit = () => {
    handleSubmit((values) => {
      return (editing ? updateCustomField : createCustomField)
        .mutateAsync({
          ...values,
          id: editing ? field.id : 0,
          options: values.options.map((chip) => chip.value),
        })
        .then(closeModal)
        .catch((error) => {
          propagateBackendValidationErrors({
            error,
            setErrors,
            formErrors: errors,
          });
        });
    });
  };

  const editing = !!field?.id;

  // adds confirmation on close and reopens modal with current values back if
  // not confirmed
  const isDirty =
    initialValues.title !== values.title ||
    initialValues.type !== values.type ||
    initialValues.is_required !== values.is_required ||
    initialValues.description !== values.description ||
    initialValues.options.map((option) => option.value).join(' ') !==
      values.options.map((option) => option.value).join(' ');

  useModalBlock(
    isDirty
      ? modalOpener(ConfirmModal, {
          edit: editing,
          onClose: modalOpener(CustomFieldModal, { field, passedValues: values }),
        })
      : undefined,
  );
  //

  return (
    <>
      <ModalHeader title={`${editing ? 'Edit' : 'Add'} new custom field`} onClose={onClose} />
      <ModalShell
        submitText={editing ? 'Save changes' : 'Add field'}
        onClose={onClose}
        onSubmit={submit}
        loading={createCustomField.isLoading || updateCustomField.isLoading}
        className="space-y-3"
        disabled={!isDirty}
        disabledHint="No changes to save"
      >
        <InputField
          {...fieldProps('title')}
          title="Title"
          type="text"
          placeholder="Enter field title"
        />

        {/* TODO props along with data-* attributes are getting forwarded to the input */}
        <Tooltip
          content={
            !!editing && (
              <>
                A custom field's type cannot be changed after it's created. If you accidentally set
                it to the wrong type, no worries! Just delete the field, and then recreate it with
                the right type.
              </>
            )
          }
          className="mt-3"
        >
          <div>
            <SelectField
              {...fieldProps('type')}
              title="Type"
              placeholder="Select field type"
              options={customFieldOptions}
              disabled={!!editing}
            />
          </div>
        </Tooltip>
        {values.type === 'select_one' && (
          <ChipsInputField
            {...fieldProps('options')}
            splitter={STRING_SEPARATORS}
            placeholder="Enter select list options, comma separated"
            pasteProcessor={stringPasteProcessor}
          />
        )}
        <InputField
          {...fieldProps('description')}
          title="Description"
          placeholder="Add a brief description or instructions to assist your team"
          type="text"
        />
        <CheckboxField {...fieldProps('is_required')} title="Make this field mandatory" />
      </ModalShell>
    </>
  );
};
