import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { z } from 'zod';
import { formatZodError, record } from '~/common/utils';
import { posthog } from '~/root/services';
import { useFullOrderData } from './useOrder';
import { useSlideRead } from './useSlideRead';

// TODO importing legacy localStorage slide/annotation read state,
// remove after 2-3 months of using new slide read version
//
// ideally transfer reads to BE one day

const transformedLegacySlideReadSchema = z.object({
  slideIds: z.array(z.number()),
  annotationIdComments: z.record(z.number()),
});

const untransformedLegacySlideReadSchema = z
  .record(
    z.record(
      z.object({
        version: z.number().optional(),
        versioned: z.boolean().optional(),
        annotations: z
          .record(
            z.object({
              commented: z.boolean(),
              commentAmount: z.number(),
            }),
          )
          .optional(),
      }),
    ),
  )
  .transform((ordersRecord) => {
    const readState: z.infer<typeof transformedLegacySlideReadSchema> = {
      slideIds: [],
      annotationIdComments: {},
    };
    record.forEach(ordersRecord, (slidesRecord) => {
      record.forEach(slidesRecord, (slide, slideId) => {
        // I just json-diffed the payload before clicking on a slide and after,
        // don't ask me why versioned: false means read
        //  {
        //    439: {
        //      261: {
        // -      versioned: true
        // +      versioned: false
        //      }
        //    }
        //  }
        if (slide.versioned === false) {
          readState.slideIds.push(Number(slideId));
        }
        if (slide.annotations) {
          record.forEach(slide.annotations, (annotation, annotationId) => {
            // commented means that annotation has unread comments :shrug:
            if (annotation.commented === false) {
              // we'll save amount of comments to compare with current amount, unread if length mismatch
              readState.annotationIdComments[Number(annotationId)] = annotation.commentAmount;
            }
          });
        }
      });
    });
    return readState;
  });

const legacySlideReadSchema = z.union([
  transformedLegacySlideReadSchema,
  untransformedLegacySlideReadSchema,
]);

export const useLegacySlideReads = () => {
  const order = useFullOrderData();
  const { readSlide, readAnnotation } = useSlideRead();

  useEffect(() => {
    if (!order.revisions.length) {
      return;
    }

    const slides = JSON.parse(localStorage.getItem('slides') as string);
    if (!slides) {
      return;
    }

    const result = legacySlideReadSchema.safeParse(slides);
    if (!result.success) {
      captureException(new Error(formatZodError(result.error)));
      return;
    }

    const imported = { slides: [] as number[], annotations: [] as number[] };

    const orderSlides = order.revisions.map((revision) => revision.slides).flat();

    const slideAnnotations = record.fromEntries(
      order.slideAnnotations.map((slide) => [slide.id, slide]),
    );

    orderSlides.forEach((slide) => {
      if (result.data.slideIds.includes(slide.id)) {
        readSlide(slide.id);
        result.data.slideIds = result.data.slideIds.filter((id) => id !== slide.id);
        imported.slides.push(slide.id);
      }

      slideAnnotations[slide.id].annotations.forEach((annotation) => {
        if (annotation.comments.length === result.data.annotationIdComments[annotation.id]) {
          readAnnotation(slide.id, annotation.id);
          delete result.data.annotationIdComments[annotation.id];
          imported.annotations.push(annotation.id);
        }
      });
    });

    // importing for this order was complete, let's clean it up from the legacy reads
    localStorage.setItem('slides', JSON.stringify(result.data));

    if (imported.annotations.length || imported.slides.length) {
      posthog?.capture('imported_legacy_slide_read', {
        imported,
        order_id: order.id,
        $set: { _event_imported_legacy_slide_read_once: true },
      });
    }

    // should run only once, no need to run multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
