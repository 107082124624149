type Key = string | number;

export function keys<K extends Key, A>(record: Record<K, A>): Array<K> {
  return Object.keys(record) as Array<K>;
}

export function values<K extends Key, A>(record: Record<K, A>): Array<A> {
  return Object.values(record);
}

export function entries<K extends Key, A>(record: Record<K, A>): Array<[K, A]> {
  return Object.entries(record) as Array<[K, A]>;
}

export function fromEntries<K extends Key, A>(entries: [K, A][]): Record<K, A> {
  return Object.fromEntries(entries) as Record<K, A>;
}

export function forEach<K extends Key, A>(
  record: Record<K, A>,
  callback: (value: A, key: K) => void,
): void {
  for (const key in record) {
    if (Object.prototype.hasOwnProperty.call(record, key)) {
      callback(record[key], key);
    }
  }
}

export function shallowEqual<K extends Key, A>(a: Record<K, A>, b: Record<K, A>): boolean {
  return entries(a).every(([k, v]) => b[k] === v);
}

export function isObject(record: unknown) {
  return typeof record === 'object' && record !== null && Array.isArray(record) === false;
}
