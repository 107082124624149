import cx from 'classnames';
import { PackagesExplainedModal } from '~/components/Modal/Modals';
import type { PayWithMethodType, PaymentDetailsTotal } from '../domain';

type Props = {
  enoughCredits: boolean;
  isToppingUp: boolean;
  showCreditPackages: boolean;
  creditBalance: number;
  method: PayWithMethodType;
  total: PaymentDetailsTotal;
  setTopUpBind: (arg0: boolean) => () => void;
};

export const MethodContent = ({
  enoughCredits,
  isToppingUp,
  showCreditPackages,
  method,
  creditBalance,
  total,
  setTopUpBind,
}: Props) => {
  const getCreditsMessage = () => {
    if (!showCreditPackages) {
      return (
        <p className="cp__sub-title">Your card will be charged ${total.order - creditBalance}</p>
      );
    }

    if (!enoughCredits && creditBalance && isToppingUp) {
      return (
        <p className="cp__sub-title">
          You need ${Math.abs(total.order - creditBalance)} more credits. Top up below or&nbsp;
          <button
            type="button"
            className="color-lochmara inline-block"
            onClick={setTopUpBind(false)}
          >
            pay the difference by card
          </button>
        </p>
      );
    }

    if (!enoughCredits && creditBalance) {
      return (
        <p className="cp__sub-title">
          Your card will be charged ${total.order - creditBalance}, or pay with credits by&nbsp;
          <button
            type="button"
            className="color-lochmara inline-block"
            onClick={setTopUpBind(true)}
          >
            topping up here
          </button>
        </p>
      );
    }

    if (enoughCredits) {
      return (
        <p className="cp__sub-title">
          You will have ${total.remaining} credits remaining after payment. Want to top up your
          credits?
        </p>
      );
    }

    return (
      <p className="cp__sub-title">
        Buy credits to pay for your order. Any remaining credits can be used for future orders and
        will never expire.
      </p>
    );
  };

  if (method === 'credits') {
    return (
      <>
        {/* Hide notification if no credits */}
        {!!creditBalance && (
          <div
            className={cx('notification', {
              resolved: enoughCredits,
            })}
          >
            Current credit balance — <span className="semibold">${creditBalance}</span>
          </div>
        )}
        <PackagesExplainedModal color="grey" className="justify-center mt-3 -mb-1 mx-auto" />
        {getCreditsMessage()}
      </>
    );
  }

  // Pay with card
  return <p className="cp__sub-title">Pay total amount of ${total.pay} with your credit card</p>;
};
