import { FloatingPortal } from '@floating-ui/react';
import { useState, useEffect } from 'react';
import Tooltip from '~/components/ui/Tooltip';
type PositionType = {
  left: string;
  top: string;
};

const RestrictionOverlay = () => {
  const [position, setPosition] = useState<PositionType | null>(null);

  useEffect(() => {
    // @ts-ignore
    let timerId;

    if (position) {
      timerId = setTimeout(() => {
        setPosition(null);
      }, 3000);
    }

    return () => {
      // @ts-ignore
      clearTimeout(timerId);
    };
  }, [position]);

  // @ts-ignore
  const handleClick = (event) => {
    const top = `${event.clientY}px`;
    const left = `${event.clientX}px`;
    setPosition({
      top,
      left,
    });
  };

  return (
    <button type="button" className="restriction-overlay full-width" onClick={handleClick}>
      <FloatingPortal>
        {position && (
          <Tooltip {...position} className="restriction-overlay-tooltip">
            You can leave comments only on the latest version
          </Tooltip>
        )}
      </FloatingPortal>
    </button>
  );
};

export default RestrictionOverlay;
