import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Toast } from '~/common/components';
import { Any } from '~/common/utils';
import { axios } from '~/root';
import { useSetUser } from '~/root/Auth';
import { CardPaymentMethod, InvoicePaymentMethod } from '~/root/domain';
import { qk } from '~/root/query-keys';
import { RedirectError } from '~/root/queryClient';
import { useCreateCardToken } from '~/root/stripe';
import {
  AddCardPayload,
  AddPaymentMethodPayload,
  Billing,
  EditInvoicePayload,
  billingSchema,
} from '../domain';

const useSyncUserFields = () => {
  const setUser = useSetUser();

  return (billing: Billing) => {
    const userCreditCardRelatedFields = {
      cardDigits: null as null | string,
      hasCard: false,
      balance: billing.creditBalance,
    };

    if (billing.defaultPaymentMethod?.type === 'card') {
      userCreditCardRelatedFields.cardDigits = billing.defaultPaymentMethod.last4;
      userCreditCardRelatedFields.hasCard = true;
    }

    setUser(userCreditCardRelatedFields);
  };
};

export const useAddPaymentMethod = () => {
  const queryClient = useQueryClient();
  const syncUserFields = useSyncUserFields();

  return useMutation({
    mutationFn: (method: AddPaymentMethodPayload) => {
      return axios.post('/v1/customers/billing/methods', method).then((res) => {
        if ((res.data as Any).redirectUrl) {
          // Redirect a user to card confirmation
          throw new RedirectError({ redirectTo: (res.data as Any).redirectUrl });
          // otherwise we'll get validation errors in 3DS case, since BE
          // doesn't return anything useful, but execution continues
        }

        return billingSchema.parse(res.data.data);
      });
    },
    onSuccess: (billing) => {
      queryClient.setQueryData<Billing>(qk.billing, () => billing);
      syncUserFields(billing);
    },
  });
};

export const useAddCardPaymentMethod = () => {
  const createCardTokenMutation = useCreateCardToken();
  const addPaymentMethodMutation = useAddPaymentMethod();

  return useMutation({
    mutationFn: async ({
      default: isDefault,
      returnUrl,
    }: Omit<AddCardPayload, 'token' | 'type'>) => {
      const token = await createCardTokenMutation.mutateAsync();

      return addPaymentMethodMutation.mutateAsync({
        type: 'card' as const,
        default: isDefault,
        token: token.id,
        returnUrl,
      });
    },
  });
};

export const useDeletePaymentMethod = () => {
  const queryClient = useQueryClient();
  const syncUserFields = useSyncUserFields();

  return useMutation({
    mutationFn: (id: number) => {
      return axios
        .delete(`/v1/customers/billing/methods/${id}`)
        .then((res) => billingSchema.parse(res.data.data));
    },
    onSuccess: (billing) => {
      queryClient.setQueryData<Billing>(qk.billing, () => billing);
      syncUserFields(billing);
    },
  });
};

export const useUpdatePaymentMethod = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, payload }: { id: number; payload: EditInvoicePayload }) => {
      return axios
        .put(`/v1/customers/billing/methods/${id}`, payload)
        .then((res) => billingSchema.parse(res.data.data));
    },
    onSuccess: (billing) => {
      queryClient.setQueryData<Billing>(qk.billing, () => billing);
    },
  });
};

export const useSetDefaultPaymentMethod = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => {
      return axios.put(`/v1/customers/billing/methods/${id}`, { default: true }).then(() => id);
    },
    onSuccess: (id) => {
      queryClient.setQueryData<Billing>(qk.billing, (billing) => {
        if (!billing) {
          return;
        }

        const paymentMethods = billing.paymentMethods.map((method) => ({
          ...method,
          default: method.id === id,
        }));

        return {
          ...billing,
          paymentMethods,
          invoicePaymentMethod: paymentMethods.find(
            (paymentMethod): paymentMethod is InvoicePaymentMethod =>
              paymentMethod.type === 'invoice',
          ),
          cardPaymentMethods: paymentMethods.filter(
            (paymentMethod): paymentMethod is CardPaymentMethod => paymentMethod.type === 'card',
          ),
          defaultPaymentMethod: paymentMethods.find((method) => method.default),
        };
      });
    },
  });
};

export const useSetDefaultPaymentMethodSubmit = () => {
  const setDefaultPaymentMethodMutation = useSetDefaultPaymentMethod();

  const submit = (id: number) => {
    setDefaultPaymentMethodMutation
      .mutateAsync(id)
      .then(() =>
        Toast.success({ message: 'You successfully changed your default payment method.' }),
      )
      .catch((error) => {
        Toast.error({
          message:
            'Something went wrong. Please try again or contact our support team for assistance.',
        });

        captureException(error);
      });
  };

  return submit;
};
