import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useDelayGroup,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStatus,
} from '@floating-ui/react';
import { Strategy } from '@floating-ui/utils';
import { cloneElement, ReactNode, useRef, useState } from 'react';
import { cx } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import styles from './Tooltip.module.scss';

export type TooltipColor = 'white' | 'grey' | 'darkgrey' | 'primary';

type Props = {
  content: ReactNode;
  color?: TooltipColor;
  className?: string;
  placement?: Placement;
  fallbackPlacements?: Placement[];
  delay?: number;
  compensateOffset?: number;
  noArrow?: boolean;
  clickable?: boolean;
  strategy?: Strategy;
} & (
  | { children?: never; reference: Element | null }
  | { children: JSX.Element; reference?: never }
);

export const Tooltip = ({
  content,
  children,
  color = 'white',
  className,
  placement = 'top',
  fallbackPlacements = ['top', 'bottom', 'left', 'right'],
  delay = 300,
  compensateOffset = 0,
  noArrow = false,
  clickable = false,
  strategy = 'absolute',
  reference,
}: Props) => {
  const [open, setOpen] = useState(false);

  const arrowRef = useRef<SVGSVGElement>(null);

  const { context, refs, floatingStyles } = useFloating({
    placement,
    open: open && !!content,
    elements: {
      reference,
    },
    onOpenChange: setOpen,
    middleware: [
      offset(12 - compensateOffset),
      flip({ fallbackPlacements }),
      shift({ padding: 8 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
    strategy,
  });

  const { delay: groupDelay, isInstantPhase } = useDelayGroup(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      // delayGroup
      delay: groupDelay === 0 ? { open: delay, close: 150 } : groupDelay,
      restMs: 40,
      handleClose: clickable ? safePolygon({ blockPointerEvents: true }) : undefined,
    }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  const { isMounted, status } = useTransitionStatus(context, {
    duration: groupDelay || 250,
  });

  return (
    // TODO use render-props instead of cloneElement to be able to merge
    // multiple ref callbacks from above, make multiRef(ref1, ref2) utility
    // use-case - dropdown popover & tooltip on select fields on the same node
    <>
      {reference === undefined &&
        cloneElement(children, getReferenceProps({ ref: refs.setReference, ...children.props }))}

      {isMounted && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              className: cx(
                styles.tooltip,
                styles[color],
                className,
                fade.floating,
                clickable && 'pointer-events-auto',
              ),
              style: floatingStyles,
              onClick: () => setOpen(false),
            })}
            data-status={status}
            data-instant={isInstantPhase}
          >
            {!noArrow && (
              <FloatingArrow
                context={context}
                ref={arrowRef}
                width={12}
                height={6}
                className={cx(styles.arrow, styles[color])}
                tipRadius={2}
                strokeWidth={1}
              />
            )}
            {content}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
