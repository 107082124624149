import { createContextPair } from '~/common/kits/context';
import { NavigationProps } from './types';

export const [useNavigation, withNavigation] = createContextPair(
  ({ isFromHeader = false, isMenuCollapsed = false }: NavigationProps) => {
    return {
      isFromHeader,
      isMenuCollapsed,
    };
  },
)('Navigation');
