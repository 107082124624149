import MenuItem from '@material-ui/core/MenuItem';
import { CreditPackage as CreditPackageType } from '~/root/domain';
import { useConfirmationContext } from './confirmationContext';
import { OrdersSelect } from './OrdersSelect';
import { SelectItem } from './SelectItem';

type Props = {
  packages: CreditPackageType[];
};

export const CreditPackage = ({ packages }: Props) => {
  const { creditPackageId, setPackage } = useConfirmationContext();

  const packageList = packages.map(({ id, ...other }, look) => (
    <MenuItem key={id} value={id}>
      <SelectItem {...other} type="package" look={look} />
    </MenuItem>
  ));

  const handleClear = () => {
    // @ts-ignore
    setPackage('');
  };

  return (
    <div>
      <OrdersSelect
        clearable
        className="package-select"
        label="Select Credit Package"
        value={creditPackageId}
        onChange={setPackage}
        onReset={handleClear}
      >
        {packageList}
      </OrdersSelect>
    </div>
  );
};
