import { Any } from '~/common/utils';

export const commentStorage = {
  /**
   * Comment storage implemented via local storage
   * because useRecuder comment calls Popove ref conflict on change
   */
  get() {
    return localStorage.getItem('commentModeComment') ?? '';
  },

  set(comment: string) {
    localStorage.setItem('commentModeComment', comment);
  },

  reset() {
    localStorage.removeItem('commentModeComment');
  },
};

export const handleConfirmClickBind =
  (onClick: Any) =>
  (...args: Array<Any>) => {
    // The function prevents `onClick` execution if a user has not sent comment
    const comment = commentStorage.get();

    if (comment && onClick) {
      const isSure: boolean = window.confirm(
        'Do you want to continue without saving a comment? Your data will be lost.',
      );

      if (isSure) {
        commentStorage.reset();
        onClick(...args);
      }
    } else if (onClick) {
      onClick(...args);
    }
  };
