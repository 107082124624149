import { ComponentProps, ForwardRefExoticComponent, forwardRef } from 'react';
import { useDevice } from '~/common/kits/device';
import { cx } from '~/common/utils';
import { TESTING } from '~/env';
import { IconBox } from '../IconContainers';
import { ThreeDots } from './ThreeDots';
import { ButtonProps, IconProps, ResponsiveButtonProps } from './types';
import css from './styles.module.scss';

type Button = ForwardRefExoticComponent<ButtonProps> & ButtonNamespace;

const ButtonBase = forwardRef<HTMLButtonElement, ComponentProps<'button'>>((props, ref) => (
  <button
    {...props}
    className={cx(props.className, 'focus-visible:ring transition-all')}
    ref={ref}
    type={props.type ?? 'button'}
    // apparently firefox only ignores onClick, while chrome ignores every
    // event, breaking tooltips on disabled elements
    disabled={TESTING ? props.disabled : false}
    onClick={props.disabled ? undefined : props.onClick}
    tabIndex={props.disabled ? -1 : props.tabIndex}
  />
));

export const Button = forwardRef((props, ref) => {
  const {
    children,
    color = 'primary',
    size = 's',
    loading = false,
    disabled = false,
    className,
    icon,
    ...restProps
  } = props;
  return (
    <ButtonBase
      {...restProps}
      className={cx(css.button, css[color], css[size], className, {
        [css.loading]: loading,
        [css.disabled]: disabled,
      })}
      disabled={loading || disabled}
      ref={ref}
    >
      {icon && <IconBox className={css.iconContainer} icon={icon} />}
      {typeof children === 'string' ? (
        <span className="whitespace-nowrap">{children}</span>
      ) : (
        children
      )}
      {loading && <ThreeDots className={css.dots} width={24} />}
    </ButtonBase>
  );
}) as Button;

const ButtonIcon = forwardRef<HTMLButtonElement, IconProps>((props, ref) => {
  const {
    icon,
    loading = false,
    disabled = false,
    size = 's',
    iconSize = size,
    className,
    ...restProps
  } = props;
  return (
    <ButtonBase
      {...restProps}
      className={cx(css.icon, className, css[size], {
        [css.loading]: loading,
        [css.disabled]: disabled,
      })}
      disabled={loading || disabled}
      ref={ref}
    >
      <IconBox className={css.iconContainer} size={iconSize} icon={icon} />
      {loading && <ThreeDots className={css.dots} width={24} />}
    </ButtonBase>
  );
});

/**
 * Button that is rendered as a button on desktop and as an icon on mobile
 */
export const ButtonResponsive = forwardRef<HTMLButtonElement, ResponsiveButtonProps>(
  ({ size = 's', children, ...restProps }, ref) => {
    const screenSize = useDevice();
    return screenSize === 'MOBILE' ? (
      <ButtonIcon {...restProps} ref={ref} />
    ) : (
      <Button {...restProps} color="text" size={size} ref={ref}>
        {children}
      </Button>
    );
  },
);

type ButtonNamespace = {
  Base: typeof ButtonBase;
  Icon: typeof ButtonIcon;
  Responsive: typeof ButtonResponsive;
};

Button.Base = ButtonBase;
Button.Icon = ButtonIcon;
Button.Responsive = ButtonResponsive;
