import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ConfirmationDetails } from '~/pages/My-profile/Order/domain';
import { CreditPackage, PaymentMethod } from '~/root/domain';
import type { PayWithMethodType, PaymentDetailsTotal, PaymentDetailsVat } from '../domain';

dayjs.extend(relativeTime);

/**
 * "123.45" > "123.45"
 * "123.2799999999997" > "123.28"
 * 123 > "123"
 * 1.456 > "1.46"
 */
const fixedFloat = (number: number | string, digit = 2): number =>
  +parseFloat(number as string)
    .toFixed(digit)
    .replace(/\.?0+$/, '');

export const getInitialCreditPackage = (enoughCredits: boolean, packages: CreditPackage[]) => {
  if (enoughCredits) {
    // Do not set credit package by default
    return '';
  }

  // Set first (the cheapest) credit package by default
  return packages[0]?.id || '';
};

export const getInitialPaymentMethod = (methods: PaymentMethod[]) => {
  /**
   * Select default payment method if it exists
   *
   * Otherwise make payment method unselected
   * to show new card adding form
   */
  const methodId = methods.find(({ type, default: isDefault }) => isDefault && type === 'card')?.id;

  if (methodId) {
    return methodId;
  }

  /**
   * `invoice` can be default payment method,
   * but it can't be used on conirmation popup
   *
   * Return first card payment method in this case
   */
  return methods.find(({ type }) => type === 'card')?.id || '';
};

export const getDeadlineLabel = (timestamp: number | null | undefined) => {
  if (!timestamp) {
    return {
      value: timestamp,
    };
  }

  const deadline = dayjs(timestamp);
  return {
    value: deadline.fromNow(),
    title: deadline.format('Do MMMM [at] hh:mm A'),
  };
};

export const getConfirmationPaymentDetails = ({
  enoughCredits,
  isToppingUp,
  payWithMethod,
  currentPackage,
  order: { unit, discount },
  billing: { creditBalance },
  paymentMethod,
}: {
  enoughCredits: boolean;
  isToppingUp: boolean;
  payWithMethod: PayWithMethodType;
  currentPackage?: CreditPackage;
  order: ConfirmationDetails['order'];
  billing: ConfirmationDetails['billing'];
  paymentMethod?: PaymentMethod;
}) => {
  const amount = currentPackage?.amount || 0;
  const payWithCredits = payWithMethod === 'credits';
  const difference = unit - discount - creditBalance;
  const vatPercent = paymentMethod?.vat ?? 0;
  const vat: PaymentDetailsVat = {
    percent: vatPercent,

    get fraction() {
      return this.percent / 100;
    },

    get credits() {
      /**
       * - `payWithCredits` is `true` only when "Pay with credits" tab is active
       * - `isToppingUp` payment with credit package purchasing
       */
      if (payWithCredits && isToppingUp) {
        return fixedFloat(amount * this.fraction);
      }

      return 0;
    },

    get order() {
      if (!payWithCredits) {
        /**
         * `payWithMethod` is `card`
         */
        return fixedFloat((unit - discount) * this.fraction);
      }

      if (!isToppingUp) {
        /**
         * `isToppingUp` is `true` when credit package purchasing
         * This case while paying the difference by card
         */
        return fixedFloat(difference * this.fraction);
      }

      return 0;
    },
  };
  const total: PaymentDetailsTotal = {
    order: unit + vat.order - discount,

    get credits() {
      if (vat.credits) {
        return amount + vat.credits;
      }

      return 0;
    },

    get pay() {
      if (payWithCredits && isToppingUp) {
        return vat.credits + amount;
      }

      if (payWithCredits) {
        return difference + vat.order;
      }

      return this.order;
    },

    get remaining() {
      if (enoughCredits && !this.pay) {
        return fixedFloat(creditBalance - total.order);
      }

      const remaining =
        isToppingUp && currentPackage ? creditBalance + currentPackage.credits - total.order : 0;
      return fixedFloat(Math.max(0, remaining));
    },
  };
  return {
    enoughCredits,
    isToppingUp,
    creditBalance,
    payWithMethod,
    vat,
    total,
    currentPackage,
  };
};
