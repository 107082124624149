import { z } from 'zod';
import { orderStatus } from '~/pages/My-profile/Orders/domain';
import { DateFromIsoString, uploadedFileSchema } from '~/root/domain';
import { customFieldWithValueSchema } from '../../components/CustomFields';
import { membersSchema } from './members';

const baseOrderSchema = z.object({
  id: z.number(),
  title: z.string().nullable(),
  creationTime: z.number(),
  price: z.string(),
  proofreading: z.string().nullable(), //TODO: figure out if it's right type
  slides: z.number(),
  status: orderStatus,
  statusDescription: z.string().nullable(),
  style: z.string().nullable(), //TODO: might be union
  treatment: z.string(), //TODO: might be union
  isConfidential: z.boolean(),
});

export const accessibleOrderSchema = baseOrderSchema
  .extend({
    brief: z.object({
      brief: z.string(),
      deliverable: z.string().nullable(),
      diversity: z.string().nullable(),
      purpose: z.string().nullable(),
      scope: z.string().nullable(),
      scheduledAt: DateFromIsoString.nullable(),
      shouldCreateTemplate: z.string().nullable(),
      googleSlidesUrls: z.array(z.string()).nullable(),
      referenceFiles: z.array(uploadedFileSchema).nullable(),
    }),
    completed: z.boolean(),
    downloadPresentationUrl: z.string().nullable(),
    downloadPreviewsUrl: z.string().nullable(),
    downloadable: z.boolean(),
    discount: z.string(),
    files: z.array(uploadedFileSchema),
    manager: z
      .object({
        calendly: z.string().nullable(),
        initials: z.string(),
        name: z.string(),
        online: z.boolean(),
        opensAt: z.string().nullable(),
        role: z.string(),
      })
      .nullable(),
    members: membersSchema,
    customFields: z.array(customFieldWithValueSchema).nonempty().nullable().catch(null),
    isAccessible: z.literal(true),
  })
  .transform((order) => {
    // owner should be kickable for confidential orders where they are not the order creator
    if (order.isConfidential && order.members.creator?.id !== order.members.owner?.id) {
      const owner = order.members.members.find((member) => member.id === order.members.owner?.id);
      if (owner) {
        owner.editable = true;
      }
    }
    return order;
  });

const inaccessibleOrderSchema = baseOrderSchema.extend({
  isAccessible: z.literal(false),
});

export const orderSchema = accessibleOrderSchema.or(inaccessibleOrderSchema);

export type Order = z.infer<typeof orderSchema>;

export type PayWithMethodType = 'card' | 'credits';

export type PaymentDetailsVat = {
  percent: number;
  fraction: number;
  order: number;
  credits: number;
};

export type PaymentDetailsTotal = {
  order: number;
  credits: number;
  pay: number;
  remaining: number;
};
