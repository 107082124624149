import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { ENV } from '~/env';
import { rootReducer } from './modules';

const initialState = {};
const middleware = [thunk];

export const store = createStore(
  rootReducer,
  initialState,
  ENV.PRODUCTION
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware)),
);

// @ts-ignore
if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  // @ts-ignore
  module.hot.accept('./modules', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const nextRootReducer = require('./modules').default;
    store.replaceReducer(nextRootReducer);
  });
}
