import { faMoneyBill1Wave } from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';
import { IconBox, Link } from '~/common/components';
import { formatMoney } from '~/common/utils';
import { routes } from '~/constants';
import { useCurrentUserData } from '../../../../Auth';
import { useNavigation } from '../../../context';

export const CreditBalanceMenu = () => {
  const { manageCredits, seeCreditBalance, balance } = useCurrentUserData();
  const { isMenuCollapsed } = useNavigation();

  if (!manageCredits && !seeCreditBalance) {
    return null;
  }

  const iconClassName = cx('text-text-500 hover:text-primary-400 transition-colors duration-300', {
    'mt-[2px]': !isMenuCollapsed,
  });

  return (
    <div className="flex px-2 py-[12px]">
      {manageCredits ? (
        <Link.Base
          to={routes.profile.billing.credits}
          className={iconClassName}
          data-tt={cx(isMenuCollapsed && 'Get more credits')}
        >
          <IconBox size="s" icon={faMoneyBill1Wave} />
        </Link.Base>
      ) : (
        <IconBox
          size="s"
          className={iconClassName}
          icon={faMoneyBill1Wave}
          data-tt={cx(isMenuCollapsed && 'Get more credits')}
        />
      )}

      {!isMenuCollapsed && (
        <div className="flex flex-col gap-1 ml-1">
          <span className="font-brand-sm">Payment details</span>
          <p className="font-brand-h5">
            {formatMoney(balance)} <span className="def:font-brand-b3 font-medium">available</span>
          </p>
          <Link size="m" to={routes.profile.billing.credits}>
            Get more credits
          </Link>
        </div>
      )}
    </div>
  );
};
