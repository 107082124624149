import { faMessage } from '@fortawesome/pro-regular-svg-icons';
import { Button, IconBox } from '~/common/components';

export const Beacon = () => {
  return (
    <div className="fixed bottom-5 right-5 z-50">
      <Button.Base className="w-[152px] h-[60px] flex items-center justify-center gap-1 bg-secondary-300 text-white font-brand-l3 rounded-[28px] shadow-lg hover:scale-110 transition-transform">
        <IconBox icon={faMessage} size="l" />
        <span>Need help?</span>
      </Button.Base>
    </div>
  );
};
