import { useEffect, useRef, useState } from 'react';

// TODO maybe it's a dumb hook, can't think of something better rn
export const useDebouncedSetter = <T>(
  value: T,
  setter: (value: T) => void,
  delay: number = 3000,
) => {
  const [internalValue, setInternalValue] = useState<T>(value);

  const ref = useRef<{ callback: () => void; timer: NodeJS.Timeout } | null>(null);

  const setValue = (value: T) => {
    if (ref.current) {
      clearTimeout(ref.current.timer);
    }

    setInternalValue(value);

    const callback = () => setter(value);
    ref.current = { callback, timer: setTimeout(callback, delay) };
  };

  useEffect(() => {
    return () => {
      if (ref.current) {
        clearTimeout(ref.current.timer);
        // we'll store current message when unmounting immediately
        ref.current.callback();
        ref.current = null;
      }
    };
  }, []);

  return [internalValue, setValue] as const;
};
