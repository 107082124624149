import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Toast, useModalContext } from '~/common/components';
import { setCookie } from '~/common/kits/cookie';
import { assertQueryData } from '~/common/kits/query';
import { nonNullable } from '~/common/utils';
import { LoginModal } from '~/components/Modal/Modals';
import { ENV } from '~/env';
import { axios, userStore } from '~/root';
import { qk } from '~/root/query-keys';
import { CustomerUser, Init, initSchema, User, userSchema } from '../domain';
import { markAsPreviouslyLoggedIn, authorizeUser, logoutRequest } from '../utils';

const setClientId = () => {
  if (window.ga) {
    window.ga((tracker) => {
      const clientId = tracker.get('clientId');
      setCookie('_gaClientId', clientId);
    });
  } else if (ENV.PRODUCTION) {
    // eslint-disable-next-line no-console
    console.warn('GA is switched off');
  }
};

export const useInit = () => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: qk.init,
    queryFn: async () => {
      const data = await axios.get('/v1/init').then((res) => res.data);
      const init = initSchema.parse(data);

      // why not set init query and make currentUser hook get user from the init
      queryClient.setQueryData(qk.me, () => init.user);
      setClientId();

      if (init.user) {
        authorizeUser(init.user);
        markAsPreviouslyLoggedIn();
      }

      return init;
    },

    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const useInitData = assertQueryData(useInit);

const FIVE_MIN = 300000;

export const TokenExpirationToast = () => {
  const { modalOpener } = useModalContext();

  return (
    <p>
      Your session has expired, please{' '}
      <Button color="text" onClick={modalOpener(LoginModal)} className="inline-flex !font-brand-b1">
        login
      </Button>{' '}
      again
    </p>
  );
};

export const useMe = () => {
  const setUser = useSetUser();
  const init = useInit();

  return useQuery({
    queryKey: qk.me,
    queryFn: () =>
      axios.get('/v1/users/me').then((res) => {
        const user = userSchema.parse({ ...res.data.user, token: res.data.token });

        if (!user.token) {
          Toast.warning({
            message: <TokenExpirationToast />,
            id: 'TokenExpirationToast',
          });

          logoutRequest();
        }

        userStore.setState({
          id: String(user.id),
          name: user.name,
          email: user.name,
          company: user.company,
          customerAccountId: user.customerAccountId,
        });
        markAsPreviouslyLoggedIn();
        // Just TS fix, user can be staff too)
        setUser(user as CustomerUser);

        return user;
      }),
    staleTime: FIVE_MIN,
    refetchInterval: FIVE_MIN,
    enabled: Boolean(init.data?.user),
  });
};

export const useSetUser = () => {
  const queryClient = useQueryClient();

  return <U extends User>(userUpdate?: Partial<U>) =>
    queryClient.setQueryData<Init>(qk.init, (init) => {
      if (init && userUpdate) {
        return {
          ...init,
          user: { ...init?.user, ...userUpdate } as User,
        };
      }
    });
};

export const useCurrentUser = () => useInit().data?.user;
export const useCurrentUserData = () => nonNullable(useInit().data?.user);
