import { faCoin } from '@fortawesome/pro-light-svg-icons';
import { IconBox, Link } from '~/common/components';
import { cx } from '~/common/utils';
import { routes } from '~/constants';
import { useCurrentUser } from '../../../../Auth';
import { useNavigation } from '../../../context';

export const CreditOfferMenu = () => {
  const { isMenuCollapsed } = useNavigation();
  const user = useCurrentUser();

  if (!user?.manageCredits) {
    return null;
  }

  return (
    <div className="flex px-2 py-[12px]">
      <Link.Base
        to={routes.profile.billing.credits}
        className={cx('text-text-500 hover:text-primary-400 transition-colors duration-300', {
          'mt-[2px]': !isMenuCollapsed,
        })}
        data-tt={cx(isMenuCollapsed && 'Buy credit pack')}
      >
        <IconBox size="s" icon={faCoin} />
      </Link.Base>

      {!isMenuCollapsed && (
        <div className="ml-1">
          <p className="font-brand-sm mb-1">
            <span className="bold">Save up to 10%</span> on your orders
          </p>

          <Link size="m" to={routes.profile.billing.credits}>
            Buy credit pack
          </Link>
        </div>
      )}
    </div>
  );
};
