import { useEffect } from 'react';
import { posthog } from '~/root/services';
import './dvh-fallback.scss';

export const useDvhFallback = () => {
  useEffect(() => {
    const isDvhSupported = getComputedStyle(document.documentElement)
      .getPropertyValue('--dvh')
      .includes('dvh');

    if (isDvhSupported) {
      return;
    }

    // just curious how many clients aren't supporting dvh units
    posthog.capture('dvh-not-supported');

    const handleResize = () => {
      const visualVh = window.visualViewport?.height || window.innerHeight;
      document.documentElement.style.setProperty('--dvh', `${visualVh}px`);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    handleResize();
  }, []);
};
