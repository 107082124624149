import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ZodError } from 'zod';
import { useIdParam } from '~/common/hooks';
import { assertQueryData } from '~/common/kits/query';
import { axios } from '~/root';
import { UploadedFileId } from '~/root/domain';
import { qk } from '~/root/query-keys';
import { CommentsEvents, commentSchema, commentsEventsSchema } from '../domain';
import { setSubscribedTo, useUpdateOrderCache } from './useOrder';

export const normalizeEvents = ({ comments, events }: CommentsEvents) => {
  const commentList = comments.map((comment) => ({
    type: 'comment' as const,
    ...comment,
  }));
  const eventList = events.map((event) => ({
    type: 'event' as const,
    ...event,
  }));
  return [...commentList, ...eventList].sort((a, b) => a.timestamp - b.timestamp);
};

export type NormalizedEvents = ReturnType<typeof normalizeEvents>;

export const useEvents = () => {
  const id = useIdParam();

  return useQuery({
    queryKey: qk.orderEvents(id),
    queryFn: async ({ signal }) => {
      return axios
        .get(`/v1/orders/${id}/comments`, { signal })
        .then((res) => {
          const response = commentsEventsSchema.parse(res.data);
          return normalizeEvents(response);
        })
        .catch((error) => {
          if (error instanceof ZodError) {
            throw error;
          }
          return [] as ReturnType<typeof normalizeEvents>;
        });
    },
    refetchOnWindowFocus: true,
  });
};

export const useEventsData = assertQueryData(useEvents);

export type CommentPayload = {
  comment: string;
  files: (string | UploadedFileId)[];
};

export const usePostComment = () => {
  const id = useIdParam();
  const client = useQueryClient();
  const { setQuery } = useUpdateOrderCache();

  return useMutation({
    mutationFn: (payload: CommentPayload) => {
      return axios.post(`/v1/orders/${id}/comments`, payload).then((res) => {
        const comment = commentSchema.parse(res.data.comment);
        client.setQueryData<NormalizedEvents>(qk.orderEvents(id), (prev) => {
          return prev && [...prev, { ...comment, type: 'comment' as const }];
        });
        setQuery((prev) => setSubscribedTo(true, prev));
        return comment;
      });
    },
  });
};
