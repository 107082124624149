import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import { Switch, Redirect, Route, useHistory, useLocation } from 'react-router-dom';

import { GlobalTooltip, withModalContext } from '~/common/components';
import { isObject } from '~/common/utils';
import { CookieSettings } from '~/components/CookieSettings';
import { routes } from '~/constants';
import { ENV, TESTING } from '~/env';
import { LoadingPage } from '~/pages';

import { hotjar, posthog } from '~/root/services';
import { useMe, useInit } from '../Auth';
import { hasPreviouslyLoggedIn } from '../Auth/utils';
import { Beacon } from '../beacon';
import { useFreshTabs } from '../fresh-tabs';
import { useDvhFallback } from '../hacks/dvh-fallback';
import { useLoggedInTabInitInvalidation } from '../invalidate-init';
import { AppRoute } from './AppRoute';
import { useReferrer } from './referrer';
import { appRoutes } from './routes';

const redirectToStaff = () => {
  const { origin } = window.location;
  window.location.href = origin.includes('local')
    ? 'https://staff.24slides.dev'
    : origin.replace(/\bapp\b/, 'staff');
  return null;
};

export const Root = withModalContext(() => {
  const history = useHistory();
  const location = useLocation<{ referrer?: string }>();
  const init = useInit();
  const referrer = useReferrer();

  useMe();

  useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.send({ hitType: 'pageview', page: location.pathname }); // Record a pageview for the given page
    hotjar('stateChange', location.pathname);

    history.listen(({ pathname: page }) => {
      ReactGA.set({ page });
      ReactGA.send({ hitType: 'pageview', page });
      posthog?.capture('$pageview');
      hotjar('stateChange', page);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFreshTabs();
  useLoggedInTabInitInvalidation();
  useDvhFallback();

  return (
    <div className="app">
      <Helmet
        titleTemplate="%s | 24Slides"
        defaultTitle="Get Your PowerPoint Presentation Designed"
      >
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      </Helmet>

      {init.data && <CookieSettings />}
      <GlobalTooltip />
      <Switch>
        <Route exact path={routes.loading} component={LoadingPage} />

        {appRoutes.redirections.map(({ from, to }) => (
          <Redirect exact from={from} to={to} key={`${from}${to}`} />
        ))}

        {init.data &&
          //  we should wait for init data before redirecting to login page from /
          (init.data.user?.isStaff ? (
            <Route exact path="/" render={redirectToStaff} />
          ) : (
            <Redirect exact from="/" to={init.data.user ? routes.profile.index : routes.login} />
          ))}

        {init.data &&
          appRoutes.modules.map(
            ({ url, parameters, module, isExact, layout, redirectWhenLoggedIn, getVisibility }) => {
              const routePath = getRoutePath(url, parameters);

              // redirect to signup and back for currently inaccessible routes
              if (!init.data?.user && !getVisibility(null)) {
                return (
                  <Redirect
                    from={url as string}
                    to={{
                      // TODO this starts to look really stupid in the code
                      // we should find a way to make it better once specs
                      // and tests are there
                      //
                      // see other changes from this commit
                      pathname: hasPreviouslyLoggedIn() ? routes.login : routes.signup,
                      state: { referrer: location.pathname },
                    }}
                    key={url as string}
                  />
                );
              }

              const role = init.data?.user?.isStaff ? 'staff' : 'customer';
              const visible = getVisibility(init.data?.user ? role : null);

              // when logged in, redirect to homeUrl on irrelevant routes
              // such as /login /signup etc. and on inaccessible routes
              if (!visible || (init.data?.user && redirectWhenLoggedIn)) {
                return init.data?.user?.isStaff ? (
                  <Route key={url as string} path={routePath} render={redirectToStaff} />
                ) : (
                  <Redirect
                    exact
                    from={url as string}
                    to={referrer || routes.profile.index}
                    key={url as string}
                  />
                );
              }

              return (
                <AppRoute
                  key={`${url}${parameters}`}
                  path={routePath}
                  exact={isExact}
                  component={module}
                  layout={layout.component}
                  layoutProps={layout.props}
                />
              );
            },
          )}
      </Switch>

      <Toaster
        position="top-right"
        reverseOrder={false}
        containerClassName="!top-[75px] hot-toast"
        gutter={8}
        toastOptions={{
          duration: 8000,
        }}
      />

      {!ENV.PRODUCTION && !TESTING && <Beacon />}
    </div>
  );
});

const getRoutePath = (url: string | Record<string, string> | string[], params: string) => {
  if (Array.isArray(url)) {
    return url;
  } else if (isObject(url)) {
    return Object.keys(url);
  }

  return `${url}${params}`;
};
