import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { isObject } from '~/common/utils';
import { ComponentNames } from '~/pages/Order-now/constants';
import validation from '~/pages/Order-now/formValidation';
import { isEmpty } from '~/utils/helpers';

dayjs.extend(advancedFormat);

export const getComponentDataByKey = ({ name = '', components = [] }) => {
  // @ts-ignore
  const componentData = components.find((component) => component.name === name);
  // Component data is kinda { ..., data: { componentName: { ... } } };
  // Function skips `data` object's key and spread component data directly
  // For `addons` component example: `componentData.data.addons` > `componentData.addons
  // For `treatments` component example: `componentData.data.treatments` > `componentData.treatments
  // etc.
  // @ts-ignore
  const { data = {}, ...rest } = componentData;

  if (!rest.properties) {
    rest.properties = {};
  }

  return { ...rest, ...data };
};

// @ts-ignore
export const getValidationSchema = (components) =>
  // @ts-ignore
  components.reduce((schema, component) => {
    if (component.fields) {
      return {
        ...schema,
        // @ts-ignore
        ...component.fields.reduce((props, { name, rules, messages }) => {
          if (rules) {
            // Testing condition: check if we got the same unique field.name
            // If `schema[name]` exists, refactor `getValidationSchema` to [component.fields]: { [field.name]: [...] };
            if (schema[name]) {
              console.error(`Field ${name} is not unique. Refactor "getValidationSchema"`);
              return props;
            }

            // We cant't add default value `{}`, because `messages` can be `null`
            const errorMessages = messages || {};

            return {
              ...props,
              [name]: [
                ...Object.entries(rules).reduce(
                  (rule, [key, value]) =>
                    // @ts-ignore
                    validation[key] ? [...rule, validation[key](value, errorMessages[key])] : rule,
                  [],
                ),
              ],
            };
          }
          return props;
        }, {}),
      };
    }
    return schema;
  }, {});

// @ts-ignore
export const getFormHandlerInitialValues = (components, details) =>
  // @ts-ignore
  components.reduce((schema, component) => {
    if (component.fields) {
      return {
        ...schema,
        // @ts-ignore
        ...component.fields.reduce((props, { name, value }) => {
          const detailsValue = details?.[component.name]?.[name];
          const initialValue = detailsValue || value;
          if (!(initialValue === null || initialValue === undefined)) {
            return {
              ...props,
              [name]: initialValue,
            };
          }
          return props;
        }, {}),
      };
    }
    return schema;
  }, {});

// @ts-ignore
export const debounce = (func, ms = 3000) => {
  // @ts-ignore
  let lastCall;
  // @ts-ignore
  let lastCallTimer;

  return {
    stopDebounce: () => {
      // @ts-ignore
      clearTimeout(lastCallTimer);
    },
    // @ts-ignore
    debounce: (args) => {
      // @ts-ignore
      const previousCall = lastCall;
      lastCall = Date.now();
      if (previousCall && lastCall - previousCall <= ms) {
        // @ts-ignore
        clearTimeout(lastCallTimer);
      }
      lastCallTimer = setTimeout(() => func(args), ms);
    },
  };
};

// @ts-ignore
export const parseDetails = (details) =>
  Object.values(details).reduce((acc, detail) => {
    if (isObject(detail)) {
      // @ts-ignore
      return { ...acc, ...detail };
    }

    return acc;
  }, {});

export const getTimeInMinutes = () => Math.ceil(new Date().getTime() / 1000 / 60);

// @ts-ignore
export const getComponentFields = (components, componentName) => {
  // @ts-ignore
  const component = components.find((item) => item.name === componentName) || {};
  return component.fields || [];
};

export const removeOrderDataFromLocalStorage = () => {
  try {
    localStorage.removeItem('orderData');
  } catch (error) {
    console.error(error);
  }
};

export const getOrderDataFromLocalStorage = () => {
  try {
    const serializedData = localStorage.getItem('orderData');

    if (serializedData === null) {
      return {};
    }

    return JSON.parse(serializedData);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

// @ts-ignore
export const putOrderDataIntoLocalStorage = ({ details, name: formName }) => {
  try {
    const { details: localStorageDetails = {} } = getOrderDataFromLocalStorage();

    // Complementing local storage details by current details
    const mergedDetails = Object.keys(details).reduce((accumulator, name) => {
      // Skip `cardToken` saving
      if (name === ComponentNames.PAYMENT_DETAILS) {
        const { cardToken, ...rest } = details[name];
        return {
          ...accumulator,
          [name]: { ...rest },
        };
      }

      // Skip `password` saving
      if (name === ComponentNames.SIGNUP) {
        const { password, passwordConfirmation, ...rest } = details[name];
        return {
          ...accumulator,
          [name]: { ...rest },
        };
      }

      return {
        ...accumulator,
        [name]: { ...accumulator[name], ...details[name] },
      };
    }, localStorageDetails);

    // removing dummy files, because we can't reliably restore them
    if (mergedDetails?.styles?.file?.file) {
      delete mergedDetails.styles.file;
    }

    if (Array.isArray(mergedDetails?.uploadFiles?.files)) {
      mergedDetails.uploadFiles.files = mergedDetails.uploadFiles.files.filter(
        // @ts-ignore
        (file) => !file.file,
      );
    }

    if (Array.isArray(mergedDetails?.brief?.referenceFiles)) {
      mergedDetails.brief.referenceFiles = mergedDetails.brief.referenceFiles.filter(
        // @ts-ignore
        (file) => !file.file,
      );
    }
    // end remove

    const orderData = {
      name: formName,
      timestamp: getTimeInMinutes(),
      details: mergedDetails,
    };

    const serializedState = JSON.stringify(orderData);
    localStorage.setItem('orderData', serializedState);
  } catch (error) {
    console.error(error);
  }
};

// @ts-ignore
export const getComponentDetailsForSubmit = (details) =>
  Object.keys(details).reduce((data, key) => {
    const value = details[key];

    if (typeof value === 'object' && !isEmpty(value)) {
      return { ...data, [key]: details[key] };
    }

    return data;
  }, {});

// @ts-ignore
export const toFixed2Digits = (price) => Math.ceil(price * 100) / 100;

// @ts-ignore
export const calculateCouponPrice = (price, coupon) => {
  const { discount, discountType, minOrderAmount } = coupon;

  if (!discount || price < minOrderAmount) {
    return price;
  }

  if (discountType === 'natural') {
    return price - discount;
  }

  if (discountType === 'percent') {
    return price - price * (discount / 100);
  }

  return price;
};

export const calculateOrderPrice = ({
  // @ts-ignore
  price,
  // @ts-ignore
  slides,
  // @ts-ignore
  addon,
  // @ts-ignore
  coupon,
}): 'On enquiry' | 'TBD' | { min: number; max: number } | number => {
  if (price === null) {
    return 'On enquiry';
  }

  if (price === 'TBD') {
    return price;
  }

  if (typeof price === 'object' && 'min' in price && price.min && 'max' in price && price.max) {
    // The reason we do not want to apply coupon discounts here is
    // because they won't work with price ranges, only with integers
    const minCost = price.min * slides + Number(slides * (addon || 0));
    const maxCost = price.max * slides + Number(slides * (addon || 0));

    if (minCost === 0 && maxCost === 0) {
      return 0;
    }

    return { min: minCost, max: maxCost };
  }

  // Price formula: (slides * treatmentWithTurnaroundPrice) + (addon price * slides)
  // Price formula: (treatment price * turnaround ratio * slides) + (addon price * slides)
  let amount = 0;

  if (price) {
    amount = price;
  }

  amount *= slides;

  if (addon) {
    amount += addon * slides;
  }

  if (coupon.discount) {
    const couponPrice = calculateCouponPrice(amount, coupon);
    return typeof couponPrice === 'number' ? toFixed2Digits(couponPrice) : couponPrice;
  }

  return toFixed2Digits(amount);
};

// Function allows get value by key like "deliveryDates.turnaround" or "lol.kek.cheburek"
// @ts-ignore
export const getPropertyValue = (object, dataToRetrieve) =>
  // @ts-ignore
  dataToRetrieve.split('.').reduce((details, key) => details && details[key], object);

export const compareUpsellWhen =
  // @ts-ignore


    (details) =>
    // @ts-ignore
    ([key, whenValue]) => {
      const detailsValue = getPropertyValue(details, key);

      // Because `when.coupon.coupon` can be `null`,
      // but `details.coupon.coupon` can be `undefined`
      if (whenValue === null) {
        return !detailsValue;
      }

      if (Array.isArray(whenValue)) {
        return whenValue.includes(detailsValue);
      }

      return detailsValue === whenValue;
    };

export const scrollToFormHandlerError = () => {
  const invalidElement = document.querySelector('.handler-error');
  const bar = document.querySelector('.js-topbar');

  if (invalidElement && bar) {
    // @ts-ignore
    const TOPBAR_OFFSET = bar.offsetHeight;
    // @ts-ignore
    const top = invalidElement.offsetTop - TOPBAR_OFFSET;
    window.scrollTo({ top, behavior: 'smooth' });
  }
};

// @ts-ignore
export const getComponentFieldDefaultValues = (components) =>
  // @ts-ignore
  components.reduce((accumulator, { fields }) => {
    if (!fields) {
      return accumulator;
    }

    // @ts-ignore
    const collection = fields.reduce((fieldsCollection, { name, value }) => {
      if (value === null) {
        return fieldsCollection;
      }

      return {
        ...fieldsCollection,
        [name]: value,
      };
    }, {});

    return {
      ...accumulator,
      ...collection,
    };
  }, {});

// @ts-ignore
export const skipDefaultValues = (details, fields) =>
  Object.keys(details).reduce((fieldDetails, fieldName) => {
    if (details[fieldName] === fields[fieldName]) {
      const { [fieldName]: skipField, ...rest } = fieldDetails;
      return rest;
    }

    return fieldDetails;
  }, details);

// @ts-ignore
export const contentWithLineBreaker = (content) => {
  if (Array.isArray(content)) {
    return content;
  }

  if (typeof content === 'string') {
    return content.split('\\n\\n');
  }

  return [];
};

export const customFormParamsWhitelist = () => {
  const whitelist = ['token'];
  const searchParams = new URLSearchParams(window.location.search);

  return whitelist.reduce((params, paramName) => {
    const paramValue = searchParams.get(paramName);

    if (paramValue) {
      return {
        ...params,
        [paramName]: paramValue,
      };
    }

    return params;
  }, {});
};

// @ts-ignore
export const scrollToLastComponent = (elementId) => {
  const element = document.getElementById(elementId);

  if (element) {
    /**
     * Added setTimeout to wait for mobile rendering
     */
    setTimeout(() => {
      element.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }, 100);
  }
};
