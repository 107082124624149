import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useIdParam } from '~/common/hooks';
import { nonNullable } from '~/common/utils';
import { JoinedOrder, useFullOrderData } from './useOrder';

export const useSlideReadStore = create(
  persist(
    () => ({
      slideIds: [] as number[],
      annotationsIdHash: {} as Record<number, string>,
    }),
    { name: '_slide-read' },
  ),
);

export const useSlideRead = () => {
  const store = useSlideReadStore();
  const order = useFullOrderData();
  const client = useQueryClient();
  const id = useIdParam();

  const readSlide = useCallback((id: number) => {
    useSlideReadStore.setState((prev) => {
      if (prev.slideIds.includes(id)) {
        return prev;
      }
      return { ...prev, slideIds: [...prev.slideIds, id] };
    });
  }, []);

  const readAnnotation = useCallback(
    (slideId: number, annotationId: number) => {
      const order = nonNullable(client.getQueryData<JoinedOrder>(['orders', id]));
      const slide = nonNullable(order.slideAnnotations.find((slide) => slide.id === slideId));
      const annotation = slide.annotations.find((annotation) => annotation.id === annotationId);
      if (annotation) {
        useSlideReadStore.setState((prev) => ({
          ...prev,
          annotationsIdHash: {
            ...prev.annotationsIdHash,
            [annotation.id]: annotation.hash,
          },
        }));
      }
    },
    [client, id],
  );

  const isAnnotationRead = useCallback(
    (slideId: number, annotationId: number) => {
      const slide = nonNullable(order.slideAnnotations.find((slide) => slide.id === slideId));
      const annotation = slide.annotations.find((annotation) => annotation.id === annotationId);
      return annotation ? annotation.hash === store.annotationsIdHash[annotation.id] : true;
    },
    [order.slideAnnotations, store.annotationsIdHash],
  );

  const areAnnotationsRead = useCallback(
    (id: number) => {
      const slide = nonNullable(order.slideAnnotations.find((slide) => slide.id === id));
      return slide.annotations.every(
        (annotation) => annotation.hash === store.annotationsIdHash[annotation.id],
      );
    },
    [order.slideAnnotations, store.annotationsIdHash],
  );

  const isSlideRead = useCallback(
    (id: number) => {
      const slide = nonNullable(order.slideAnnotations.find((slide) => slide.id === id));
      return (
        store.slideIds.includes(slide.id) &&
        slide.annotations.every(
          (annotation) => annotation.hash === store.annotationsIdHash[annotation.id],
        )
      );
    },
    [order.slideAnnotations, store.annotationsIdHash, store.slideIds],
  );

  return { readAnnotation, readSlide, isAnnotationRead, areAnnotationsRead, isSlideRead };
};
