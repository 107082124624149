import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { IconBox } from '~/common/components';
import { cx, formatMoney } from '~/common/utils';
import { useDiscountCode, withDiscountCode } from '~/root/discount';
import { ProductType } from '~/root/domain';
import { useBillingData } from './hooks';

interface PurchaseSummaryProduct {
  name: string;
  type: ProductType;
  bonus?: number;
  price: ReactNode;
  advantages?: string[];
}

interface SummaryRow {
  title: string;
  hint?: string;
  value: string;
}

export interface PurchaseSummaryProps {
  product: PurchaseSummaryProduct;
  summary: SummaryRow[];
  /** Purchased product price */
  amount: number;
  additionalInfo?: string;
  summaryChildren?: ReactNode;
  children?: ReactNode;
}

export const PurchaseSummary = withDiscountCode(
  ({ product, summary, amount, additionalInfo }: PurchaseSummaryProps) => {
    return (
      <div className="flex flex-col lg:flex-row justify-between p-3 md:p-4 bg-white rounded-md">
        <ProductInfo {...product} />
        <Summary summary={summary} amount={amount} additionalInfo={additionalInfo} />
      </div>
    );
  },
);

interface ProductInfoProps extends PurchaseSummaryProduct {
  children?: ReactNode;
}

export const ProductInfo = ({ name, bonus, price, advantages, children }: ProductInfoProps) => {
  return (
    <div className="flex grow-0 shrink-0 flex-col mdo:flex-row gap-x-10 gap-y-3 md:gap-y-4 pb-4 lg:pb-0 lg:pr-4">
      <div className="inline-flex flex-col gap-y-2">
        <div className="flex gap-x-2 items-center">
          <span className="font-brand-h4 text-secondary-400 uppercase">{name}</span>
          {!!bonus && (
            <span className="font-brand-c3 px-1 py-[1px] rounded-[3px] text-text-100 bg-primary-300">
              +{bonus}% extra credits
            </span>
          )}
        </div>
        <span className="font-brand-h4 whitespace-nowrap">{price}</span>
      </div>
      <div className="flex flex-col gap-y-3 lg:gap-y-4">
        <ProductAdvantages advantages={advantages} />
        {children}
      </div>
    </div>
  );
};

const ProductAdvantages = ({ advantages }: Pick<PurchaseSummaryProduct, 'advantages'>) => {
  if (!advantages?.length) {
    return null;
  }

  return (
    <ul className="font-brand-b3 space-y-[12px]">
      {advantages?.map((advantage) => (
        <li key={advantage} className="flex gap-[6px] items-center">
          <IconBox icon={faCheck} className="text-secondary-300" size="m" />
          {advantage}
        </li>
      ))}
    </ul>
  );
};

export const Summary = ({
  summary,
  amount,
  additionalInfo,
  summaryChildren,
  children,
}: Omit<PurchaseSummaryProps, 'product'>) => {
  const { discount } = useDiscountCode();
  const { vatPercent } = useBillingData();

  const vatAmount = (amount * (vatPercent || 0)) / 100;
  const discountAmount = discount ? ((amount + vatAmount) * discount) / 100 : 0;
  const total = amount + vatAmount - discountAmount;

  return (
    <div
      className="w-full space-y-3 
    border-t border-solid border-other-300 lg:border-t-0 lg:border-l
    pt-3 md: md:pt-4 lg:pt-0 lg:pl-4"
    >
      <h6 className="font-brand-h6">Summary</h6>

      <div className="space-y-2">
        {summary.map((summaryRow) => (
          <SummaryRow key={summaryRow.title} {...summaryRow} />
        ))}

        {summaryChildren}
        <hr className="text-other-300 h-[1px]" />
      </div>

      {children}

      <Total total={total} additionalInfo={additionalInfo} />
    </div>
  );
};

interface SummaryRowProps extends SummaryRow {
  className?: string;
}

export const SummaryRow = ({ title, hint, value, className }: SummaryRowProps) => {
  return (
    <div
      className={cx('w-full flex flex-col md:flex-row gap-y-1 gap-x-2 font-brand-b3', className)}
    >
      <div className="w-full flex text-text-300">
        {title}
        {hint && (
          <IconBox
            className="text-text-200 ml-[4px] pointer-events-auto"
            icon={faQuestionCircle}
            data-tt={hint}
            data-tt-placement="right"
            data-tt-class="max-w-[230px] px-[12px] py-1"
          />
        )}
      </div>
      <span className="whitespace-nowrap">{value}</span>
    </div>
  );
};

const Total = ({
  total,
  additionalInfo,
}: { total: number } & Pick<PurchaseSummaryProps, 'additionalInfo'>) => {
  return (
    <div className="flex flex-col gap-y-1">
      <div className="flex gap-1 font-brand-h4 text-secondary-400">
        <p className="w-full">Total</p>
        <span className="whitespace-nowrap">{formatMoney(total)}</span>
      </div>
      {additionalInfo && <span className="font-brand-b3 text-text-400">{additionalInfo}</span>}
    </div>
  );
};
