import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useMemo } from 'react';
import { useDevice } from '~/common/kits/device';
import { GenericItem, TableView } from '~/common/kits/table';
import { cx } from '~/common/utils';
import { Select } from '../Select';
import { PaginationItem } from './PaginationItem';
import { getPages, PageItem } from './utils';

interface PaginationProps
  extends Pick<TableView<GenericItem>, 'page' | 'totalPages' | 'total' | 'results' | 'limit'> {
  className?: string;
  limitTitle?: string;
  limitOptions?: number[];
  onPageClick?: (page: number) => void;
  onLimitChange?: (limit: number) => void;
}

export const Pagination = ({
  page,
  totalPages,
  total,
  results,
  limit,
  limitTitle = 'Show per page',
  limitOptions,
  className,
  onPageClick,
  onLimitChange,
}: PaginationProps) => {
  const device = useDevice();
  const firstPage = page === 1;
  const lastPage = page === totalPages;
  const clickNext = () => onPageClick?.(page - 1);
  const clickPrevious = () => onPageClick?.(page + 1);

  const makePage = ({ key, value }: PageItem) => {
    const active = value === page;
    const disabled = typeof value !== 'number';
    const clickPage = () => onPageClick?.(+value);

    return (
      <PaginationItem
        key={key}
        active={active}
        disabled={disabled}
        onClick={clickPage}
        className={disabled ? 'px-0 min-w-0' : undefined}
      >
        {value}
      </PaginationItem>
    );
  };

  const Pages = getPages(page, totalPages).map(makePage);
  const props = { page, limit, total, results, limitTitle, limitOptions, onLimitChange };

  return (
    <div
      className={cx(
        'flex flex-col md:flex-row items-center justify-between px-2 gap-x-1 gap-y-3',
        className,
      )}
    >
      {device === 'MOBILE' ? (
        <div className="flex justify-between items-center flex-wrap gap-y-1 w-full">
          <ResultsAmount {...props} />
        </div>
      ) : (
        <ResultsAmount {...props} />
      )}
      <ul className="flex justify-center space-x-[4px]">
        <PaginationItem disabled={firstPage} onClick={clickNext} icon={faChevronLeft} />
        {Pages}
        <PaginationItem disabled={lastPage} onClick={clickPrevious} icon={faChevronRight} />
      </ul>
    </div>
  );
};

const ResultsAmount = ({
  page,
  limit,
  total,
  results,
  limitTitle,
  limitOptions,
  onLimitChange,
}: Pick<
  PaginationProps,
  'page' | 'total' | 'limitOptions' | 'limitTitle' | 'limit' | 'results' | 'onLimitChange'
>) => {
  const showedResults = useMemo(() => {
    if (!(limit && results)) {
      return `${page} page results`;
    }

    const firstShowedItemNumber = limit * (page - 1) + 1;

    if (results === 1) {
      return `${firstShowedItemNumber} of ${total}`;
    }

    return `${firstShowedItemNumber}-${limit * (page - 1) + results} of ${total}`;
  }, [limit, page, results, total]);

  const options = useMemo(
    () =>
      limitOptions
        ? limitOptions.map((l) => ({ name: String(l), value: l }))
        : [{ name: String(limit), value: limit }],
    [limit, limitOptions],
  );

  return (
    <>
      {limit && (
        <div className="flex items-center gap-[12px]">
          <span className="font-brand-b3">{limitTitle}</span>
          <Select
            options={options}
            value={limit}
            onChange={(value: number) => onLimitChange?.(value)}
            className="w-9"
            placement="top"
            size="m"
            disabled={options.length === 1}
          />
        </div>
      )}
      <span className="text-text-300 font-brand-b3">{showedResults}</span>
    </>
  );
};
