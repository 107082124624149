import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axios } from '~/root';
import { qk } from '~/root/query-keys';
import { CustomField, CustomFieldsList, customFieldsSchema } from './domain';

export const useCustomFields = () => {
  return useQuery({
    queryKey: qk.customFields,
    queryFn: () => {
      return axios
        .get('/v1/customers/custom-fields')
        .then((res) => customFieldsSchema.parse(res.data));
    },
  });
};

export const useCreateCustomField = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, ...data }: CustomField) => {
      return axios
        .post('/v1/customers/custom-fields', data)
        .then((res) => customFieldsSchema.parse(res.data));
    },
    onSuccess: (data) => {
      queryClient.setQueryData<CustomFieldsList>(qk.customFields, data);
      queryClient.invalidateQueries(qk.ordersAll);
    },
  });
};

export const useUpdateCustomField = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, ...data }: CustomField) => {
      return axios
        .put(`/v1/customers/custom-fields/${id}`, data)
        .then((res) => customFieldsSchema.parse(res.data));
    },
    onSuccess: (data) => {
      queryClient.setQueryData<CustomFieldsList>(qk.customFields, data);
      queryClient.invalidateQueries(qk.ordersAll);
    },
  });
};

export const useDeleteCustomField = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => {
      return axios
        .delete(`/v1/customers/custom-fields/${id}`)
        .then((res) => customFieldsSchema.parse(res.data));
    },
    onSuccess: (data) => {
      queryClient.setQueryData<CustomFieldsList>(qk.customFields, data);
      queryClient.invalidateQueries(qk.ordersAll);
    },
  });
};
