import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createQueryState } from '~/common/hooks';
import { assertQueryData } from '~/common/kits/query';
import { axios } from '~/root';
import { useSetUser } from '~/root/Auth';
import { qk } from '~/root/query-keys';
import {
  UpdateBillingPayload,
  billingSchema,
  paymentsHistoryParamSchema,
  paymentsHistorySchema,
} from '../domain';

export const useBilling = () => {
  const setUser = useSetUser();

  return useQuery({
    queryKey: qk.billing,
    queryFn: () =>
      axios.get('/v1/customers/billing').then((res) => {
        const billing = billingSchema.parse(res.data.data);

        // TODO: Not sure why it's there, possibly can be removed but needs testing
        setUser({ balance: billing.creditBalance });

        return billing;
      }),
    staleTime: Infinity,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useBillingData = assertQueryData(useBilling);

export const useUpdateBilling = () => {
  const queryClient = useQueryClient();
  const setUser = useSetUser();

  return useMutation({
    mutationFn: (data: UpdateBillingPayload) => {
      return axios
        .post('/v1/customers/billing', data)
        .then((res) => billingSchema.parse(res.data.data));
    },
    onSuccess: (billing) => {
      setUser({ balance: billing.creditBalance });
      queryClient.setQueryData(qk.billing, billing);
    },
  });
};

export const usePaymentsHistoryQueryState = createQueryState(paymentsHistoryParamSchema);

export const usePaymentsHistory = () => {
  const [params] = usePaymentsHistoryQueryState();

  params.limit = 8;

  return useQuery({
    queryKey: [...qk.billingPayments, params],
    queryFn: () =>
      axios
        .get(`/v1/customers/billing/payments`, { params })
        .then((res) => paymentsHistorySchema.parse(res.data)),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
