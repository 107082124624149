import dayjs from 'dayjs';
import { Link } from '~/common/components';
import { routes } from '~/constants';
import { banners } from '~/images/OnlineSubscriptionsPopup';
import './could-save.scss';

interface CouldSavePopupProps {
  amount?: string;
  discount?: string;
  slides?: number;
  onClose: () => void;
}

export const CouldSavePopup = ({ amount, slides = 0, onClose }: CouldSavePopupProps) => {
  const popupContent = couldSavePopupVariants[0];

  const handleDontShowClick = () => {
    /** Show popup in one month */
    const nextMonth = dayjs().add(20, 'month');
    localStorage.setItem('couldSavePopup', JSON.stringify(nextMonth));
    onClose();
  };

  return (
    <div className="could-save__container cs__container">
      <div className="cs__content">
        <h2 className="cs__title gradient">
          We can train a
          <em>
            &nbsp;dedicated design <br /> team&nbsp;
          </em>
          in your branding
        </h2>
        <h3 className="cs__subtitle">
          Sign up for a monthly subscription and you’ll get your own team of designers
        </h3>

        {'benefits' in popupContent && (
          <div className="cs__benefits">
            <ul>
              {popupContent.benefits.map(({ image, description }) => {
                const descriptionLine =
                  typeof description === 'string' ? description : description(slides);

                return (
                  <li key={descriptionLine}>
                    <img src={image} alt="benefit" />
                    <p>{descriptionLine}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {'price' in popupContent && popupContent.price && amount && (
          <p className="cs__proposal">
            Just <span>{amount}</span> per month
          </p>
        )}

        <p>
          <Link className="cs__button bold dif" size="m" href={routes.public.dedicated_team} newTab>
            Learn more
          </Link>
        </p>

        <button
          type="button"
          className="cs__dont-show bold uppercase"
          onClick={handleDontShowClick}
        >
          Don’t show me this again
        </button>
      </div>
    </div>
  );
};

const couldSavePopupBase = {
  benefits: [
    {
      image: banners.banner1,
      description: 'Get consistent, top quality design on every project',
    },
    {
      image: banners.banner2,
      description: (slidesAmount: number) =>
        `Your subscription includes approx ${slidesAmount} slides per month`,
    },
    {
      image: banners.banner3,
      description: 'Work with the same Project Manager every time',
    },
  ],
};

export const couldSavePopupVariants = [
  { ...couldSavePopupBase, price: true },
  { ...couldSavePopupBase },
  {
    ...couldSavePopupBase,
    benefits: [
      {
        image: banners.banner1,
        description: 'Get consistent, top quality design on every project',
      },
      {
        image: banners.banner2,
        description: 'Save up to 32% on the cost of your slides',
      },
      {
        image: banners.banner3,
        description: 'Work with the same Project Manager every time',
      },
    ],
  },
];
