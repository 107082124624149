import { faSquare } from '@fortawesome/pro-light-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { FieldProps } from 'formoid';
import { NonEmptyArray } from 'formoid/lib/Array';
import { ComponentPropsWithoutRef, ReactNode, forwardRef } from 'react';
import { Errors, IconBox } from '~/common/components';
import { Overwrite, cx } from '~/common/utils';
import css from './Checkbox.module.scss';

// TODO we need to add tooltip icons here

type Props = Overwrite<
  ComponentPropsWithoutRef<'input'>,
  {
    title?: ReactNode;
    hint?: string | null;
    value: boolean;
    onChange?: (value: boolean) => void;
    error?: boolean;
    errors?: NonEmptyArray<string> | null;
  }
>;

export const Checkbox = forwardRef<HTMLLabelElement, Props>(
  ({ className, title, hint, value: checked, onChange, error, errors, ...props }, ref) => (
    <label
      ref={ref}
      className={cx(css.wrapper, className, checked && css.checked, props.disabled && css.disabled)}
    >
      <div className="flex items-center gap-1">
        <input
          {...props}
          className="absolute -z-1 opacity-0 cursor-pointer"
          type="checkbox"
          checked={checked}
          onChange={() => onChange?.(!checked)}
        />

        <IconBox icon={checked ? faCheckSquare : faSquare} className={css.checkmark} />
        {title && <span className={css.text}>{title}</span>}
        {hint && (
          <IconBox
            className={css.titleIcon}
            icon={faQuestionCircle}
            data-tt={hint}
            data-tt-placement="right"
            data-tt-class={css.hintTooltip}
          />
        )}
      </div>
      {errors && <Errors errors={errors} className="ml-5 text-left" />}
    </label>
  ),
);

export const CheckboxField = forwardRef<HTMLLabelElement, Props & FieldProps<boolean>>(
  ({ touched, ...props }, ref) => <Checkbox ref={ref} {...props} />,
);
