import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { useForm, validator } from 'formoid';
import { useEffect, useMemo, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { CheckboxField, Button, Link, Select, Toast, useModalContext } from '~/common/components';
import { useIdParam } from '~/common/hooks';
import {
  createPrefetchWrapper,
  formatMoney,
  handleErrorMessage,
  isNonNullable,
} from '~/common/utils';
import { routes } from '~/constants';
import { useInitData } from '~/root/Auth';
import { Discount, useDiscountCode, withDiscountCode } from '~/root/discount';
import { useIsGenmabUnicorn } from '~/root/unicorns/genmab';
import { BillingInformationBlock } from '../BillingInformation';
import { BookMeetingModal } from '../BookMeetingModal';
import { SubscriptionPlanPeriod, SubscriptionPlanView } from '../domain';
import {
  useBillingData,
  useBuySubscriptionPlan,
  useSubscriptionPlans,
  useSubscriptionPlansData,
} from '../hooks';
import { PaymentMethodsBlock } from '../PaymentMethodsBlock';
import { ProductInfo, PurchaseSummaryProps, Summary, SummaryRow } from '../PurchaseSummary';
import { subscriptionPeriodToMonthAmount, subscriptionPeriodToName } from './constants';
import { useInitialPeriod } from './Subscription';

export const PurchaseSubscription = createPrefetchWrapper(useSubscriptionPlans)(
  withDiscountCode(() => {
    const history = useHistory();
    // TODO: Fix decode error handling, now it breaks the whole page
    const subscriptionPlanId = useIdParam();
    const subscriptionPlans = useSubscriptionPlansData();
    const { vatPercent, defaultPaymentMethod, billingAddress, company } = useBillingData();
    const { couponCode, removeCoupon } = useDiscountCode();

    const purchasePlanMutation = useBuySubscriptionPlan();

    const subscriptionPlan = useMemo(
      () => subscriptionPlans.find((subscription) => subscription.id === subscriptionPlanId),
      [subscriptionPlans, subscriptionPlanId],
    );

    const periodOptions = useRef(
      (subscriptionPlan?.periods
        ? (Object.keys(subscriptionPlan.periods) as (keyof SubscriptionPlanView['periods'])[])
        : ['monthly' as const]
      )
        .sort((p1, p2) => subscriptionPeriodToMonthAmount[p1] - subscriptionPeriodToMonthAmount[p2])
        .map((p) => ({ name: subscriptionPeriodToName[p], value: p })),
    );

    const [period, setPeriod] = useInitialPeriod(subscriptionPlan);

    const invoiceMethodAvailable = subscriptionPlan?.periods[period]?.methods.includes('invoice');

    const isGenmab = useIsGenmabUnicorn();

    const { fieldProps, setErrors, handleSubmit } = useForm({
      initialValues: {
        termsAndConditions: false,
        dataProcessingAgreement: false,
        paymentMethod: null,
        billingInfo: null,
      },
      validators: () => ({
        termsAndConditions: validator.fromPredicate<boolean>(
          (value) => value === true,
          'Please accept our terms and contitions to continue with purchase.',
        ),
        dataProcessingAgreement: validator.fromPredicate<boolean>(
          (value) => value === true,
          'Please accept our data processing agreement to continue with purchase.',
        ),
        paymentMethod: validator.sequence(
          validator.fromPredicate(
            () => Boolean(defaultPaymentMethod),
            'Please add payment method.',
          ),
          validator.fromPredicate(() => {
            return defaultPaymentMethod?.type === 'invoice'
              ? Boolean(invoiceMethodAvailable)
              : true;
          }, 'Please use credit card as your default payment method to finish the purchase.'),
          validator.validateIf(
            () => isGenmab,
            validator.fromPredicate(() => {
              return (
                defaultPaymentMethod?.type !== 'invoice' ||
                !!(
                  company?.vatNumber &&
                  defaultPaymentMethod?.poNumber &&
                  defaultPaymentMethod?.poExpiresAt
                )
              );
            }, 'Please correct your payment method.'),
          ),
        ),
        // TODO: consider extracting it to customValidator
        billingInfo: validator.fromPredicate(
          () =>
            Boolean(
              billingAddress.country &&
                (isGenmab
                  ? company?.name && company?.vatNumber
                  : billingAddress.city &&
                    billingAddress.street &&
                    billingAddress.phoneNumber &&
                    billingAddress.phoneCountryId),
            ),
          'Please fill in your billing info.',
        ),
      }),
      validationStrategy: 'onBlur',
    });

    useEffect(() => {
      setErrors('billingInfo', null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingAddress, company]);

    useEffect(() => {
      setErrors('paymentMethod', null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultPaymentMethod]);

    const onPeriodChange = (value: typeof period) => {
      if (subscriptionPlanId === 7 && value !== 'monthly') {
        Toast.warning({
          title: 'Coupon has been removed',
          message: 'Coupon is only available for monthly payment period',
        });
        removeCoupon();
      }
      setPeriod(value);
    };

    if (!subscriptionPlan) {
      return <Redirect to={routes.profile.billing.subscription} />;
    }

    const returnToSubscriptionPage = () => history.push(routes.profile.billing.subscription);

    const onSubmit = () =>
      handleSubmit(() =>
        purchasePlanMutation.mutateAsync(
          {
            coupon: couponCode || null,
            plan: subscriptionPlanId,
            period: subscriptionPeriodToMonthAmount[period],
            method: defaultPaymentMethod?.id || null,
          },
          {
            onSuccess: () => history.push(routes.profile.billing.thank_you),
            onError: handleErrorMessage,
          },
        ),
      );

    const planPrice = subscriptionPlan.amount * subscriptionPeriodToMonthAmount[period];
    const bonusCreditsPercentage = subscriptionPlan.periods[period]?.bonus || 0;
    const bonusCredits = Math.round((planPrice * bonusCreditsPercentage) / 100);
    const vatAmount = (planPrice * vatPercent) / 100;

    const summary = [
      { title: 'Subscription type', value: subscriptionPlan.name },
      { title: 'Price', value: formatMoney(planPrice) },
      subscriptionPlan.name !== 'Pro'
        ? {
            title: 'Bonus credits',
            value: formatMoney(bonusCredits),
          }
        : null,
      { title: 'VAT', hint: 'Your company country VAT', value: formatMoney(vatAmount) },
    ].filter(isNonNullable);

    return (
      <>
        <Link
          to={routes.profile.billing.subscription}
          icon={faChevronLeft}
          className="mb-3 sm:hidden"
        >
          Back to subscriptions
        </Link>
        <PurchaseSummary
          period={period}
          product={{
            name: subscriptionPlan.name,
            type: 'AccountSubscription',
            price: (
              <>
                {formatMoney(planPrice)}
                <span className="font-brand-b3 ml-[4px] lowercase">
                  {subscriptionPeriodToName[period]}
                </span>
              </>
            ),
            advantages: subscriptionPlan.pros,
            bonus: bonusCreditsPercentage,
          }}
          summary={summary}
          amount={planPrice}
          selfService={subscriptionPlan.selfService}
        />
        <BillingInformationBlock className="my-3" errors={fieldProps('billingInfo').errors} />
        <PaymentMethodsBlock
          title={null}
          errors={fieldProps('paymentMethod').errors}
          hideInvoice={!invoiceMethodAvailable}
        >
          {periodOptions.current.length > 1 && (
            <>
              <h4 className="font-brand-h5 mb-3">Payment period</h4>
              <Select
                value={period}
                size="m"
                onChange={onPeriodChange}
                className="mb-3 w-[295px] sm:w-full"
                options={periodOptions.current}
                icon={faCalendarAlt}
              />
            </>
          )}
          <h4 className="font-brand-h5 mb-3">Payment method</h4>
        </PaymentMethodsBlock>
        <CheckboxField
          {...fieldProps('termsAndConditions')}
          title={
            <>
              I agree to the{' '}
              <Link href={routes.public.documents.terms_and_conditions} newTab>
                Terms and Conditions
              </Link>
            </>
          }
          className="mt-4"
        />
        <CheckboxField
          {...fieldProps('dataProcessingAgreement')}
          title={
            <>
              I accept{' '}
              <Link href={routes.public.documents.data_processing_agreement} newTab>
                Data Processing agreement
              </Link>
            </>
          }
          className="mt-1"
        />
        <div className="w-full flex flex-row-reverse gap-x-2 py-2 mt-3">
          <Button
            size="m"
            className="w-[200px] sm:w-full grow-2"
            onClick={onSubmit}
            loading={purchasePlanMutation.isLoading}
          >
            Confirm
          </Button>
          <Button
            color="primary-outlined"
            size="m"
            className="hidden sm:flex grow-0"
            onClick={returnToSubscriptionPage}
          >
            Back
          </Button>
        </div>
      </>
    );
  }),
);

const PurchaseSummary = ({
  product,
  summary,
  amount,
  period,
}: PurchaseSummaryProps & { selfService: boolean; period: SubscriptionPlanPeriod }) => {
  const { enableProCoupons } = useInitData();
  const { discount } = useDiscountCode();
  const { modalOpener } = useModalContext();

  const discountCall = modalOpener(BookMeetingModal, {
    title: 'Apply coupon by reaching us',
    message:
      "Want to apply coupon for this subscription? Book a call with an account manager, and we'll set it up for you ASAP.",
    contactForm: 'subscriptions-manage-subscription',
  });

  const showCoupon = enableProCoupons && product.name === 'Pro' && period === 'monthly';
  const showCouponFallback = !enableProCoupons && product.name === 'Pro';

  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between p-3 md:p-4 bg-white rounded-md">
        <ProductInfo {...product} />
        <Summary
          summary={summary}
          amount={amount}
          summaryChildren={
            (showCoupon || showCouponFallback) && (
              <SummaryRow
                title="Coupon discount (for the first purchase)"
                value={`${discount || 0} %`}
                className="md:hidden"
              />
            )
          }
          additionalInfo={
            product.name === 'Pro'
              ? 'You can cancel your subscription at any time directly from the Billing overview page.'
              : 'To cancel your subscription, simply inform your account manager. The notice period is the current month plus one.'
          }
        >
          {showCoupon && <Discount period={period} product={product.type} className="sm:hidden" />}

          {showCouponFallback && (
            <Button className="sm:hidden" color="text" size="m" onClick={discountCall}>
              Apply coupon
            </Button>
          )}

          {(showCoupon || showCouponFallback) && (
            <SummaryRow
              title="Coupon discount (for the first purchase)"
              value={`${discount || 0} %`}
              className="sm:hidden"
            />
          )}
        </Summary>
      </div>

      {showCoupon && (
        <Discount
          product="AccountSubscription"
          className="md:hidden mt-3 bg-white"
          period={period}
        />
      )}

      {showCouponFallback && (
        <div className="md:hidden p-3 md:p-4 bg-white border border-solid border-other-300 rounded-md mt-3">
          <Button color="text" size="m" onClick={discountCall}>
            Apply coupon
          </Button>
        </div>
      )}
    </>
  );
};
