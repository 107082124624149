import cx from 'classnames';
import { colors } from '~/utils/palette';
type Props = {
  className?: string;
  color?: string;
};
const defaultProps = {
  className: '',
  color: colors.charcoal,
};

// TODO cleanup remove with legacy InnerOrderPage
const CloseIcon = ({ className = '', color }: Props) => (
  <svg
    aria-hidden="true"
    viewBox="0 0 320 512"
    className={cx('close-icon', {
      [`${className}__close-icon`]: !!className,
    })}
  >
    <path
      fill={color}
      d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
    />
  </svg>
);

CloseIcon.defaultProps = defaultProps;
export default CloseIcon;
