import cx from 'classnames';
import { ComponentProps, ImgHTMLAttributes, forwardRef, useLayoutEffect, useState } from 'react';

export type ColorSet = {
  backgroundColor: string;
  color: string;
};

export type AvatarProps = ComponentProps<'div'> & {
  colorSet: ColorSet;
  url?: string | null;
  imgProps?: ImgHTMLAttributes<HTMLImageElement>;
  size?: 's' | 'm' | 'l';
};

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ colorSet, url, className, children, imgProps, size = 'm', ...props }, ref) => {
    const [loaded, setLoaded] = useState(false);
    const style = loaded ? props.style : { ...colorSet, ...props.style };

    useLayoutEffect(() => setLoaded(false), [url]);

    return (
      <div
        ref={ref}
        {...props}
        style={style}
        className={cx(
          'flex flex-none items-center justify-center uppercase rounded-full overflow-hidden',
          size === 's' && 'h-3 w-3 font-brand-sm',
          size === 'm' && 'h-4 w-4 font-brand-b3',
          size === 'l' && 'h-5 w-5 font-brand-b3',
          className,
        )}
      >
        {url && (
          <img
            {...imgProps}
            src={url}
            className={cx(imgProps?.className, 'h-full w-full object-cover bg-greyscale-0', {
              hidden: !loaded,
            })}
            onLoad={() => setLoaded(true)}
          />
        )}
        {!loaded && children}
      </div>
    );
  },
);
