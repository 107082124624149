import MenuItem from '@material-ui/core/MenuItem';
import cx from 'classnames';
import dayjs from 'dayjs';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import Icon from '~/components/icons';
import { Modal } from '~/components/Modal';
import CheckIcon from '~/images/InnerOrder/check-green.svg';
import { OrdersSelect } from '~/pages/My-profile/Order/LegacyConfirmation/OrdersSelect';
import { clamp } from '~/utils/helpers';
import { colors } from '~/utils/palette';
import { Slide } from '../../../Order/domain';
import { useFullOrderData } from '../../../Order/hooks';
import { handleConfirmClickBind } from '../utils/common';

export type ArrowType = {
  type: 'prev' | 'next';
  size?: 'small' | 'medium';
  noHover?: boolean;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const Arrow = ({
  className,
  type,
  onClick,
  size = 'medium',
  noHover = false,
  disabled = false,
}: ArrowType) => (
  <button
    type="button"
    className={cx(
      'flex items-center justify-center absolute top-1/2 -translate-y-1/2 text-text-100 bg-[#00000080] rounded transition-brand',
      size === 'medium' ? 'h-6 w-6' : 'h-4 w-4',
      type === 'prev' ? 'rotate-90 left-0' : '-rotate-90 right-0',
      !noHover && 'hover:bg-[#ffffff12]',
      disabled && 'opacity-30',
      className,
    )}
    onClick={handleConfirmClickBind(onClick)}
  >
    <Icon icon="arrow-down" width={12} height={8} fill="#fff" />
  </button>
);

type VersionItemType = {
  id: number;
  version: number;
  timestamp: number;
  isActiveVersion: boolean;
};

type Props = {
  asideSlidesMarkup: ReactNode[];
  activeSlideIndex: number;
  setActiveSlideIndex: Dispatch<SetStateAction<number>>;
};

const PreviewSlider = ({ asideSlidesMarkup, activeSlideIndex, setActiveSlideIndex }: Props) => {
  const { slideList: slides } = useFullOrderData();
  const currentSlide = slides[activeSlideIndex];

  const [stateVersion, setStateVersion] = useState(currentSlide.version);
  const isLatestVersion = stateVersion === currentSlide.version;

  const getVersionTimestamp = () => {
    const currentVersionTime = currentSlide.timestamp;

    if (!currentVersionTime) {
      return null;
    }

    if (isLatestVersion) {
      return dayjs.unix(currentVersionTime).fromNow();
    }

    const version = currentSlide.versions.find(({ version }) => version === stateVersion);
    return dayjs.unix(version?.timestamp || currentVersionTime).fromNow();
  };

  useEffect(() => {
    /** Set latest version on slide change */
    setStateVersion(currentSlide.version);
  }, [currentSlide]);

  const slideMarkup = useMemo(() => {
    const { index, preview, version: currentVersion, versions } = currentSlide;
    const imageSource =
      (currentVersion !== stateVersion
        ? versions.find(({ version }) => version === stateVersion)?.preview
        : preview) ?? preview;
    return (
      <img
        className="rounded max-h-full max-w-full min-h-0"
        src={imageSource}
        alt={`Slide #${index} preview`}
      />
    );
  }, [currentSlide, stateVersion]);

  const getVersionText = (
    version: number, // Version text for select menu item
  ) => (currentSlide.version === version ? 'Latest version' : `Version ${version}`);

  const makeVersionItem = ({ version, timestamp, isActiveVersion }: VersionItemType) => (
    <MenuItem
      key={version}
      className="iopr__version f-wrap"
      classes={{
        selected: 'selected',
      }}
      value={version}
    >
      <div className="select-only">{getVersionText(version)}</div>
      <div className="f-between-center full-width menu-only">
        {getVersionText(version)}
        {isActiveVersion && <img src={CheckIcon} alt="Selected version" />}
      </div>
      <div className="iopr__menu-time menu-only">
        {dayjs(timestamp * 1000).format('DD MMM[, at] hh:mm a')}
      </div>
    </MenuItem>
  );

  const makeVersionPayload = (slide: Slide['versions'][number]): VersionItemType => {
    const isActiveVersion: boolean = stateVersion === slide.version;
    return { ...slide, isActiveVersion };
  };

  const renderSlideVersions = () => {
    const lastVersionPayload: VersionItemType = makeVersionPayload(currentSlide);
    const versionList = currentSlide.versions.map((slide) => {
      const menuItemPayload = makeVersionPayload(slide);
      return makeVersionItem(menuItemPayload);
    });
    return [makeVersionItem(lastVersionPayload), ...versionList];
  };

  // @ts-ignore
  const handleSelectChange = (event) => {
    const version = Number(event.target.value);

    if (stateVersion !== version) {
      setStateVersion(version);
    }
  };

  const slideVersions = renderSlideVersions();
  const versionTimestamp = getVersionTimestamp();
  return (
    <>
      <header className="flex items-center justify-between smo:flex-wrap">
        <div className="flex smo:mb-[12px] smo:w-full smo:justify-between">
          <span className="iopr__title">{`Slide #${currentSlide.index}`}</span>
          {!!versionTimestamp && (
            <span className="iopr__time flex items-center">
              <Icon icon="update-clock" fill={colors.graysuit} />
              <span>{` ${versionTimestamp}`}</span>
            </span>
          )}
        </div>
        <OrdersSelect
          className="iopr__versions textfield-compensation smo:w-full"
          value={stateVersion}
          classes={{
            select: 'iopr__select',
          }}
          MenuProps={{
            classes: {
              list: 'iopr__menu',
            },
          }}
          onChange={handleSelectChange}
        >
          {slideVersions}
        </OrdersSelect>
      </header>

      <div className="grow-[2] min-h-0 relative grid place-items-center">
        <Modal
          modal="commentModeModal"
          className="comment-mode"
          closeIcon={false}
          isLatestVersion={isLatestVersion}
          slideId={currentSlide.id}
          asideSlides={asideSlidesMarkup}
          slideVersions={slideVersions}
          stateVersion={stateVersion}
          versionTimestamp={versionTimestamp}
          onSelectChange={handleSelectChange}
          activeSlideIndex={activeSlideIndex}
          setActiveSlideIndex={setActiveSlideIndex}
        >
          <div className="cm__wrapper flex-center">
            <div className="cm__wrapper-content">
              <Icon icon="comment-alt-edit" fill="#fff" width={18} height={18} />
              &nbsp;open in comment mode
            </div>
          </div>
        </Modal>

        {slideMarkup}

        <Arrow
          className="z-10"
          noHover
          size="small"
          type="prev"
          disabled={activeSlideIndex === 0}
          onClick={() => setActiveSlideIndex((index) => clamp(0, index - 1, slides.length - 1))}
        />

        <Arrow
          className="z-10"
          noHover
          size="small"
          type="next"
          disabled={activeSlideIndex === slides.length - 1}
          onClick={() => setActiveSlideIndex((index) => clamp(0, index + 1, slides.length - 1))}
        />
      </div>
    </>
  );
};

export default PreviewSlider;
