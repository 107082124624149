import { Any } from '~/common/utils';
import { CommentFilesPopup } from '~/pages/My-profile/components/InnerOrderPage/components/CommentFilesPopup';
import { CommentModeModal } from '~/pages/My-profile/components/InnerOrderPage/components/CommentModeModal';
import { InnerOrderConfirmationPopup } from '~/pages/My-profile/Order/LegacyConfirmation';
import {
  CouldSavePopup,
  EnterpriseOfferModal,
  OnlineSubscriptionsPopup,
} from '~/pages/My-profile/Orders/Modals';
import { HolidayPopup } from '~/pages/Order-now/components/InitPopup/HolidayPopup';
import { UpsellCreditPackages } from '~/pages/Order-now/components/Upsell-credit';
import { UpsellPopup } from '~/pages/Order-now/components/Upsell-popup';

const modalMap = {
  // TODO cleanup remove after refactoring order form
  showTurnaroundUpsellPopup: UpsellPopup,
  showCreditPackUpsellPopup: UpsellCreditPackages,
  showHolidayPopup: HolidayPopup,
  // TODO cleanup remove with InnerOrderPage
  orderCommentFiles: CommentFilesPopup,
  commentModeModal: CommentModeModal,
  // TODO cleanup remove after redoing order confirmation popup
  innerOrderConfirmationPopup: InnerOrderConfirmationPopup,
  // TODO cleanup remove after refactoring to our new modals
  onlineSubscriptionsPopup: OnlineSubscriptionsPopup,
  couldSavePopup: CouldSavePopup,
  enterpriseOfferModal: EnterpriseOfferModal,
} as const;

export const ModalContent = ({
  modal,
  ...props
}: { modal: keyof typeof modalMap } & Record<string, unknown>) => {
  const ModalContentComponent = modalMap[modal] as Any;

  if (ModalContentComponent) {
    return <ModalContentComponent {...props} />;
  }

  // eslint-disable-next-line no-console
  console.warn(`There is no mapping for "${modal}" name`);

  return null;
};
