// @ts-ignore
import CountryFlags from 'country-flag-icons/react/3x2';
import { ComponentProps } from 'react';
import { Any, cx } from '~/common/utils';

type Props = { countryCode: string | undefined } & ComponentProps<'div'>;

export const CountryFlag = ({ countryCode, className, ...props }: Props) => {
  const Flag = CountryFlags[countryCode as Any as keyof typeof CountryFlags];
  return (
    <div
      {...props}
      className={cx('w-[27px] border border-solid border-other-400 flex-none', className)}
    >
      {Flag && <Flag />}
    </div>
  );
};
