import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { assertQueryData } from '~/common/kits/query';
import { axios } from '~/root';
import { qk } from '~/root/query-keys';

export const countryFilter = (search: string) => (option: { lookup: string }) =>
  option.lookup.includes(search);

const COUNTRIES_AT_THE_TOP = ['DK', 'UK', 'US'];

// TODO ideally we should end up using only country ids as values for phones
// and countries
//
// no annoying dial_code <> country_id & code <> country_id conversions
//
// but here we go :)
const countriesSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    code: z.string(),
    dialCode: z.number(),
    hasZip: z.boolean(),
  })
  .array()
  .transform((countries) => {
    countries = [
      ...countries.filter((code) => COUNTRIES_AT_THE_TOP.includes(code.code)),
      ...countries.filter((code) => !COUNTRIES_AT_THE_TOP.includes(code.code)),
    ];

    const countriesByCode = countries.map((country) => ({
      name: country.name,
      value: country.code,
      code: country.code,
      lookup: `+${country.dialCode} ${country.code} ${country.name}`.toLowerCase(),
      // compat with redux form
      label: country.name,
    }));

    const countriesById = countries.map((country) => ({
      name: country.name,
      value: country.id,
      code: country.code,
      lookup: `+${country.dialCode} ${country.code} ${country.name}`.toLowerCase(),
      // compat with redux form
      label: country.name,
    }));

    const countriesByDialCodeMap = countries.reduce(
      (countryList: Record<number, Country>, country) => {
        // TODO this is to leave more popular duplicates first, US vs CA :shrug:
        if (!countryList[country.dialCode]) {
          countryList[country.dialCode] = country;
        }
        return countryList;
      },
      {},
    );

    // TODO apparently when using dialCodes as values we get duplicates,
    // so we're going to use by dialCode map to have unique values
    const dialCodes = Object.values(countriesByDialCodeMap).map((country) => ({
      name: `+${country.dialCode}`,
      value: country.dialCode,
      code: country.code,
      lookup: `+${country.dialCode} ${country.code} ${country.name}`.toLowerCase(),
      // compat with redux form
      label: `(${country.dialCode}) ${country.name}`,
    }));

    const dialCodesById = countries.map((country) => ({
      name: `+${country.dialCode}`,
      value: country.id,
      code: country.code,
      dialCode: country.dialCode,
      lookup: `+${country.dialCode} ${country.code} ${country.name}`.toLowerCase(),
    }));

    const countriesByIdMap = countries.reduce((countryList: Record<number, Country>, country) => {
      countryList[country.id] = country;
      return countryList;
    }, {});

    const countriesByCodeMap = countries.reduce((countryList: Record<string, Country>, country) => {
      countryList[country.code] = country;
      return countryList;
    }, {});

    type Country = (typeof countries)[number];

    const getCountryById = (id: number) => countriesByIdMap[id];
    const getCountryByCode = (code: string) => countriesByCodeMap[code];
    const getCountryByDialCode = (dialCode: number) => countriesByDialCodeMap[dialCode];

    return {
      countriesByCode,
      countriesById,
      dialCodes,
      dialCodesById,
      countries,
      getCountryById,
      getCountryByCode,
      getCountryByDialCode,
    };
  });

export const useCountries = () => {
  return useQuery({
    queryKey: qk.countries,
    queryFn: () => axios.get('/v1/countries/names').then(({ data }) => countriesSchema.parse(data)),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useCountriesData = assertQueryData(useCountries);
