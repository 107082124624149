import { useEffect, useRef, RefObject } from 'react';
import { Any } from '../utils';
import { useEvent } from './useEvent';

export const useVisibleElements = <T extends HTMLElement = HTMLDivElement>(
  {
    containerRef: externalContainerRef,
    onVisibleChange,
    observerOptions,
  }: {
    containerRef?: RefObject<T>;
    onVisibleChange: (visibleElements: IntersectionObserverEntry[]) => void;
    observerOptions?: Omit<IntersectionObserverInit, 'root'>;
  },
  dependencies = [] as Any[],
) => {
  // I don't think we'll ever conditionally pass containerRef,
  // so muting this is fine
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const containerRef = externalContainerRef || useRef<T>(null);
  const entriesRef = useRef(new Map<Element, IntersectionObserverEntry>());

  const observerCallback = useEvent((entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => entriesRef.current.set(entry.target, entry));
    onVisibleChange([...entriesRef.current.values()].filter((entry) => entry.isIntersecting));
  });

  // TODO consider making actual ref.current a dependency, since we might hit a wall
  // in a case where initial render ref value would be null if we're not careful
  //
  // it was a case in order chat, had to redo react query state handling in ternaries
  // to avoid broken ref
  useEffect(() => {
    entriesRef.current.clear();

    const observer = new IntersectionObserver(observerCallback, {
      root: containerRef.current,
      ...observerOptions,
    });

    if (containerRef.current) {
      for (let i = 0; i < containerRef.current.children.length; i++) {
        observer.observe(containerRef.current.children[i]);
      }
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, observerCallback, observerOptions, ...dependencies]);

  return containerRef;
};
