import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { assertQueryData } from '~/common/kits/query';
import { axios } from '~/root';
import { useSetUser } from '~/root/Auth';
import { creditPackageSchema } from '~/root/domain';
import { qk } from '~/root/query-keys';
import {
  Billing,
  BillingEditTopUpPayload,
  PurchasePackagePayload,
  billingSchema,
  topUpSchema,
} from '../domain';

export const useCreditPackages = () => {
  return useQuery({
    queryKey: qk.billingCreditPackages,
    queryFn: () => {
      return axios
        .get('/v1/packages')
        .then(({ data: packages }) => z.array(creditPackageSchema).parse(packages));
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const useCreditPackagesData = assertQueryData(useCreditPackages);

export const useBillingEditTopUp = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: BillingEditTopUpPayload) => {
      return axios
        .post('/v1/customers/billing/top-ups', data)
        .then((res) => topUpSchema.parse(res.data.topUp));
    },
    onSuccess: (topUp) => {
      queryClient.setQueryData<Billing>(qk.billing, (prev) => prev && { ...prev, topUp });
    },
  });
};

export const useBillingRemoveTopUp = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => axios.delete('/v1/customers/billing/top-ups'),
    onSuccess: () => {
      queryClient.setQueryData<Billing>(qk.billing, (prev) => prev && { ...prev, topUp: null });
    },
  });
};

export const usePurchasePackage = () => {
  const queryClient = useQueryClient();
  const setUser = useSetUser();

  const billingTopUpEditMutation = useBillingEditTopUp();

  return useMutation({
    mutationFn: ({ packageId, ...data }: PurchasePackagePayload) => {
      return axios
        .post(`/v1/customers/charge/package/${packageId}`, data)
        .then((res) => {
          const billing = billingSchema.parse(res.data.data);
          queryClient.setQueryData<Billing>(qk.billing, (prev) => prev && { ...prev, ...billing });
          setUser({ balance: billing.creditBalance, purchasedPackages: true });
        })
        .then(() => {
          // TODO This is a bit dumb, can we handle this on BE?
          if (data.topUp && data.method !== null && data.topUpCreditsBelow !== null) {
            billingTopUpEditMutation.mutate({
              package: packageId,
              paymentMethod: data.method,
              creditsBelow: data.topUpCreditsBelow,
            });
          }
        });
    },
  });
};
