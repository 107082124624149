import { useState } from 'react';
import { createContextPair } from '~/common/kits/context';
import { StripeElementState, StripeElementsState } from './types';

const initialElementState: StripeElementState = {
  complete: false,
  error: undefined,
};

export const [useStripeElementsState, withStripeElementsState] = createContextPair(() => {
  const [elementsState, setElementsState] = useState<StripeElementsState>({
    number: initialElementState,
    expiry: initialElementState,
    cvv: initialElementState,
  });

  const updateElementsState = (partialUpdate: Partial<StripeElementsState>) => {
    setElementsState((prevElementsState) => ({ ...prevElementsState, ...partialUpdate }));
  };

  return { elementsState, setElementsState: updateElementsState };
})('StripeElementsState');
