export const mountCompareContainer = () => {
  const contentWidthContainer = document.createElement('div');
  contentWidthContainer.setAttribute('data-24slides-tooltip-compare-container', '');
  contentWidthContainer.style.width = 'fit-content';
  contentWidthContainer.style.position = 'absolute';
  contentWidthContainer.style.visibility = 'hidden';
  contentWidthContainer.style.pointerEvents = 'none';
  contentWidthContainer.style.zIndex = '-1';
  contentWidthContainer.style.top = '80%';
  contentWidthContainer.style.right = '0';
  document.body.appendChild(contentWidthContainer);
  return contentWidthContainer;
};

export const unmountCompareContainer = () => {
  const contentWidthContainer = document.querySelector('[data-24slides-tooltip-compare-container]');
  if (!contentWidthContainer)
    throw new Error('24slides Global tooltip compare container not found');
  document.body.removeChild(contentWidthContainer);
};

export const transferNecessaryStyles = (from: Element, to: HTMLDivElement) => {
  const css = getComputedStyle(from);
  to.style.fontSize = css.fontSize;
  to.style.fontWeight = css.fontWeight;
  to.style.fontFamily = css.fontFamily;
  to.style.lineHeight = css.lineHeight;
  to.style.letterSpacing = css.letterSpacing;
  to.style.textTransform = css.textTransform;
  to.style.maxWidth = '100% !important';
};
