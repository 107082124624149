import { FieldProps } from 'formoid';
import { CheckboxField, InputField, SelectField } from '~/common/components';
import { Overwrite } from '~/common/utils';
import { CustomField, CustomFieldValue } from './domain';

export const CustomFieldsRenderer = ({
  items,
  fieldProps,
}: {
  items: Overwrite<CustomField, { id: string }>[];
  fieldProps: (key: string) => FieldProps<CustomFieldValue>;
}) => {
  if (!items.length) {
    return null;
  }

  const checkboxes = items.filter((item) => item.type === 'bool');
  const inputs = items.filter((item) => item.type !== 'bool');

  return (
    <>
      {!!inputs.length && (
        <div className="smo:space-y-3 md:grid md:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] md:gap-2">
          {items.map((field) => {
            switch (field.type) {
              case 'text':
                return (
                  <InputField
                    {...(fieldProps(field.id) as FieldProps<string>)}
                    key={field.id}
                    title={field.title}
                    hint={field.description}
                    type="text"
                  />
                );
              case 'select_one':
                return (
                  <SelectField
                    {...(fieldProps(field.id) as FieldProps<string>)}
                    key={field.id}
                    title={field.title}
                    options={field.options.map((value) => ({ value, name: value }))}
                    hint={field.description}
                    nullable={!field.is_required}
                  />
                );
            }
          })}
        </div>
      )}
      {!!checkboxes.length && (
        <div className="space-y-2 md:space-y-1 mt-3 md:mt-1">
          {checkboxes.map((field) => (
            <CheckboxField
              {...(fieldProps(field.id) as FieldProps<boolean>)}
              key={field.id}
              title={field.title}
              hint={field.description}
            />
          ))}
        </div>
      )}
    </>
  );
};
