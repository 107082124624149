import { addDays } from 'date-fns';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { posthog } from '~/root/services';

export const useLegacyOrderPage = create(
  persist(() => ({ use: false, setAt: new Date() }), { name: '_legacy-order-page' }),
);

export const updateOrderPageDesignPreference = () => {
  const { use, setAt } = useLegacyOrderPage.getState();

  const design = use ? 'old' : 'new';

  posthog?.capture('order_page_redesign', { visited_order_with_design: design });

  // if there were no changes to the order design selection for 1 day,
  // let's make the user eligible for old/new design quizes from posthog
  if (addDays(new Date(), -1) > setAt) {
    posthog?.capture('order_page_redesign', {
      $set: {
        _event_prefers_order_page_design: design,
      },
    });
  }
};
