import { Any } from '~/common/utils';

export type EntitiesType = string | string[];

export type IterableEntityType = string;

// TODO remove with redux
const getEntityArray = (entities: EntitiesType) => {
  if (typeof entities === 'string') {
    return [entities];
  }

  if (Array.isArray(entities)) {
    return entities;
  }

  return [];
};

export const createLoadingSelector = (entities: EntitiesType) => (state: Any) =>
  // returns true only when all actions is not loading
  getEntityArray(entities).some((entity: IterableEntityType): boolean => state.api.loading[entity]);

export const createLastFetchDateSelector = (entities: EntitiesType) => (state: Any) => {
  const key = getEntityArray(entities).find(
    (entity: IterableEntityType): Date => state.api.lastFetch[entity],
  );
  // @ts-ignore
  return state.api.lastFetch[key];
};
