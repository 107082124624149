import ReactGA from 'react-ga4';
import { CustomerType } from '../domain';

const dimensions = {
  contract: 'retainer',
  returning: 'return',
  'custom portal': 'custom',
} as const;

export const ga = {
  /**
   * https://linear.app/24slides/issue/TFL-137/[ga-integration]-segment-users-with-custom-dimension
   */
  dimension: (dimension?: CustomerType) => {
    // @ts-ignore
    const dimensionValue = (dimension && dimensions[dimension]) ?? dimension;
    ReactGA.ga('set', 'dimension6', dimensionValue);
  },
};
