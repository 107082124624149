import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  StripeCardNumberElementOptions,
  StripeElementChangeEvent,
  loadStripe,
} from '@stripe/stripe-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Errors } from '~/common/components';
import { Component, Props, cx } from '~/common/utils';
import { useInitData } from '../Auth';
import { qk } from '../query-keys';
import { StripeElementState, StripeFormElement } from './types';
import { useStripeElementsState, withStripeElementsState } from './useStripeElementsState';

export const useStripeInit = () => {
  const { env } = useInitData();

  return useQuery({
    queryKey: qk.stripe,
    queryFn: () => loadStripe(env.stripeToken),
    staleTime: Infinity,
    enabled: Boolean(env.stripeToken),
  });
};

export const useCreateCardToken = () => {
  const stripe = useStripe();
  const elements = useElements();

  return useMutation({
    mutationFn: async () => {
      const cardElement = elements?.getElement(CardNumberElement);

      if (!stripe) {
        throw Error('No Stripe instance found!');
      }

      if (!cardElement) {
        throw Error('No card element found!');
      }

      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        throw new Error(error.message);
      }

      return token;
    },
  });
};

export const withStripeElements = <P,>(Component: Component<P>) => {
  return withStripeElementsState((props: Props<P>) => {
    const { data: stripe } = useStripeInit();

    return stripe ? (
      <Elements stripe={stripe}>
        <Component {...props} />
      </Elements>
    ) : null;
  });
};

const STRIPE_INPUT_STYLES: StripeCardNumberElementOptions['style'] = {
  base: {
    fontFamily: 'Inter, "Open sans", Arial',
    fontSize: 'inherit',

    '::placeholder': {
      color: '#929bad',
    },
  },
  invalid: {
    iconColor: '#e1485b',
  },
};

const STRIPE_INPUT_CLASSES: StripeCardNumberElementOptions['classes'] = {
  base: `
    border border-solid border-other-400 rounded
    px-1 py-[14px] h-6 
    text-text-500 font-brand-b1 leading-5 bg-other-50
  `,
  complete: '',
  empty: '',
  focus: '',
  invalid: `border-error-300 text-text-500`,
  webkitAutofill: 'bg-other-50',
};

/**
 * Use inside components, wrapped in with ```withStripeElements ```
 * ```tsx
 *  <CreditCardForm className="mb-1 pt-1" />
 * ```
 */
export const CreditCardForm = ({ className }: { className?: string }) => {
  const { elementsState, setElementsState } = useStripeElementsState();

  const handleChange = (fieldName: StripeFormElement) => (options: StripeElementChangeEvent) => {
    setElementsState({ [fieldName]: options });
  };

  return (
    <div className={cx('grid grid-cols-2 gap-2 md:gap-3', className)}>
      <div className="col-span-2 relative">
        <CardNumberElement
          className="w-full"
          options={{
            placeholder: 'Card number',
            style: STRIPE_INPUT_STYLES,
            classes: STRIPE_INPUT_CLASSES,
            showIcon: true,
          }}
          onChange={handleChange('number')}
        />
        <StripeElementError error={elementsState['number'].error} />
      </div>

      <div className="relative">
        <CardExpiryElement
          className="w-full"
          options={{
            placeholder: 'MM/YY',
            style: STRIPE_INPUT_STYLES,
            classes: STRIPE_INPUT_CLASSES,
          }}
          onChange={handleChange('expiry')}
        />
        <StripeElementError error={elementsState['expiry'].error} />
      </div>

      <div className="relative">
        <CardCvcElement
          className="w-full"
          options={{
            placeholder: 'CVV',
            style: STRIPE_INPUT_STYLES,
            classes: STRIPE_INPUT_CLASSES,
          }}
          onChange={handleChange('cvv')}
        />
        <StripeElementError error={elementsState['cvv'].error} />
      </div>
    </div>
  );
};

const StripeElementError = ({ error }: { error: StripeElementState['error'] }) => {
  const errors = error?.message ? ([error.message] as [string]) : null;

  if (!errors) {
    return null;
  }

  return <Errors errors={errors} className="absolute bottom-0 right-0 translate-y-full" />;
};
