import { useEffect } from 'react';
import { PORTAL_NAME } from '~/constants';
import { hotjar } from '~/root/services';

type PortalName = typeof PORTAL_NAME;

type Name = PortalName[keyof PortalName];

export const useLandingHeatmaps = (portal: Name): void => {
  useEffect(() => {
    if (portal) {
      hotjar('trigger', `custom_portal_landing_${portal}`);
    }
  }, [portal]);
};
