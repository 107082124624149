import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { ComponentProps, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { cx } from '~/common/utils';
import { IconBox } from './IconContainers';

// TODO think about better divs nesting here, having loaderClassName is not
// ideal, but is an escape hatch to place loader at the center and image at the
// start of the container vertically
//
// Ideally we should have one className and have that behavior somehow as well
type Props = ComponentProps<'img'> & { loaderClassName?: string };

export const ImageLoader = ({ loaderClassName, ...props }: Props) => {
  const [loaded, setLoaded] = useState(true);
  const shouldShowSpinner = useRef(true);

  const handleLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    props.onLoad?.(event);
    setLoaded(true);
    shouldShowSpinner.current = false;
  };

  useEffect(() => {
    setTimeout(() => {
      if (shouldShowSpinner.current) {
        setLoaded(false);
      }
    });
  }, []);

  return (
    <>
      {!loaded && (
        <div
          className={cx(
            'flex items-center justify-center before:block def:before:mt-[75%]',
            props.className || 'w-full',
            loaderClassName,
          )}
        >
          <IconBox
            size="l"
            icon={faSpinnerThird}
            className="text-greyscale-500 animate-spin h-fit w-fit"
          />
        </div>
      )}
      <img
        {...props}
        className={cx(props.className, {
          hidden: !loaded,
        })}
        onLoad={handleLoad}
      />
    </>
  );
};
