import TextField from '@material-ui/core/TextField';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Any } from '~/common/utils';
import { Spinner } from '~/components/loaders/Spinner';
import { Modal } from '~/components/Modal';
import Tooltip from '~/components/ui/Tooltip';
import { AttachIcon } from '~/images/InnerOrder';
import { colors } from '~/utils/palette';
import { CommentPayload } from '../../../Order/hooks/useEvents';
import { commentStorage } from '../utils';
import SendIcon from './SendIcon';

type Props = {
  isPosting: boolean;
  showAttachment?: boolean;
  commentLimit?: number;
  shouldStoreComment?: boolean;
  autoFocus?: boolean;
  postCommentAction: (payload: CommentPayload) => Promise<Any>;
};

const defaultProps = {
  showAttachment: true,
  commentLimit: 5000,
  shouldStoreComment: false,
  autoFocus: false,
};

const CommentInput = ({
  isPosting,
  showAttachment,
  commentLimit = 5000,
  // Because Flow.js defaultProps conflict
  shouldStoreComment,
  autoFocus,
  postCommentAction,
}: Props) => {
  const inputRef = useRef(null);
  const defaultComment = shouldStoreComment ? commentStorage.get() : '';
  const [comment, setComment] = useState<string>(defaultComment);

  const handleChange = useCallback(
    (event: React.SyntheticEvent<EventTarget>) => {
      // @ts-ignore
      const { value } = event.target;

      if (value.length <= commentLimit) {
        setComment(value);
      }
    },
    [commentLimit],
  );

  useEffect(
    () => () => {
      if (shouldStoreComment && inputRef.current) {
        // Condition because of Flow.js warning
        // @ts-ignore
        commentStorage.set(inputRef.current.value);
      }
    },
    [shouldStoreComment],
  );

  const handleCommentSend = useCallback(
    (uploadedFiles: Array<Any>) => {
      // todo: think on `files` improving
      // Checking is `uploadedFiles` not button syntethic event
      const files = Array.isArray(uploadedFiles) ? uploadedFiles : [];
      const payload = {
        comment,
        files: files.map(({ uuid }) => uuid),
      };
      return postCommentAction(payload)
        .then(() => {
          setComment('');
        })
        .catch(() => {});
    },
    [comment, postCommentAction],
  );

  const handleSend = () => {
    if (comment.trim()) {
      // Prevent action if `comment` is empty
      handleCommentSend([]);
    } else {
      // @ts-ignore
      inputRef.current.focus();
    }
  };

  const renderSendButton = () => {
    if (isPosting) {
      // Display loader on post a comment request
      return (
        <div className="ioc__send boundary-box">
          <Spinner size={18} />
        </div>
      );
    }

    return (
      <button type="button" className="boundary-box button-reset ioc__send" onClick={handleSend}>
        <SendIcon color={colors.lochmara} />
      </button>
    );
  };

  return (
    <div className="inner-order-comment ioc__container f-align-end f-shrink-0">
      {showAttachment && (
        <Modal
          modal="orderCommentFiles"
          className="inner-order-page-modal"
          comment={comment}
          isPosting={isPosting}
          onChange={handleChange}
          onSend={handleCommentSend}
        >
          <div className="boundary-box boundary-box-32 ioc__attach pointer relative">
            <img src={AttachIcon} alt="Leave a comment" />
            <Tooltip className="text-center">Attach files</Tooltip>
          </div>
        </Modal>
      )}
      <div className="relative full-width">
        <TextField
          multiline
          name="send"
          maxRows={10}
          placeholder="Leave a comment"
          className="textfield-compensation ioc__textfield"
          value={comment}
          autoFocus={autoFocus}
          inputRef={inputRef}
          onChange={handleChange}
        />
        {renderSendButton()}
      </div>
    </div>
  );
};

CommentInput.defaultProps = defaultProps;
export default CommentInput;
