import { ForwardedRef, ReactNode, createContext, forwardRef } from 'react';
import { useEnsuredContext } from '~/common/kits/context';
import { cx } from '~/common/utils';
import styles from './Tabs.module.scss';

interface TabsProps<T extends string> {
  activeTab?: T;
  children: ReactNode;
  className?: string;
  onChange?: (tabName: T) => void;
}

export const Tabs = <T extends string>({
  activeTab,
  children,
  className,
  onChange,
}: TabsProps<T>) => {
  return (
    <TabsProvider value={{ activeTab, onChange }}>
      <div className={cx('w-full flex border-b-2 border-solid border-other-300 mb-3', className)}>
        {children}
      </div>
    </TabsProvider>
  );
};

interface TabProps<T extends string> {
  name: T;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
}

export const Tab = forwardRef(
  <T extends string>(
    { name, disabled, children, className }: TabProps<T>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { activeTab, onChange } = useTabsContext();

    const isActive = activeTab === name;

    const handleClick = () => {
      !disabled && onChange?.(name);
    };

    return (
      <div
        onClick={handleClick}
        className={cx(
          'w-full flex h-5 justify-center items-center relative font-brand-l2',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          isActive ? 'text-primary-300' : 'text-text-300',
          {
            [styles.active]: isActive,
          },
          className,
        )}
        ref={ref}
      >
        {typeof children === 'string' ? <span>{children}</span> : children}
      </div>
    );
  },
);

type TabsContextValue<T extends string> = Pick<TabsProps<T>, 'activeTab' | 'onChange'>;

const createTabsContext = <T extends string>() => {
  return createContext<TabsContextValue<T> | null>(null);
};

const TabsContext = createTabsContext();

const TabsProvider = <T extends string>({
  children,
  value,
}: {
  children: ReactNode;
  value: TabsContextValue<T>;
}) => {
  return (
    <TabsContext.Provider value={value as unknown as TabsContextValue<string>}>
      {children}
    </TabsContext.Provider>
  );
};

export const useTabsContext = () => useEnsuredContext(TabsContext);
