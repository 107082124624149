import cx from 'classnames';
import {
  ComponentProps,
  PointerEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { nonNullable } from '~/common/utils';
import { clamp } from '~/utils/helpers';

const SCALE_INCREMENT = 0.05;
const MIN_SCALE = 1;
const MAX_SCALE = 2;

export const ZoomPanContainer = (props: ComponentProps<'div'>) => {
  const viewportRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const enabledRef = useRef(false);

  const [enabled, setEnabledState] = useState(false);
  const [panning, setPanning] = useState(false);

  const setEnabled = (stateOrCb: SetStateAction<boolean>) => {
    setEnabledState((prev) => {
      const next = stateOrCb instanceof Function ? stateOrCb(prev) : stateOrCb;
      enabledRef.current = next;
      return next;
    });
  };

  // TODO extract this stuff to a class or closure and clean it up
  // example api - { viewport, container, increment, minScale, maxScale, onDrag, onZoom }
  // it also should have zoom, zoomIn, zoomOut methods. pan can be added later
  // maybe it should also have beforeStart callback, which would be able to disable it
  const handlePan: PointerEventHandler<HTMLDivElement> = (event) => {
    // only left click
    if (event.button !== 0) {
      return;
    }

    const pos = { x: 0, y: 0 };
    const container = nonNullable(event.currentTarget);

    const handleMove = (e: PointerEvent) => {
      const diffX = e.clientX - pos.x;
      const diffY = e.clientY - pos.y;

      pos.x = e.clientX;
      pos.y = e.clientY;

      window.requestAnimationFrame(() => {
        container.scrollBy({ left: -diffX, top: -diffY });
      });
    };

    const handleUp = () => {
      setPanning(false);
      window.removeEventListener('pointermove', handleMove);
      window.removeEventListener('pointerup', handleUp);
    };

    pos.x = event.clientX;
    pos.y = event.clientY;

    setPanning(true);
    window.addEventListener('pointermove', handleMove);
    window.addEventListener('pointerup', handleUp);
  };

  useEffect(() => {
    // const content = nonNullable(containerRef.current);
    const switchHeld = (switchTo: boolean) => (event: KeyboardEvent) => {
      if (event.key === ' ') {
        setEnabled(switchTo);
        // content.style.setProperty('--pointer-events', switchTo ? 'none' : 'auto');
      }
    };

    const hold = switchHeld(true);
    const release = switchHeld(false);

    window.addEventListener('keydown', hold);
    window.addEventListener('keyup', release);

    return () => {
      window.removeEventListener('keydown', hold);
      window.removeEventListener('keyup', release);
    };
  }, []);

  useEffect(() => {
    const viewport = nonNullable(viewportRef.current);
    const content = nonNullable(containerRef.current);

    let scale = 1;

    // shameless ripoff of tragopan zoom logic
    const handleZoom = (event: WheelEvent) => {
      if (!enabledRef.current) {
        return;
      }

      // this is wheel event listener from tragopan
      event.preventDefault();
      event.stopPropagation();

      const zoomIn = event.deltaY < 0;
      const targetScale = scale * (1 + (zoomIn ? SCALE_INCREMENT : -SCALE_INCREMENT));

      let mouseX = event.offsetX;
      let mouseY = event.offsetY;
      let element = event.target as HTMLElement | null;
      while (element !== content && element) {
        mouseX += element.offsetLeft;
        mouseY += element.offsetTop;
        element = element.offsetParent as HTMLElement | null;
      }

      // the thing inside is zoom method from tragopan basically
      window.requestAnimationFrame(() => {
        const prevScale = scale;
        scale = clamp(MIN_SCALE, targetScale, MAX_SCALE);

        // TODO add tx/ty logic that keeps zooming to the same point when zoomed from toolbar
        const focalPoint = { x: mouseX, y: mouseY };

        // determine how far we have to shift to compensate for scaling to keep focus
        const dx = Math.round(focalPoint.x * prevScale - focalPoint.x * scale);
        const dy = Math.round(focalPoint.y * prevScale - focalPoint.y * scale);
        const scrollLeft = viewport.scrollLeft - dx;
        const scrollTop = viewport.scrollTop - dy;

        // pan before or after depending on whether we're zooming in or out
        scale < prevScale && viewport.scroll(scrollLeft, scrollTop);
        content.style.transform = `scale(${scale})`;
        content.style.setProperty('--scale-down', String(1 / scale));
        scale >= prevScale && viewport.scroll(scrollLeft, scrollTop);
        // TODO add tx/ty logic once we have a toolbar which zooms
      });
    };

    viewport.addEventListener('wheel', handleZoom, { passive: false });

    return () => {
      viewport.removeEventListener('wheel', handleZoom);
    };
  }, []);

  return (
    <div
      ref={viewportRef}
      className="min-h-0 w-full h-full overflow-auto"
      onPointerDown={enabled ? handlePan : undefined}
      data-zoom-pan
    >
      <div
        ref={containerRef}
        className="min-h-0 h-full rounded grid place-items-center origin-top-left"
      >
        <div
          {...props}
          onClick={enabled ? undefined : props.onClick}
          role="button"
          aria-label="Interactive image"
          className={cx(
            'flex flex-col items-center justify-start relative',
            panning ? 'cursor-grabbing' : enabled ? 'cursor-grab' : 'cursor-crosshair',
          )}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};
