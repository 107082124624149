import { Placement } from '@floating-ui/react';
import { ComponentProps, useLayoutEffect, useState } from 'react';
import { useDocumentVisibility } from '~/common/hooks';
import { Tooltip, TooltipColor } from '../Tooltip';
import {
  mountCompareContainer,
  transferNecessaryStyles,
  unmountCompareContainer,
} from './compareContainer';

// TODO
// Add an ability to open tooltip on keyboard navigation
// Note - I've tried setting tooltip props on focus, but had no luck
/**
 * Mount it anywhere globally to have a tooltip on any element that has data-tt attribute or truncate class
 * You can use data-tt-placement, data-tt-class, data-tt-color attributes on the element
 * like on normal tooltip
 */
const getCommonTooltipProps = (element?: Element) =>
  ({
    color: (element?.getAttribute('data-tt-color') as TooltipColor) || 'white',
    className: element?.getAttribute('data-tt-class') || '',
    content: element?.getAttribute('data-tt') || element?.textContent || undefined,
    placement: (element?.getAttribute('data-tt-placement') as Placement) || 'top',
    reference: element || null,
  }) satisfies ComponentProps<typeof Tooltip>;

export const GlobalTooltip = () => {
  const documentVisible = useDocumentVisibility();
  const [tooltipProps, setTooltipProps] = useState<ComponentProps<typeof Tooltip>>(
    getCommonTooltipProps(),
  );

  useLayoutEffect(() => {
    const compareContainer = mountCompareContainer();

    const tooltipsObserver = new MutationObserver(() => {
      setTooltipProps((props) => (props.reference?.isConnected ? props : getCommonTooltipProps()));
    });

    tooltipsObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    const enterEventHandler = ({ target }: Event) => {
      if (!(target instanceof Element)) return;
      const ttTarget = target?.closest('[data-tt]');

      if (ttTarget && ttTarget.getAttribute('data-tt')?.length) {
        return setTooltipProps(getCommonTooltipProps(ttTarget));
      }

      if (compareContainer.innerHTML) {
        compareContainer.innerHTML = '';
      }

      const truncateTarget = target?.closest('.truncate');

      if (truncateTarget) {
        compareContainer.innerText = truncateTarget.textContent || '';
        transferNecessaryStyles(truncateTarget, compareContainer);

        if (truncateTarget.clientWidth < compareContainer.clientWidth) {
          setTooltipProps(getCommonTooltipProps(truncateTarget));
        }
      }
    };

    document.addEventListener('pointerover', enterEventHandler, true);

    return () => {
      tooltipsObserver.disconnect();
      unmountCompareContainer();
      document.removeEventListener('pointerover', enterEventHandler);
    };
  }, []);

  useLayoutEffect(() => {
    if (!documentVisible) {
      setTooltipProps(getCommonTooltipProps());
    }
  }, [documentVisible]);

  return <Tooltip {...tooltipProps} />;
};
