import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { createTheme } from '@material-ui/core/styles';

const grey300 = grey['300'];
const cyan500 = cyan['500'];
const lightBlue600 = lightBlue['600'];

export const generalTheme = createTheme({
  palette: {
    // @ts-ignore
    borderColor: grey300,
  },
  slider: {
    handleFillColor: cyan500,
    handleColorZero: cyan500,
  },
  typography: {
    // @ts-ignore
    useNextVariants: true,
    fontSize: 16,
    htmlFontSize: 11,
    color: grey['700'],
    fontFamily: 'Open Sans, sans-serif',
  },
  overrides: {
    MuiButton: {
      // @ts-ignore
      fab: {
        boxShadow: 'none',
      },
    },
    MuiModal: {
      root: {
        zIndex: 17000,
        overflowY: 'auto',
      },
    },
    MuiInput: {
      root: {
        minHeight: '38px',
        '&$error': {
          '&:after': {
            borderBottomColor: '#ed715c!important',
          },
        },
        '&$disabled': {
          '&:before': {
            borderBottom: `1px solid ${grey['300']}!important`,
          },
        },
      },
      input: {
        fontSize: 16,
        lineHeight: 1.5,
        color: '#454545',
      },
      multiline: {
        padding: 0,
      },
      formControl: {
        marginTop: '12px!important',
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid '.concat(grey['300']),
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          height: 1,
          borderBottom: '1px solid '.concat(grey['500']),
        },
        '&:after': {
          borderBottom: '2px solid '.concat(cyan500),
        },
      },
    },
    MuiSwitch: {
      // @ts-ignore
      bar: {
        backgroundColor: '#46b688!important',
      },
      colorPrimary: {
        '&$checked': {
          color: '#46b688',
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 0) scale(0.75)',
      },
    },
    MuiInputBase: {
      input: {
        fontFamily: 'Open Sans, sans-serif',
        padding: 0,
        height: '100%',
      },
      // inputType: {
      //   height: '100%',
      // }
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        bottom: '-23px',
        color: '#ed715c',
        whiteSpace: 'nowrap',
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '24px',
        fontSize: '12px',
        '&$error': {
          position: 'absolute',
          bottom: '-23px',
          color: '#ed715c!important',
        },
      },
    },
    MuiSelect: {
      root: {
        textAlign: 'left',
        fontSize: 16,
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500,
        fontSize: '16px',
        color: '#9b9b9b',
        '&$focused': {
          color: cyan500,
          fontSize: '16px',
        },
        '&$error': {
          color: '#ed715c!important',
        },
      },
      filled: {
        fontSize: '16px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 16,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiStepIcon: {
      text: {
        fontSize: '14px',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '24px',
      },
      fontSizeSmall: {
        fontSize: '20px',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: 0,
        alignItems: 'flex-start',
        color: '#2B3151',
        '&$disabled': {
          color: '#9b9b9b !important',
        },
      },
      label: {
        paddingTop: '11px',
        marginLeft: '-3px',
        fontSize: '16px',
        width: '100%', // for IE <= 11
      },
    },
    MuiCheckbox: {
      root: {
        color: '#9b9b9b',
        '&$checked': {
          color: '#46b688 !important',
        },
        '&$disabled': {
          color: '#dcdcdc !important',
        },
      },
    },
    MuiRadio: {
      root: {
        color: '#9b9b9b',
        '&$checked': {
          color: '#46b688 !important',
        },
        '&$disabled': {
          color: '#dcdcdc !important',
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '1084px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '40px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        right: 0,
        top: 0,
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#eeeeee',
      },
      barColorPrimary: {
        backgroundColor: cyan500,
      },
      root: {
        height: 10,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: lightBlue600,
      },
      colorSecondary: {
        color: '#ffffff',
      },
    },
    MuiTypography: {
      root: {
        color: '#454545!important',
        fontSize: '16px',
      },
    },
    MuiAutocomplete: {
      popper: {
        width: '100%!important',
        maxWidth: '268px',
      },
      paper: {
        fontSize: '16px',
      },
    },
  },
});
