import { Link } from '~/common/components';

export const parseLinks = (text: string) => {
  const urlRegex = /(\bhttps?:\/\/[^\s()<>]+(?:\([\w\d]+\)|(?:[^[:punct:]\s]|\/))?)/gi;
  const parts = text.split(urlRegex);

  return parts.map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <Link key={index} href={part} newTab>
          {part}
        </Link>
      );
    }
    return part;
  });
};
