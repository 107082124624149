import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Any, UUID } from '~/common/utils';
import { SERVER_URL } from '~/env';
import { axios } from '~/root';
import { tusUpload, percentage } from '~/utils/tusUpload';
import { setStyleFile, resetStyleFile } from '../order-now/data/data.actions';
import {
  ORDER_FILE_UPLOAD_REQUEST,
  ORDER_FILE_UPLOAD_SUCCESS,
  ORDER_FILE_UPLOAD_FAILURE,
  ORDER_FILE_UPLOAD_REMOVE,
  RESTORE_ORDER_FILES,
  ORDER_FILE_PROGRESS,
  RESET_ORDER_FILES,
  RESET_FILE_UPLOAD_STATE,
  STYLE_FILE_UPLOAD_REQUEST,
  STYLE_FILE_UPLOAD_SUCCESS,
  STYLE_FILE_UPLOAD_FAILURE,
  STYLE_FILE_UPLOAD_REMOVE,
  RESTORE_STYLE_FILE,
  STYLE_FILE_PROGRESS,
  STYLE_PREFERENCES_FILE_UPLOAD_REQUEST,
  STYLE_PREFERENCES_FILE_UPLOAD_SUCCESS,
  STYLE_PREFERENCES_FILE_UPLOAD_FAILURE,
  STYLE_PREFERENCES_FILE_UPLOAD_REMOVE,
  STYLE_PREFERENCES_FILE_UPLOAD_REMOVE_FAILED,
  STYLE_PREFERENCES_FILE_UPLOAD_DROP,
  STORE_ABORT_CONTROLLER,
  REMOVE_ABORT_CONTROLLER,
  STOP_FILE_UPLOAD,
  ORDER_REFERENCE_FILES,
} from './file-upload.types';

// ORDER FILE
// @ts-ignore
const orderFileUploadRequest = (payload) => ({ type: ORDER_FILE_UPLOAD_REQUEST, payload });
// @ts-ignore
const orderFileUploadSuccess = (payload) => ({ type: ORDER_FILE_UPLOAD_SUCCESS, payload });
// @ts-ignore
const orderFileUploadFailure = (error) => ({ type: ORDER_FILE_UPLOAD_FAILURE, error });
// @ts-ignore
const orderFileProgress = (payload) => ({ type: ORDER_FILE_PROGRESS, payload });

// @ts-ignore
export const orderFileUploadRemove = (payload) => ({
  type: ORDER_FILE_UPLOAD_REMOVE,
  payload,
});

export const resetOrderFiles = () => ({ type: RESET_ORDER_FILES });

export const resetFileUploadState = () => ({ type: RESET_FILE_UPLOAD_STATE });

const abortControllers: Record<number | UUID, AbortController | undefined> = {};

export const useUploadOrderFile = (tag: 'customer_file' | 'order_comment') => {
  const dispatch = useDispatch();

  return {
    abort: (id: number | UUID) => abortControllers[id]?.abort(),
    upload: async (file: File, fakeUpload?: boolean) => {
      const id = uuidv4() as number | UUID;
      dispatch(orderFileUploadRequest({ file, id }));

      if (fakeUpload) {
        const fileDummy = {
          name: file.name,
          uuid: id,
          file,
        };
        dispatch(orderFileUploadSuccess({ file: fileDummy, id }));
        return fileDummy;
      }

      abortControllers[id] = new AbortController();
      return tusUpload({
        file,
        tag,
        onProgress: percentage((progress) => dispatch(orderFileProgress({ progress, id }))),
        // @ts-ignore
        signal: abortControllers[id].signal,
      })
        .then((uploadedFile) => {
          const file = {
            uuid: uploadedFile.id,
            name: uploadedFile.name,
            file: undefined,
          };
          dispatch(orderFileUploadSuccess({ file, id }));
          return file;
        })
        .catch((error) => {
          dispatch(orderFileUploadFailure({ error, id }));
          return Promise.reject(error);
        });
    },
  };
};

// ORDER REFERENCE FILE
// @ts-ignore
const orderReferenceFileUploadRequest = (payload) => ({
  type: ORDER_REFERENCE_FILES.REQUEST,
  payload,
});
// @ts-ignore
const orderReferenceFileUploadSuccess = (payload) => ({
  type: ORDER_REFERENCE_FILES.SUCCESS,
  payload,
});
// @ts-ignore
const orderReferenceFileUploadFailure = (error) => ({ type: ORDER_REFERENCE_FILES.FAILURE, error });
// @ts-ignore
const orderReferenceFileUploadProgress = (payload) => ({
  type: ORDER_REFERENCE_FILES.PROGRESS,
  payload,
});

// @ts-ignore
export const orderReferenceFileRemove = (payload) => ({
  type: ORDER_REFERENCE_FILES.REMOVE,
  payload,
});
// @ts-ignore
export const orderReferenceFileRestore = (payload) => ({
  type: ORDER_REFERENCE_FILES.RESTORE,
  payload,
});

export const uploadOrderReferenceFile = (file: File, fakeUpload?: boolean) => (dispatch: Any) => {
  const id = uuidv4();
  dispatch(orderReferenceFileUploadRequest({ file, id }));

  if (fakeUpload) {
    const fileDummy = {
      name: file.name,
      uuid: id,
      file,
    };
    dispatch(orderReferenceFileUploadSuccess({ file, id }));
    return Promise.resolve(fileDummy);
  }

  return tusUpload({
    file,
    tag: 'order_brief_reference',
    onProgress: percentage((progress) =>
      dispatch(orderReferenceFileUploadProgress({ progress, id })),
    ),
  })
    .then((uploadedFile) => {
      const file = {
        uuid: uploadedFile.id,
        name: uploadedFile.name,
        file: undefined,
      };
      dispatch(orderReferenceFileUploadSuccess({ file, id }));
      return file;
    })
    .catch((error) => {
      dispatch(orderReferenceFileUploadFailure({ error, id }));
      return Promise.reject(error);
    });
};

// STYLE FILE
// @ts-ignore
const styleFileUploadRequest = (payload) => ({ type: STYLE_FILE_UPLOAD_REQUEST, payload });
// @ts-ignore
const styleFileUploadSuccess = (payload) => ({ type: STYLE_FILE_UPLOAD_SUCCESS, payload });
// @ts-ignore
const styleFileUploadFailure = (error) => ({ type: STYLE_FILE_UPLOAD_FAILURE, error });
// @ts-ignore
const styleFileProgress = (payload) => ({ type: STYLE_FILE_PROGRESS, payload });

// @ts-ignore
export const styleFileUploadRemove = () => (dispatch) => {
  dispatch(resetStyleFile());
  dispatch({ type: STYLE_FILE_UPLOAD_REMOVE });
};

// @ts-ignore
const storeAbortController = (abortController) => ({
  type: STORE_ABORT_CONTROLLER,
  payload: abortController,
});

const removeAbortController = () => ({ type: REMOVE_ABORT_CONTROLLER });

export const stopFileUpload = () => ({ type: STOP_FILE_UPLOAD });

// @ts-ignore
export const styleFileUpload = (file, fakeUpload?: boolean) => (dispatch) => {
  const id = uuidv4();
  dispatch(styleFileUploadRequest({ file, id }));

  if (fakeUpload) {
    const fileDummy = {
      name: file.name,
      uuid: id,
      file,
    };
    dispatch(styleFileUploadSuccess({ file: fileDummy, id }));
    dispatch(setStyleFile(fileDummy));
    return Promise.resolve(fileDummy);
  }

  const controller = new AbortController();
  dispatch(storeAbortController(controller));

  return tusUpload({
    file,
    tag: 'customer_file',
    signal: controller.signal,
    onProgress: percentage((progress) => dispatch(styleFileProgress({ progress, id }))),
  })
    .then((uploadedFile) => {
      const file = {
        uuid: uploadedFile.id,
        name: uploadedFile.name,
      };
      dispatch(styleFileUploadSuccess({ file, id }));
      dispatch(removeAbortController());
      dispatch(setStyleFile(file));
      return file;
    })
    .catch((error) => {
      if (error instanceof DOMException && error.name === 'AbortError') {
        dispatch(removeAbortController());
        return;
      }
      dispatch(styleFileUploadFailure({ error, id }));
      return Promise.reject(error);
    });
};

// @ts-ignore
export const restoreOrderFiles = (files) => ({ type: RESTORE_ORDER_FILES, payload: files });

// @ts-ignore
export const restoreStyleFile = (file) => ({ type: RESTORE_STYLE_FILE, payload: file });

// STYLE PREFERENCES FILE UPLOAD

// @ts-ignore
function stylePreferencesFileUploadRequest(payload) {
  return {
    type: STYLE_PREFERENCES_FILE_UPLOAD_REQUEST,
    payload,
  };
}

// @ts-ignore
export const stylePreferencesFileUploadSuccess = (payload) => ({
  type: STYLE_PREFERENCES_FILE_UPLOAD_SUCCESS,
  payload,
});

// @ts-ignore
function stylePreferencesFileUploadFailure(error) {
  return {
    type: STYLE_PREFERENCES_FILE_UPLOAD_FAILURE,
    error,
  };
}

// @ts-ignore
export function stylePreferencesFileUploadRemove(file) {
  return {
    type: STYLE_PREFERENCES_FILE_UPLOAD_REMOVE,
    payload: {
      file,
    },
  };
}

export function stylePreferencesFileRemoveFailedFiles() {
  return {
    type: STYLE_PREFERENCES_FILE_UPLOAD_REMOVE_FAILED,
  };
}

export function stylePreferencesFileUploadDrop() {
  return {
    type: STYLE_PREFERENCES_FILE_UPLOAD_DROP,
  };
}

const STYLE_PREFERENCES_FILE_UPLOAD = `${SERVER_URL}/v1/customers/preferences/upload`;

// refactor this according to quoteFileUpload, styleFileUpload or orderFileUpload actions
// when style preferences will be released in customer area
// @ts-ignore
export function stylePreferencesFileUpload(file) {
  // @ts-ignore
  return (dispatch) => {
    const { name } = file;

    dispatch(stylePreferencesFileUploadRequest({ file, name }));

    const data = new FormData();
    data.append('file', file);

    // @ts-ignore
    axios.post(STYLE_PREFERENCES_FILE_UPLOAD, data, { isJSON: false }).then(
      (response) => {
        dispatch(stylePreferencesFileUploadSuccess({ file: response.data.file, name }));
        dispatch(stylePreferencesFileRemoveFailedFiles());
      },
      (error) => {
        dispatch(stylePreferencesFileUploadFailure({ error, name }));
      },
    );
  };
}
