import { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import { IconBox, Link } from '~/common/components';
import { cx, tw } from '~/common/utils';
import { CustomerUser, useCurrentUser } from '../Auth';
import { useNavigation } from './context';
import { NavigationLink as NavigationLinkType } from './types';

export const menuItemClassnames = tw`
  flex items-center font-brand-b3 px-2 py-[12px] rounded
  hover:text-primary-400 hover:bg-other-100 transition-colors duration-300
  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-300 focus-visible:rounded-sm
`;

export const NavigationLink = forwardRef<HTMLAnchorElement, NavigationLinkType>(
  ({ icon, title, canShow, ...props }, ref) => {
    const { isMenuCollapsed } = useNavigation();
    const location = useLocation();
    const user = useCurrentUser();
    if (canShow && !canShow(user as CustomerUser)) return null;

    const isActive = location.pathname.includes(props.to ?? props.href);

    return (
      <Link.Base
        ref={ref}
        {...props}
        className={cx(menuItemClassnames, 'gap-1', { 'text-primary-300': isActive })}
        data-tt={cx(isMenuCollapsed && title)}
      >
        {icon && <IconBox size="s" icon={icon} />}
        {!isMenuCollapsed && <span className="truncate">{title}</span>}
      </Link.Base>
    );
  },
);
