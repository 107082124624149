import { UseQueryResult } from '@tanstack/react-query';

/**
 * Use this function inside of child components to get query data without
 * prop-drilling. If query data is not defined, then an error is thrown.
 *
 * Example of usage:
 *
 * Hooks:
 * ```
 * export const useOrder = () => useQuery(...)
 * export const useOrderData = assertQueryData(useOrder);
 *
 * ```
 * Components:
 * ```
 * // Parent
 * const order = useOrder();
 * if(!order.data) return null;
 * // Children
 * const order = useOrderData();
 * ```
 */
export const assertQueryData = <T>(hook: () => UseQueryResult<T>) => {
  return () => {
    const { data } = hook();
    if (!data) {
      throw new Error(
        `RQ Hook ${hook.name}Data has thrown error\nMost likely you either forgot to call ${hook.name} beforehand, or to wait till it loads`,
      );
    }
    return data;
  };
};
