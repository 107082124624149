import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { NonEmptyArray } from 'formoid/lib/Array';
import { ReactNode } from 'react';
import { Errors, useModalContext, Button, Card } from '~/common/components';
import { createPrefetchWrapper, cx } from '~/common/utils';
import { useBilling, useBillingData } from './hooks';
import {
  CreditCardPaymentMethod,
  AddCreditCardModal,
  InvoicePaymentMethod,
  InvoicePaymentMethodActions,
  InvoicePaymentMethodModal,
  CreditCardPaymentMethodActions,
} from './PaymentMethods';

const MAX_CARDS_AMOUNT = 3;

interface PaymentMethodsBlockProps {
  className?: string;
  title?: string | null;
  description?: string;
  children?: ReactNode;
  errors?: NonEmptyArray<string> | null;
  hideInvoice?: boolean;
}

export const PaymentMethodsBlock = createPrefetchWrapper(useBilling)(({
  className,
  description,
  children,
  errors,
  title = 'Payment method',
  hideInvoice = false,
}: PaymentMethodsBlockProps) => {
  const { modalOpener } = useModalContext();
  const { cardPaymentMethods, invoicePaymentMethod, invoiceable, company } = useBillingData();

  return (
    <Card title={title} className={cx(className, 'w-full space-y-3')}>
      {children}
      <div className="space-y-1">
        {cardPaymentMethods.map((card) => (
          <CreditCardPaymentMethod
            {...card}
            key={card.id}
            actions={<CreditCardPaymentMethodActions {...card} />}
          />
        ))}
      </div>
      <div>
        {cardPaymentMethods.length < MAX_CARDS_AMOUNT && (
          <Button icon={faPlus} color="text" size="m" onClick={modalOpener(AddCreditCardModal)}>
            Add credit card
          </Button>
        )}
      </div>
      {!hideInvoice &&
        (invoicePaymentMethod ? (
          <InvoicePaymentMethod
            {...invoicePaymentMethod}
            {...company}
            actions={<InvoicePaymentMethodActions {...invoicePaymentMethod} />}
          />
        ) : (
          invoiceable && (
            <Button
              icon={faPlus}
              color="text"
              size="m"
              onClick={modalOpener(InvoicePaymentMethodModal)}
            >
              Add invoice information
            </Button>
          )
        ))}
      {errors && <Errors errors={errors} className="mt-1 text-left" />}
      {description && <p className="font-brand-b3 text-text-400 mt-3">{description}</p>}
    </Card>
  );
});
