import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Link, Popover } from '~/common/components';
import { useLockBodyScroll } from '~/common/hooks';
import { cx, tw } from '~/common/utils';
import { routes } from '~/constants';
import { useScreenSize } from '~/hooks';
import { Navigation } from '~/root/Navigation';

interface MobileMenuProps {
  className?: string;
  color: string;
}

export const MobileMenu = ({ color, className }: MobileMenuProps) => {
  const location = useLocation();
  const screenSize = useScreenSize();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    setIsPopoverOpen(false);
  }, [location]);

  useLockBodyScroll(isPopoverOpen);

  useEffect(() => {
    if (screenSize === 'lg') {
      setIsPopoverOpen(false);
    }
  }, [screenSize]);

  return (
    <Popover
      externalState={[isPopoverOpen, setIsPopoverOpen]}
      className={tw`!z-50 max-h-[calc(100vh-56px)] w-screen max-w-none md:!w-fit md:min-w-[375px]
        !text-text-500 overflow-auto overscroll-contain 
        !rounded-none !border-none !p-0 -ml-1 md:ml-1
        !shadow-[0px_6px_12px_-6px_rgba(0,8,18,0.15)]`}
      compensateOffset={4}
      showArrow
      content={() => (
        <>
          <Navigation isFromHeader />
          <div
            // TODO do an investigation on why top margin was changed :sleuth_or_spy:
            className="sticky bottom-0 pb-3 mt-1 px-3 bg-text-100"
          >
            <Link.Button color="secondary-navy" size="m" href={routes.order}>
              Order now
            </Link.Button>
          </div>
        </>
      )}
      trigger={(props) => (
        <Button.Base
          {...props}
          className={cx('w-5 h-5 flex justify-center items-center', className)}
        >
          <svg
            className="w-3 h-3"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
          >
            <line
              x1="3"
              y1="6"
              x2="21"
              y2="6"
              className={cx(
                'transition-all duration-300 ease-in-out origin-center',
                isPopoverOpen && 'transform translate-y-[4.1px] -translate-x-[4.5px] rotate-45',
              )}
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
            />

            <line
              x1="3"
              y1="12"
              x2="21"
              y2="12"
              className={cx(
                'transition-all duration-300 ease-in-out',
                isPopoverOpen ? 'opacity-0' : 'opacity-100',
              )}
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
            />

            <line
              x1="3"
              y1="18"
              x2="21"
              y2="18"
              className={cx(
                'transition-all duration-300 ease-in-out origin-center',
                isPopoverOpen && 'transform -translate-y-[4.1px] -translate-x-[4.5px] -rotate-45',
              )}
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </Button.Base>
      )}
    />
  );
};
