import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';

import { Chip } from '~/common/components';
import { Any } from '~/common/utils';
import { ComponentNames } from '~/pages/Order-now/constants';
import {
  customFormParamsWhitelist,
  getComponentDetailsForSubmit,
  removeOrderDataFromLocalStorage,
} from '~/pages/Order-now/utils';
import { axios } from '~/root';
import { Init, customerSchema } from '~/root/Auth';
import { reportNewWhenOrdering } from '~/root/Auth/utils';
import { orderCreditPackageSchema } from '~/root/domain';
import { qk } from '~/root/query-keys';
import { dreamdata, ga, posthog } from '~/root/services';
import { facebook, linkedin } from '~/utils';
import { topbarDataSelector } from './data.selectors';
import types from './data.types';
import { stepsHelper } from './data.utils';

const updateComponentsDetails = () => ({ type: types.UPDATE_DETAILS });

// @ts-ignore
const updateSteps = (steps) => ({ type: types.UPDATE_STEPS, steps });

// @ts-ignore
const affect = (props, value) => (dispatch, getState) => {
  /**
   * `affect` changes component structure based on `valueAfffections` at `components` `fields` props.
   * Parameter `props` should be kind of `[ComponentName].[ComponentFieldName]`.
   * Example: props = 'treatments.treatment', 'signup.email` etc.
   *
   * Important: call `affect` after component changes, but not before
   * or set second argument `value`
   */
  const { details, affections } = getState().data;
  const [componentName, componentField] = props.split('.');

  if (!affections[componentField] || !componentName || !componentField) {
    // eslint-disable-next-line no-console
    console.warn('Check param format for `affect` action');
    return;
  }

  // Value of `componentFieldName`. Example: 'treatment', 'turnaround`
  const detailsFieldName = details[componentName][componentField] || value;

  // It contains data for update: `updateComponents`, `originalComponents
  const valueAffection = affections[componentField];
  const valueAffectionUpdate = valueAffection.update || {};

  // If `updateComponents` exists and not equal `{}`, so it matches and we should update components
  const updateComponents = valueAffection[detailsFieldName]?.updateComponents;

  // If `updateComponents` failed, so it we should update components by `originalComponents`
  const originalComponents = valueAffection[valueAffectionUpdate.name]?.originalComponents;
  // `componentData` is updating data
  const componentData = updateComponents || originalComponents;

  // `updateType` is type of update
  const updateType = updateComponents ? 'update' : 'original';

  if (updateType === valueAffectionUpdate.type && detailsFieldName === valueAffectionUpdate.name) {
    /**
     * If `true`, it means we are going to replace by the same data
     * We skip no needed action
     */
    return;
  }

  const update = {
    name: detailsFieldName,
    type: updateType,
  };

  const handleAffect =
    // @ts-ignore


      (updateInterface) =>
      // @ts-ignore
      ([name, data]) => {
        dispatch({
          type: `AFFECT_${name.toUpperCase()}`,
          payload: {
            data,
            update: updateInterface,
            fieldName: componentField,
          },
        });
      };

  if (originalComponents) {
    // @ts-ignore
    const steps = stepsHelper.original(originalComponents);

    // @ts-ignore
    Object.entries(originalComponents).forEach(handleAffect());

    dispatch(updateSteps(steps));
  }

  if (componentData) {
    const steps = stepsHelper[updateType](componentData);

    Object.entries(componentData).forEach(handleAffect(update));

    dispatch(updateSteps(steps));
    dispatch(updateComponentsDetails());
  }
};

// @ts-ignore
const facebookPixel = (event, data, props) => {
  try {
    // @ts-ignore
    window.fbq(event, data, props);
  } catch (error) {
    /**
     * Continue regardless error
     */
  }
};

// @ts-ignore
export const setTreatment = (treatment) => (dispatch) => {
  // @ts-ignore
  facebookPixel('track', 'InitiateCheckout');
  dispatch({ type: types.SET_TREATMENT, payload: treatment });
  // @ts-ignore
  dispatch(affect(`${ComponentNames.TREATMENTS}.treatment`));
};

// @ts-ignore
export const activateAffections = (dataDetails) => (dispatch, getState) => {
  const { data } = getState();
  const details = dataDetails || data.details;

  Object.keys(data.affections).forEach((fieldName) => {
    Object.entries(details).forEach(([componentName, fields]) => {
      // @ts-ignore
      if (fields?.[fieldName]) {
        // @ts-ignore
        dispatch(affect(`${componentName}.${fieldName}`, fields[fieldName]));
      }
    });
  });
};

// @ts-ignore
export const setStyle = (payload) => ({ type: types.SET_STYLE, payload });

export const resetStyle = () => ({ type: types.RESET_STYLE });

// @ts-ignore
export const setCoupon = (coupon) => ({ type: types.SET_COUPON, payload: coupon });

export const resetCoupon = () => ({ type: types.RESET_COUPON });

// @ts-ignore
export const setCard = (payload) => ({ type: types.SET_CARD, payload });

export const setTurnaround =
  (payload: {
    turnaround: number | null;
    deadline: Date | null;
    lastChosenTurnaround?: number | null;
  }) =>
  // @ts-ignore
  (dispatch, getState) => {
    const upsellCoupon = getState().data.details?.coupon?.upsellCoupon;

    dispatch({ type: types.SET_TURNAROUND, payload });
    // @ts-ignore
    dispatch(affect(`${ComponentNames.DELIVERY_DATES}.turnaround`));

    if (upsellCoupon) {
      dispatch(resetCoupon());
    }
  };

// @ts-ignore
export const setAddon = (addonData) => ({ type: types.SET_ADDON, payload: addonData });

export const resetAddon = () => ({ type: types.RESET_ADDON });

// @ts-ignore
export const setGrammar = (language) => ({ type: types.SET_GRAMMAR, payload: language });

// @ts-ignore
export const setSubmit = (payload) => ({ type: types.SET_SUBMIT, payload });

// @ts-ignore
export const setSlides = (amount) => ({ type: types.SET_SLIDES, payload: amount });

// @ts-ignore
export const setUseSharingLinks = (isUsing) => ({
  type: types.SET_SHARING_LINKS,
  payload: isUsing,
});

export const setGoogleSlides = (payload: Chip<string>[]) => ({
  type: types.SET_GOOGLE_SLIDES,
  payload,
});

// @ts-ignore
export const addUploadFile = (file) => ({ type: types.ADD_UPLOAD_FILE, payload: file });

// @ts-ignore
export const removeUploadFile = (name) => ({ type: types.REMOVE_UPLOAD_FILE, payload: name });

export const setBrief = (payload: Record<string, Any>) => ({ type: types.SET_BRIEF, payload });

export const setBriefReferenceFile = (payload: Record<string, Any>) => ({
  type: types.SET_BRIEF_REFERENCE_FILES,
  payload,
});

export const removeBriefReferenceFile = (uuid: string) => ({
  type: types.REMOVE_BRIEF_REFERENCE_FILE,
  payload: uuid,
});

// @ts-ignore
export const setPaymentDetails = (paymentDetails) => ({
  type: types.SET_PAYMENT_DETAILS,
  payload: paymentDetails,
});

export const resetDataPath = () => ({ type: types.RESET_DATA_PATH });

export const resetServerValidationErrors = () => ({ type: types.RESET_SERVER_VALIDATION_ERRORS });

// @ts-ignore
export const applyCoupon = (code) => (dispatch, getState) => {
  dispatch({ type: types.COUPON_REQUEST });

  const payload = {
    product: 'Order', // Static value for backend requirements
    payload: {
      amount: topbarDataSelector(getState())?.price,
      slides: topbarDataSelector(getState())?.slides,
    },
  };

  return axios
    .post(`/v1/coupons/${code}/validate`, payload)
    .then(({ data }) => {
      dispatch({
        type: types.COUPON_SUCCESS,
        payload: { ...data, coupon: code },
      });
    })
    .catch(({ response }) => {
      dispatch({
        type: types.COUPON_FAILURE,
        payload: response.data,
      });
    });
};

// @ts-ignore
export const submitOrder = (queryClient: QueryClient) => (dispatch, getState) => {
  const state = getState();
  const details = state.data.details;
  const { name, signup, deliveryDates } = details;
  const getDeliveryDate = (details: Any) => {
    return {
      ...details,
      deliveryDates: {
        deadline: details.deliveryDates.deadline,
        turnaround: details.deliveryDates.turnaround,
      },
    };
  };

  const form = {
    name,
    data: customFormParamsWhitelist(),
    components: {
      ...getComponentDetailsForSubmit(getDeliveryDate(details)),
    },
  };

  // @ts-ignore
  if (form.components[ComponentNames.PAYMENT_DETAILS]?.paymentMethod === 'card') {
    /**
     * Delete `invoiceSkipDetails` because of server validation
     */
    // @ts-ignore
    delete form.components[ComponentNames.PAYMENT_DETAILS].invoiceSkipDetails;
  }

  const { treatment, slides } = topbarDataSelector(state);
  const currentUser = queryClient.getQueryData<Init>(qk.init)?.user;

  return axios
    .post('/v1/orders', { form })
    .then(({ data }) => {
      const { order, user, redirectUrl } = data;
      const { price } = order;
      const facebookPayload = { value: order.price, currency: currentUser?.currency };

      if (user) {
        if (!currentUser) {
          dreamdata.identify(signup.email);

          posthog?.identify(String(user.id), {
            email: user.email,
            name: user.name,
            company: user.company,
            customer_account_id: user.customerAccountId,
          });

          reportNewWhenOrdering();
        }

        ga.dimension(user.type);

        const safeParsedUser = customerSchema.safeParse(user);

        if (!safeParsedUser.success) {
          captureException(user.error);
        }
        const anyUser = safeParsedUser.success ? safeParsedUser.data : user;

        queryClient.setQueryData<Init>(
          qk.init,
          (prevInit) =>
            user && {
              ...prevInit,
              user: {
                ...prevInit?.user,
                ...anyUser,
                customerLogo: anyUser.companyLogoUrl || null,
              },
            },
        );

        // TODO: Remove this workaround for not having manageCredits bool
        queryClient.invalidateQueries(qk.me);

        if (['new', 'returning'].includes(user.type)) {
          linkedin.track('5099474');
          facebook.track('Purchase', facebookPayload);
          dreamdata.track('regular-purchase');
        } else if (user.type === 'custom portal') {
          linkedin.track('5422978');
          facebook.trackCustom('PurchaseCustomPortal', facebookPayload);
          dreamdata.track('customportal-purchase');
        }
      }

      ReactGA.gtag('event', 'purchase', {
        transaction_id: `${order.id}`,
        value: price,
        items: [
          {
            item_id: `${order.id}`,
            item_name: treatment,
            item_category: currentUser?.customerType || 'new',
            price,
            quantity: slides,
          },
        ],
      });

      if (deliveryDates.turnaround === 10) {
        ReactGA.send({
          hitType: 'event',
          eventCategory: 'upsell',
          eventAction: '10hr-order-form',
          eventLabel: 'accepted',
          eventValue: price,
        });
      }

      ReactGA.send({
        hitType: 'event',
        eventCategory: 'upsell',
        eventAction: '10hr-order-form',
        eventLabel: 'accepted',
        eventValue: price,
      });

      if (redirectUrl) {
        // Redirect a user to card confirmation
        window.location.href = redirectUrl;
      }

      // TODO since there's some order form data mangement happening inside
      // component effects like upload files component it makes sense to erase
      // the data after order form was unmounted, otherwise we'll have weird
      // glitchy data restoration
      removeOrderDataFromLocalStorage();
      dispatch({ type: types.ORDER_FORM_SUCCESS });

      return Promise.resolve(data);
    })
    .catch(({ response }) => {
      const { errors } = response.data;
      dispatch({ type: types.ORDER_FORM_FAILURE, payload: errors });
      return Promise.reject(errors);
    });
};

// @ts-ignore
export const getDeliveryDates = (data) => (dispatch) => {
  dispatch({ type: types.DELIVERY_DATES_REQUEST });

  return axios
    .post('/v1/orders/update-component/deliveryDates', data)
    .then(({ data: { deliveryDates } }) => {
      dispatch({ type: types.DELIVERY_DATES_SUCCESS });
      dispatch({ type: types.UPDATE_COMPONENT, payload: deliveryDates });

      return Promise.resolve(deliveryDates);
    })
    .catch((error) => {
      dispatch({ type: types.DELIVERY_DATES_FAILURE });
      return Promise.reject(error);
    });
};

// @ts-ignore
export const getOrderFormData = (name, queryClient: QueryClient) => (dispatch) => {
  dispatch({ type: types.GET_DATA_REQUEST });

  // Params whitelist
  const params = customFormParamsWhitelist();

  return axios
    .get('/v1/orders/create', { params: { name, ...params } })
    .then(({ data: { form, availableCredits } }) => {
      // TODO verify and transform paymentDetails packages
      const paymentDetails = form.components.find(
        // @ts-ignore
        (component) => component.name === 'paymentDetails',
      );
      if (paymentDetails) {
        paymentDetails.data.packages = orderCreditPackageSchema
          .array()
          .parse(paymentDetails.data.packages);
      }

      stepsHelper.setSteps(form.steps);
      dispatch({ type: types.GET_DATA_SUCCESS, payload: form });
      dispatch(updateComponentsDetails());
      queryClient.setQueryData<Init>(qk.init, (prevInit) => {
        if (prevInit?.user) {
          return { ...prevInit, user: { ...prevInit.user, balance: availableCredits } };
        }
      });
      return Promise.resolve(form);
    })
    .catch((error) => {
      dispatch({ type: types.GET_DATA_FAILURE });
      return Promise.reject(error);
    });
};

// @ts-ignore
export const setSignupField = (name, value) => ({
  type: types.SET_SIGNUP_FIELD,
  payload: { [name]: value },
});

// @ts-ignore
export const setCustomFields = (name, value) => ({
  type: types.SET_CUSTOM_FIELDS,
  payload: { [name]: value },
});

// @ts-ignore
export const getNotificationsStatus = (orderId) => (dispatch) => {
  dispatch({ type: types.GET_NOTIFICATIONS_REQUEST });

  axios
    .get(`/v1/orders/${orderId}/notifications`)
    .then(({ data: { status, notifications } }) => {
      if (status === 'success') {
        const { sms } = notifications.length && notifications[0];
        const notificationsStatus = sms?.active;
        const option = sms?.options.onlyOnFirstDraft
          ? 'Only when the first draft is ready'
          : 'On the first draft and each revision round';

        dispatch({
          type: types.GET_NOTIFICATIONS_SUCCESS,
          payload: {
            status: notificationsStatus,
            option,
          },
        });
      } else {
        dispatch({ type: types.GET_NOTIFICATIONS_FAILURE });
      }
    })
    .catch(() => {
      dispatch({ type: types.GET_NOTIFICATIONS_FAILURE });
    });
};

export const updateNotificationsSettings =
  // @ts-ignore


    (orderId, { sms, option }) =>
    // @ts-ignore
    (dispatch) => {
      dispatch({ type: types.UPDATE_NOTIFICATIONS_REQUEST });

      return axios
        .post(`/v1/orders/${orderId}/notifications`, { sms })
        .then(() => {
          dispatch({
            type: types.UPDATE_NOTIFICATIONS_SUCCESS,
            payload: {
              status: sms.active,
              option,
            },
          });
        })
        .catch((error) => {
          dispatch({ type: types.UPDATE_NOTIFICATIONS_FAILURE });
          if (error instanceof AxiosError) {
            // @ts-ignore
            throw new AxiosError(error);
          }

          throw new Error(error);
        });
    };

// @ts-ignore
export const restoreDataFromLS = (details) => (dispatch) => {
  dispatch({
    type: types.RESTORE_DATA_FROM_LS,
    payload: details,
  });
  dispatch(activateAffections(details));
};

// @ts-ignore
export const setStyleFile = (file) => ({ type: types.SET_STYLE_FILE, payload: file });

export const resetStyleFile = () => ({ type: types.RESET_STYLE_FILE });

// @ts-ignore
export const setUpsell = (payload) => ({ type: types.SET_UPSELL, payload });

export const resetDetails = () => ({ type: types.RESET_DETAILS });

// @ts-ignore
export const removeServerValidationError = (fieldName) => ({
  type: types.REMOVE_SERVER_VALIDATION_ERROR,
  payload: fieldName,
});

export const resetOrderForm = () => ({ type: types.ORDER_FORM_RESET });
