export const qk = {
  ordersAll: ['orders'],
  ordersList: ['orders', 'list'],
  order: (id: number) => ['orders', id],
  orderEvents: (id: number) => ['orders', id, 'events'],
  orderFeedback: (id: number) => ['orders', id, 'feedback'],
  billing: ['billing'],
  billingPayments: ['billing', 'payments'],
  billingCreditPackages: ['billing', 'credit-packages'],
  billingSubscriptionPlans: ['billing', 'subscription-plans'],
  sessions: ['sessions'],
  styleTemplates: ['styleTemplates'],
  customFields: ['custom-fields'],
  init: ['init'],
  login: ['login'],
  me: ['me'],
  oauthLogin: ['oauth'],
  signup: ['signup'],
  briefQuestions: ['orders', 'brief-questions'],
  resetPasswordValidate: ['reset-password', 'validate'],
  stripe: ['stripe'],
  countries: ['countries'],
  myTeam: ['profile', 'team'],
  joinTeam: ['join-team'],
} as const;
