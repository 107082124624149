import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { useState, ReactNode } from 'react';
import { PopupModal } from 'react-calendly';
import { Button, IconButton } from '~/common/components';
import './book-call.scss';

interface BookCallProps {
  url: string;
}

export const BookCall = ({ url }: BookCallProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const BOOK_CALL = 'bookCallPopup';
  const bookCallClosed = window.localStorage.getItem(BOOK_CALL);
  const [, render] = useState({});

  const handleNotificationClose = () => {
    window.localStorage.setItem(BOOK_CALL, 'true');
    render({});
  };

  return (
    <div className="book-call f-shrink-0">
      {!bookCallClosed && (
        <Notification type="info" arrow="top" onClose={handleNotificationClose}>
          Don't want to write a long message? <b>Book a call</b>
        </Notification>
      )}

      <Button icon={faPhoneAlt} color="text" onClick={() => setIsOpen(true)}>
        Book a call
      </Button>

      <PopupModal
        url={url}
        open={isOpen}
        onModalClose={() => setIsOpen(false)}
        rootElement={document.getElementById('root') as HTMLElement}
      />
    </div>
  );
};

export type NotificationType = 'success' | 'warning' | 'error' | 'info';

type NotificationProps = {
  type?: NotificationType;
  arrow?: 'top' | 'left' | 'right' | 'bottom';
  className?: string;
  title?: string;
  children?: ReactNode;
  onClose?: () => void;
};

const Notification = ({
  type = 'success',
  arrow,
  className,
  title,
  children,
  onClose,
}: NotificationProps) => {
  return (
    <div
      className={cx('notification notification__container', type, className, arrow, {
        close: !!onClose,
        arrow,
      })}
    >
      {title && <p className="notification__title">{title}</p>}
      {children}
      {onClose && (
        <IconButton icon={faTimes} className="notification__close bb" onClick={onClose} />
      )}
    </div>
  );
};
