import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { NonEmptyArray } from 'formoid/lib/Array';
import { Errors, Menu, useModalContext, Button, Card } from '~/common/components';
import { createPrefetchWrapper, cx } from '~/common/utils';
import { requiredGenmabBillingFields, useIsGenmabUnicorn } from '~/root/unicorns/genmab';
import { BillingAddress, Company } from '../domain';
import { useBilling, useBillingData } from '../hooks';
import { BillingInformationModal } from './BillingInformationModal';
import { BillingInformationView } from './BillingInformationView';

interface BillingInfoBlockProps {
  className?: string;
  errors?: NonEmptyArray<string> | null;
}

export type BillingCompany = BillingAddress & Company;

const requiredBillingFields: (keyof BillingCompany)[] = [
  'country',
  'city',
  'street',
  'phoneCountryId',
  'phoneNumber',
];

const checkIsBillingInfoFilled = (billingInfo: BillingCompany, fields: (keyof BillingCompany)[]) =>
  fields.every((requiredField) => !!billingInfo[requiredField]);

export const BillingInformationBlock = createPrefetchWrapper(useBilling)(({
  className,
  errors,
}: BillingInfoBlockProps) => {
  const { modalOpener } = useModalContext();
  const { billingAddress, company } = useBillingData();

  const isGenmab = useIsGenmabUnicorn();

  const isBillingAddressFilled =
    billingAddress &&
    checkIsBillingInfoFilled(
      { ...billingAddress, ...company },
      isGenmab ? requiredGenmabBillingFields : requiredBillingFields,
    );

  return (
    <Card title="Billing information" className={cx(className, 'w-full')}>
      {isBillingAddressFilled ? (
        <BillingInformationView
          {...company}
          {...billingAddress}
          actions={
            <Menu>
              <Menu.Item onClick={modalOpener(BillingInformationModal)}>Edit</Menu.Item>
            </Menu>
          }
        />
      ) : (
        <Button icon={faPlus} color="text" size="m" onClick={modalOpener(BillingInformationModal)}>
          Add billing information
        </Button>
      )}
      {errors && <Errors errors={errors} className="text-left mt-1" />}
    </Card>
  );
});
