import { Context, createContext, ReactElement, ReactNode, useContext } from 'react';
import { Any, nonNullable } from '~/common/utils';

type Props<P> = P & JSX.IntrinsicAttributes;

type Component<P> = (props: Props<P>) => ReactElement<Any, Any> | null;

type Wrapper<P> = (Component: Component<P>) => Component<P>;

/**
 * Throws if there's no context provider above
 * Ensures contexts are used properly
 */
export const useEnsuredContext = <T,>(context: Context<T>) => nonNullable(useContext(context));

/**
 * Creates context provider & hook to access it correctly
 */
export const createContextPair =
  <T, K>(contextDataHook: (props: K) => T, children?: ReactNode) =>
  (name: string) => {
    const Context = createContext<T | null>(null);

    const Provider = ({ children, props }: { children: ReactNode; props: K }) => {
      const value = contextDataHook(props);
      return <Context.Provider value={value}>{children}</Context.Provider>;
    };

    const useEnsuredContext = () => {
      const data = useContext(Context);
      if (!data) {
        throw new Error(
          "There's no data for " + name + ' context. make sure you wrapped parent with withContext',
        );
      }
      return data;
    };

    const withContext = <P extends K>(Component: Component<P>) => {
      return (props: Props<P>) => (
        <Provider props={props}>
          <Component {...props} />
          {children}
        </Provider>
      );
    };

    return [useEnsuredContext, withContext] as const;
  };

export const combineWrappers = (...wrappers: Wrapper<Any>[]) => {
  return <P,>(component: Component<P>) => {
    for (const wrapper of wrappers) {
      component = wrapper(component);
    }
    return component;
  };
};
