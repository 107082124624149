import { useEffect, useState } from 'react';

export const useDocumentVisibility = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const visibilityListener = () => setVisible(!document.hidden);
    document.addEventListener('visibilitychange', visibilityListener);

    return () => document.removeEventListener('visibilitychange', visibilityListener);
  }, []);

  // it is possible for tab to lose focus before attaching visibilitychange
  // listener, so by checking document.hidden in interval we make sure
  // that tab will get correct visibility status
  useEffect(() => {
    const interval = setInterval(() => setVisible(!document.hidden), 5000);
    return () => clearInterval(interval);
  }, []);

  return visible;
};
