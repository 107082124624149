import { Button, Link } from '~/common/components';
import { copyToClipboard } from '~/common/utils';
import { BillingCompany } from '~/pages/My-profile/components/Billing';
import { useCurrentUser } from '~/root/Auth';

// Genmab is a potentially significant customer with the ability to greatly improve our revenue.
// To accommodate their needs, we've added custom logic, understanding it's not ideal due to added complexity
// However, the revenue potential justifies this trade-off. If the partnership doesn't work out,
// we're prepared to remove the custom code with minimal impact.
// TY Vlad for this description :)
export const useIsGenmabUnicorn = () => {
  const user = useCurrentUser();
  return user?.organisationName === 'Genmab';
};

export const requiredGenmabBillingFields: (keyof BillingCompany)[] = [
  'country',
  'vatNumber',
  'name',
];

export const GenmabPoNumberHint = (
  <div className="w-[295px]">
    Note: If you do not have a PO number, please request a quote at{' '}
    <Button
      color="text"
      onClick={(e) => {
        e.preventDefault();
        copyToClipboard('support@24slides.com');
      }}
      className="inline-flex"
    >
      support@24slides.com
    </Button>
    . Once received, contact{' '}
    <Button
      color="text"
      onClick={(e) => {
        e.preventDefault();
        copyToClipboard('globalprocurement@genmab.com');
      }}
      className="inline-flex"
    >
      globalprocurement@genmab.com
    </Button>{' '}
    to create a Purchase Requisition (PR). The PR will be sent to your Finance Business Partner and
    Cost Center leader for approval. Once approved, it will be converted into a PO number that you
    can use.
  </div>
);

export const GenmabVatNumberHint = (
  <>
    <p>
      For companies in the EU, it is mandatory to provide your company VAT number to avoid paying
      additional VAT on the order.
    </p>
    <Link
      size="s"
      href="https://s3.eu-central-1.wasabisys.com/24slides-public/docs/genmabs-vat-reg-numbers-2024-rev.pdf?response-content-disposition=attachment"
    >
      Download VAT number list
    </Link>
  </>
);
