import {
  faArrowsUpDownLeftRight,
  faComputerMouseScrollwheel,
} from '@fortawesome/pro-regular-svg-icons';
import { Checkbox, FormControl, FormControlLabel, Popover } from '@material-ui/core';
import cx from 'classnames';
import {
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';
import { IconBox } from '~/common/components';
import { useDevice } from '~/common/kits/device';
import { Any, handleEnterPress } from '~/common/utils';
import Icon from '~/components/icons';
import CloseIcon from '~/components/ui/CloseIcon';
import MouseLeftButtonIcon from '~/images/InnerOrder/mouse-left-button-icon.svg';
import { OrdersSelect } from '~/pages/My-profile/Order/LegacyConfirmation/OrdersSelect';
import { ZoomPanContainer } from '~/pages/My-profile/Order/SlidePreviews/CommentMode/ZoomPan';
import { clamp } from '~/utils/helpers';
import { colors } from '~/utils/palette';
import { Annotation } from '../../../Order/domain';
import {
  useCreateAnnotation,
  useDeleteAnnotation,
  useFullOrderData,
  usePostAnnotationComment,
  useResolveAnnotation,
  useSlideRead,
} from '../../../Order/hooks';
import { RestrictionOverlay, SlideAnnotation } from '../CommentMode';
import { commentStorage, handleConfirmClickBind } from '../utils';
import CommentInput from './CommentInput';
import { Arrow } from './PreviewSlider';
import { User } from './User';

const PRECISION = 10000;

export const calculatePosition = (position: number, side: number) => {
  const calculatedValue = Math.floor((position / side) * PRECISION);
  return clamp(0, calculatedValue, PRECISION);
};
export type AnnotationPosType = {
  x: number;
  y: number;
};

const getPosition = ({ x, y }: AnnotationPosType) => ({
  left: `${x / 100}%`,
  top: `${y / 100}%`,
});

const Space = () => (
  <span className="font-brand-ol text-other-300 h-3 px-1 mx-[4px] bg-[#454545] rounded">Space</span>
);

const iconClassName =
  '!inline-flex h-3 w-3 font-brand-link mx-[4px] align-middle text-other-300 bg-[#454545] rounded';

type Props = {
  slideId: number;
  asideSlides: ReactNode[];
  stateVersion: number;
  slideVersions: ReactNode[];
  activeSlideIndex: number;
  versionTimestamp: string | null;
  onSelectChange: () => void;
  setActiveSlideIndex: Dispatch<SetStateAction<number>>;
  onClose: () => void;
};

const EmptyArray: Any[] = [];

export const CommentModeModal = ({
  slideId,
  asideSlides,
  slideVersions,
  stateVersion,
  activeSlideIndex,
  setActiveSlideIndex,
  versionTimestamp,
  onSelectChange,
  onClose,
}: Props) => {
  const { slideList: slides, maxAnnotationIndex, slideAnnotations } = useFullOrderData();
  const device = useDevice();
  const [activeAnnotation, setActiveAnnotation] = useState<number | null>(null);
  const [anchor, setAnchor] = useState<Element | null>(null);
  const [loadedSlideId, setLoadedSlideId] = useState<number | null>(null);

  const { isSlideRead, readAnnotation } = useSlideRead();

  const createAnnotation = useCreateAnnotation();
  const postAnnotationComment = usePostAnnotationComment();
  const resolveAnnotation = useResolveAnnotation();
  const deleteAnnotation = useDeleteAnnotation();

  const [pendingAnnotation, setPendingAnnotation] = useState<Annotation | null>(null);

  const isPosting = createAnnotation.isLoading || postAnnotationComment.isLoading;

  const { currentSlide, isLastVersion } = useMemo(() => {
    const slide = slides[activeSlideIndex];
    const isLastVersion = slide.version === stateVersion;
    const previousVersion = Boolean(slide && !isLastVersion);
    const currentSlide =
      (previousVersion ? slide.versions.find(({ version }) => version === stateVersion) : slide) ??
      slide;
    return {
      currentSlide,
      isLastVersion,
    };
  }, [activeSlideIndex, slides, stateVersion]);

  const currentSlideAnnotations = useMemo(() => {
    const annotations =
      slideAnnotations.find(({ id }) => id === currentSlide.id)?.annotations || [];
    commentStorage.reset();
    return annotations;
  }, [currentSlide.id, slideAnnotations]);

  const displaySlideAnnotations = useMemo(() => {
    if (!pendingAnnotation) {
      return currentSlideAnnotations;
    }

    // unfortunately since currentSlideAnnotations can't be batch updated with
    // pendingAnnotation, we end up in one frame of inconsistent state, where
    // currentSlideAnnotations already contains new annotation that was just
    // created, but pendingAnnotation wasn't cleared yet
    const pendingIsAlreadyThere = currentSlideAnnotations.find(
      (annotation) => annotation.index === pendingAnnotation.index,
    );
    return pendingIsAlreadyThere
      ? currentSlideAnnotations
      : [...currentSlideAnnotations, pendingAnnotation];
  }, [pendingAnnotation, currentSlideAnnotations]);

  useEffect(() => {
    setAnchor(null);
    setPendingAnnotation(null);
  }, [activeSlideIndex]);

  const currentAnnotation = useMemo(() => {
    return (
      pendingAnnotation ??
      currentSlideAnnotations.find((annotation) => annotation.index === activeAnnotation)
    );
  }, [pendingAnnotation, currentSlideAnnotations, activeAnnotation]);

  const addAnnotation = (pos: AnnotationPosType) => {
    const index = maxAnnotationIndex + 1;

    const annotation = {
      // negative id as a placeholder for annotations not yet created on the server
      id: Math.floor(Math.random() * 300) * -1,
      index,
      pos,
      comments: [],
      timestamp: Math.floor(new Date().getTime() / 1000),
      resolved: false,
    };
    // @ts-ignore
    setPendingAnnotation(annotation);
    setActiveAnnotation(index);
  };

  const handleAnnotationCreate: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!(event.target instanceof HTMLElement) || event.target.tagName !== 'IMG') {
      return;
    }

    const rect = event.target.getBoundingClientRect();
    const pos: AnnotationPosType = {
      x: calculatePosition(event.clientX - rect.left, rect.width),
      y: calculatePosition(event.clientY - rect.top, rect.height),
    };
    addAnnotation(pos);
  };

  const handleCommentsOpen = (annotationIndex: number) => (event: SyntheticEvent) => {
    setAnchor(event.currentTarget);
    setActiveAnnotation(annotationIndex);
    commentStorage.reset();
  };

  const onImageLoad = () => {
    setLoadedSlideId(currentSlide?.id ?? null);
  };

  const slideMarkup = (() => {
    const { index, preview, version: slideVersion, versions } = slides[activeSlideIndex];
    const isLastVersion = slideVersion === stateVersion;
    const previousVersion = !isLastVersion;
    const imageVersionSource =
      previousVersion && versions.find(({ version }) => version === stateVersion)?.preview;
    const imageSource = imageVersionSource || preview;

    const annotations =
      loadedSlideId === currentSlide.id &&
      displaySlideAnnotations.map(({ id: annotationId, index: annotationIndex, resolved, pos }) => (
        <SlideAnnotation
          key={annotationIndex}
          id={annotationId}
          index={annotationIndex}
          resolved={resolved}
          slideId={slideId}
          position={getPosition(pos)}
          onClick={handleCommentsOpen(annotationIndex)}
        />
      ));

    return (
      <ZoomPanContainer
        key={imageSource}
        onClick={handleAnnotationCreate}
        onKeyPress={handleEnterPress(handleAnnotationCreate)}
      >
        <img
          className="h-auto rounded select-none"
          src={imageSource}
          alt={`Slide #${index} preview`}
          onLoad={onImageLoad}
          draggable={false}
        />
        {previousVersion && <RestrictionOverlay />}
        {annotations}
      </ZoomPanContainer>
    );
  })();

  const handlePopoverClose = () => {
    if (isPosting || !currentAnnotation) {
      return;
    }

    const { id: annotationId, comments = [] } = currentAnnotation;
    const thereAreComments = !!comments.length;
    setAnchor(null);
    setActiveAnnotation(null);

    if (thereAreComments) {
      readAnnotation(slideId, annotationId);
    } else {
      setPendingAnnotation(null);
    }
  };

  const handleAnnotationDelete = () => {
    if (currentAnnotation?.id) {
      deleteAnnotation.mutate({
        slideId: currentSlide.id,
        annotationId: currentAnnotation.id,
      });
      setAnchor(null);
    }
  };

  const handleResolveChange = () => {
    if (!currentAnnotation) {
      return;
    }

    const { id: annotationId, resolved } = currentAnnotation;
    resolveAnnotation.mutate({
      slideId: currentSlide.id,
      annotationId: annotationId,
      resolved: !resolved,
    });
  };

  const renderComments = () => {
    if (!currentAnnotation) {
      return null;
    }

    const { resolved, comments = [] } = currentAnnotation;

    if (!comments.length) {
      return <div className="flex-1" />;
    }

    return (
      <>
        <header className="f-between-center">
          <FormControl
            classes={{
              root: 'ac__form-control',
            }}
            disabled={!isLastVersion}
          >
            <FormControlLabel
              name="lool"
              control={
                <Checkbox
                  classes={{
                    root: 'ac__checkbox',
                  }}
                  onChange={handleResolveChange}
                  checked={!!resolved}
                />
              }
              label="Mark as resolved"
            />
          </FormControl>
          <button
            type="button"
            className="boundary-box"
            onClick={handleAnnotationDelete}
            disabled={!isLastVersion}
          >
            {/* @ts-ignore */}
            <Icon icon="trash" color={colors.graysuit} />
          </button>
        </header>
        <div className="ac__chat-container">
          {comments.map((comment) => (
            <User {...comment} key={comment.id} files={EmptyArray} avatar={null} />
          ))}
        </div>
      </>
    );
  };

  const postComment = ({ comment }: { comment: string }) => {
    if (!currentAnnotation) {
      return Promise.resolve();
    }
    const { id: annotationId, pos, comments = [] } = currentAnnotation;

    if (comments.length && annotationId) {
      // Annotation is tracked by server. Add a new comment
      return postAnnotationComment.mutateAsync({ annotationId, comment, slideId: currentSlide.id });
    }

    // Annotation handled by client side only. Create a new annotation
    return createAnnotation
      .mutateAsync({ pos, comment, slideId: currentSlide.id, key: v4() })
      .then((annotation) => {
        setPendingAnnotation(null);
        readAnnotation(slideId, annotation.id);
        // workaround for cases where popup gets broken by mismatching FE/BE annotation indexes
        if (annotation.index !== currentAnnotation?.index) {
          setAnchor(null);
          setActiveAnnotation(null);
        }
      });
  };

  // Disable auto focus for mobile because of its keyboard
  const autoFocus = device !== 'MOBILE';

  const popover = (
    <Popover
      open={!!anchor}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        root: 'annotation-comment ac__popover iop',
        paper: 'ac__paper',
      }}
      transitionDuration={0}
      onClose={handlePopoverClose}
    >
      <div className="ac__container f-column">
        <div className="ac__header-mobile f-between-center">
          Comments
          <button type="button" className="boundary-box" onClick={handlePopoverClose}>
            <Icon icon="times" fill={colors.charcoal} width={22} height={22} />
          </button>
        </div>
        {renderComments()}
        {isLastVersion && (
          <CommentInput
            autoFocus={autoFocus}
            shouldStoreComment
            showAttachment={false}
            isPosting={isPosting}
            postCommentAction={postComment}
          />
        )}
      </div>
    </Popover>
  );

  return (
    <div className="inner-order-preview iopr__container f relative">
      <button
        type="button"
        className={cx('bb-32 button-reset modal__close-button cm__close-button')}
        onClick={handleConfirmClickBind(onClose)}
      >
        <CloseIcon className="close-icon cm__close-icon" color="#fff" />
      </button>

      <ul className="inner-order-preview-aside iopa__list f-column f-shrink-0">{asideSlides}</ul>
      <div className="iopr__wrapper f-1 f-column">
        <header className="f-align-center full-width relative">
          <div className="iopr__title">{`Slide #${slides[activeSlideIndex].index}`}</div>

          <div className="f-between-center full-width-mobile">
            {!!versionTimestamp && (
              <span
                className={cx('iopr__time f-align-center boundary-wrapper mode-note', {
                  'new-version': !isSlideRead(slideId),
                })}
              >
                <Icon icon="update-clock" fill={colors.graysuit} />
                <span>{` ${versionTimestamp}`}</span>
              </span>
            )}

            <OrdersSelect
              className="iopr__versions"
              value={stateVersion}
              classes={{
                select: 'iopr__select',
                // @ts-ignore
                icon: 'iopr__select-icon',
              }}
              MenuProps={{
                classes: {
                  list: 'iopr__menu',
                },
              }}
              onChange={onSelectChange}
            >
              {slideVersions}
            </OrdersSelect>
          </div>
        </header>

        {popover}

        <div className="relative grow-[2] min-h-0 md:px-8 grid place-items-center" tabIndex={0}>
          {slideMarkup}

          <Arrow
            type="prev"
            disabled={activeSlideIndex === 0}
            onClick={() => setActiveSlideIndex((index) => clamp(0, index - 1, slides.length - 1))}
          />

          <Arrow
            type="next"
            disabled={activeSlideIndex === slides.length - 1}
            onClick={() => setActiveSlideIndex((index) => clamp(0, index + 1, slides.length - 1))}
          />
        </div>

        <footer className="grid grid-cols-3 gap-3 flex-none desktop">
          <div>
            Want to leave a comment? Simply move your cursor over the slide and click
            <div className="h-3 w-3 mx-[4px] align-middle inline-grid place-items-center bg-[#454545] rounded">
              <img src={MouseLeftButtonIcon} alt="mouse left button" />
            </div>
          </div>
          <div>
            Need to move around the slide? Hold down
            <Space />
            and drag your cursor
            <IconBox icon={faArrowsUpDownLeftRight} className={iconClassName} />
          </div>
          <div>
            Want to zoom in or out? Hold down
            <Space />
            and scroll up or down
            <IconBox icon={faComputerMouseScrollwheel} className={iconClassName} />
          </div>
        </footer>

        <footer className="f-between-center f-shrink-0 desktop-hide">
          <div className="flex flex-1">
            To leave or read a comment, tap,
            <div className="boundary-wrapper">
              <svg width={13} height={16} viewBox="0 0 13 16" fill="none">
                <path
                  d="M4.944 3.556a.889.889 0 011.778 0v3.973l1.076.116 4.39 1.946c.472.214.756.685.756 1.2v3.876A1.38 1.38 0 0111.611 16H5.833c-.337 0-.657-.133-.889-.382L.59 11.884l.658-.684a.881.881 0 01.657-.284H2.1l2.844 1.528V3.556z"
                  fill="#D9D7D7"
                />
                <path
                  d="M4.055 6.613V3.556a1.778 1.778 0 013.556 0v3.057a3.538 3.538 0 001.777-3.057 3.556 3.556 0 10-7.11 0c0 1.306.72 2.444 1.777 3.057z"
                  fill="#fff"
                />
              </svg>
            </div>
            on a slide/comment.
          </div>
        </footer>
      </div>
    </div>
  );
};
