import { z } from 'zod';
import { paginationSchema } from '~/common/hooks';
import { metadataSchema } from '~/common/kits/table';

// TODO: extract to common domain for "Orders" and "Order"
export const orderStatus = z.enum([
  'Waiting for your feedback',
  'Waiting our approval',
  'Design in progress',
  'Completed',
  'Completed (invoice)',
  'Cancelled',
  'Rejected',
]);

const baseOrderSchema = z
  .object({
    id: z.number(),
    status: orderStatus,
    statusColor: z.enum(['red', 'orange', 'blue']), // TODO: Can be removed || we can use this field but values should be updated on BE
    title: z.string(),
    slides: z.number(),
    assigned: z.string(),
    price: z.string(),
    deadline: z.string(),
    previewImage: z.string().nullish(), // Probably should be .nullable()
    newLabel: z.boolean().optional(), // TODO BE for some reason it's optional in some orders :shrug:
    isSubscribed: z.boolean(),
    isConfidential: z.boolean(),
  })
  .transform(({ newLabel, ...order }) => ({ ...order, isNew: newLabel || false }));

const accessibleOrderSchema = baseOrderSchema.and(
  z.object({
    isAccessible: z.literal(true),
  }),
);

const inaccessibleOrderSchema = baseOrderSchema.and(
  z.object({
    isAccessible: z.literal(false),
  }),
);

export const ordersSchema = z
  .object({
    items: z.array(accessibleOrderSchema.or(inaccessibleOrderSchema)),
    metadata: metadataSchema,
    // TODO: Check why it's optional on playground BE :shrug:
    fields: z
      .object({
        status: z.record(z.string()),
      })
      .optional(),
  })
  .transform(({ items, ...data }) => ({
    ...data,
    orders: items,
    statuses: data.fields
      ? Object.entries(data.fields.status).map(([value, name]) => ({ name, value: Number(value) }))
      : null,
  }));

export type Orders = z.infer<typeof ordersSchema>;

export type OrderItem = Orders['orders'][number];

export const ordersParamSchema = paginationSchema().extend({
  'filter[search]': z.string().optional().catch(undefined),
  'filter[status]': z.coerce.number().optional().catch(undefined),
});

export type OrdersParams = z.infer<typeof ordersParamSchema>;
