import cx from 'classnames';
import { ReactNode } from 'react';

type Props = {
  className?: string;
  children: ReactNode | string;
  position?: 'top' | 'bottom';
  top?: string;
  left?: string;
};
const defaultProps = {
  className: '',
  position: 'top',
  top: '',
  left: '',
};

// TODO cleanup remove with legacy InnerOrderPage
const Tooltip = ({ className = '', position, children, top, left }: Props) => (
  <div
    className={cx('tooltip', {
      [className]: !!className,
      'tooltip--top': position === 'top',
      'tooltip--bottom': position === 'bottom',
    })}
    style={{
      left,
      top,
    }}
  >
    {children}
  </div>
);

Tooltip.defaultProps = defaultProps;
export default Tooltip;
