import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import {
  ChangeEvent,
  ComponentProps,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconButton, Input } from '~/common/components';
import { Overwrite } from '~/common/utils';

type Props = Overwrite<
  ComponentProps<typeof Input>,
  {
    value?: string;
    onChange: (value: string) => void;
    onChangeStrategy?: 'onChange' | 'onSubmit' | 'debounce';
    type?: 'text';
    debounceTimeout?: number;
  }
>;

export const SearchField = ({
  value = '',
  onChange,
  onChangeStrategy = 'onChange',
  placeholder = 'Search',
  size = 'm',
  debounceTimeout = 700,
  ...props
}: Props) => {
  const [search, setSearch] = useState(value || '');
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const clearDebounce = () => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
      debounceRef.current = null;
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (onChangeStrategy === 'debounce') {
      clearDebounce();
      debounceRef.current = setTimeout(() => onChange(event.target.value), debounceTimeout);
    } else if (onChangeStrategy === 'onChange') {
      onChange(event.target.value);
    }
  };

  const handleEnter: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      onChange(search);
    }
  };

  const handleClear = () => {
    setSearch('');
    onChange('');
    clearDebounce();
  };

  useEffect(() => clearDebounce, []);
  useEffect(clearDebounce, [onChangeStrategy]);
  useEffect(() => setSearch(value), [value]);

  return (
    <Input
      {...props}
      icon={faSearch}
      value={search}
      onChange={handleChange}
      onKeyDown={onChangeStrategy === 'onSubmit' ? handleEnter : undefined}
      placeholder={placeholder}
      size={size}
    >
      {search && (
        <IconButton
          size="s"
          className="text-greyscale-300 hover:text-greyscale-500 focus-within:text-greyscale-500"
          icon={faTimesCircle}
          onClick={handleClear}
        />
      )}
    </Input>
  );
};
