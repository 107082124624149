import { mastercard, visa } from '~/assets/images/card-brands';
import { poweredByStripe } from '~/assets/images/stripe';
import { Checkbox, ModalContentProps, ModalHeader, ModalShell } from '~/common/components';
import { useToggle } from '~/common/hooks';
import { combineWrappers } from '~/common/kits/context';
import { createPrefetchWrapper } from '~/common/utils';
import { CardPaymentMethod } from '~/root/domain';
import {
  CreditCardForm,
  StripeFormElement,
  useStripeElementsState,
  withStripeElements,
} from '~/root/stripe';
import { useAddCardPaymentMethod, useBilling, useBillingData } from '../hooks';

type AddCreditCardProps = ModalContentProps & {
  title?: string;
  onAdd?: (cards: CardPaymentMethod[]) => void;
};

const withPrefetchWrapper = createPrefetchWrapper(useBilling);
const withWrappers = combineWrappers(withStripeElements, withPrefetchWrapper);

export const AddCreditCardModal = withWrappers(
  ({ title = 'Add credit card', onAdd, onClose }: AddCreditCardProps) => {
    const { paymentMethods } = useBillingData();
    const { elementsState, setElementsState } = useStripeElementsState();

    const [setDefault, toggleDefault] = useToggle(true);

    const addCardMutation = useAddCardPaymentMethod();

    const submit = () => {
      addCardMutation
        .mutateAsync({ default: setDefault, returnUrl: window.location.pathname })
        .then((res) => {
          onAdd?.(res.cardPaymentMethods);
          onClose();
        })
        .catch((error) => {
          // BE validation message
          const errorMessage = error.data?.data?.message;

          if (errorMessage) {
            return setElementsState({
              number: {
                complete: false,
                error: {
                  message: errorMessage,
                },
              },
            });
          }

          setElementsState({
            number: {
              complete: false,
              error: {
                message: 'Something went wrong',
              },
            },
          });

          return Promise.reject(error);
        });
    };

    const isFormCompleted = Object.keys(elementsState).every(
      (formElementKey) => elementsState[formElementKey as StripeFormElement].complete,
    );

    return (
      <>
        <ModalHeader onClose={onClose} title={title} />
        <ModalShell
          submitText="Add card"
          onClose={onClose}
          onSubmit={submit}
          loading={addCardMutation.isLoading}
          className="md:w-[616px]"
          disabled={!isFormCompleted}
        >
          <CreditCardForm className="mb-2" />
          <div className="cols-span-2 flex mb-2">
            <img src={poweredByStripe} alt="Powered by stripe" className="mr-2" />
            <img src={visa} alt="Visa logo" className="mr-1" />
            <img src={mastercard} alt="Mastercard logo" />
          </div>
          <p className="font-brand-b2r text-text-400">
            Your payment is fully secured by Stripe. Your credit card details are sent directly to
            Stripe for processing and cannot be seen or stored by our servers.
          </p>
          {paymentMethods.length > 0 && (
            <Checkbox
              value={setDefault}
              title="Set as default payment method"
              onChange={toggleDefault}
              className="flex-nowrap mt-3"
            />
          )}
        </ModalShell>
      </>
    );
  },
);
