import { useCallback, RefObject, useEffect, useState } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useIdParam, useVisibleElements } from '~/common/hooks';
import { useEvents } from './useEvents';

export const useChatReadStore = create(
  persist(
    () => ({
      orderIdTimestamps: {} as Record<number, number>,
    }),
    { name: '_chat-read' },
  ),
);

export const useChatRead = () => {
  const store = useChatReadStore();
  const events = useEvents();
  const id = useIdParam();

  const readChat = useCallback(() => {
    useChatReadStore.setState((prev) => ({
      ...prev,
      orderIdTimestamps: {
        ...prev.orderIdTimestamps,
        [id]: Date.now() / 1000,
      },
    }));
  }, [id]);

  const unreadMessagesCount =
    events?.data?.filter((event) => event.timestamp > store.orderIdTimestamps[id]).length || 0;

  const readTimestamp = store.orderIdTimestamps[id];

  return { readChat, readTimestamp, unreadMessagesCount };
};

const OBSERVER_OPTIONS = {
  threshold: 1,
} satisfies IntersectionObserverInit;

export const useAutoReadMessages = <T extends HTMLElement = HTMLDivElement>(
  containerRef: RefObject<T>,
) => {
  const [readUnblocked, setReadUnblocked] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(true);
  const shouldReadMessages = readUnblocked && reachedBottom;
  const { readChat } = useChatRead();
  const id = useIdParam();

  useEffect(() => {
    useChatReadStore.setState((prev) => {
      return prev.orderIdTimestamps[id]
        ? prev
        : {
            orderIdTimestamps: {
              ...prev.orderIdTimestamps,
              [id]: 1000,
            },
          };
    });
  }, [id]);

  useVisibleElements({
    containerRef,
    onVisibleChange: (elements) => {
      if (
        containerRef.current?.scrollTop === 0 ||
        elements.some((element) => element.target.getAttribute('data-new-messages-marker'))
      ) {
        setReadUnblocked(true);
      }
    },
    observerOptions: OBSERVER_OPTIONS,
  });

  useEffect(() => {
    const handler = (event: Event) => {
      const { scrollHeight, scrollTop, clientHeight } = event.currentTarget as HTMLDivElement;
      setReachedBottom(Math.abs(scrollHeight - scrollTop - clientHeight) < 1);
    };

    const container = containerRef.current;

    container?.addEventListener('scroll', handler);

    return () => container?.removeEventListener('scroll', handler);
  }, [containerRef]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (shouldReadMessages) {
        readChat();
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, [readChat, shouldReadMessages]);

  return containerRef;
};
